import { useRouter } from "next/router";
import { ReactNode } from "react";

import Image from "../../../../../apps/web-client/src/components/common/Image";
import { CrossIcon } from "../../atoms/Icons";
import Logo from "../../atoms/Logo";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import * as s from "./styles";

interface Props {
  children: ReactNode;
  footer?: ReactNode;
  illustrationAlt?: string;
  illustrationPath: string;
  onClose: () => void;
  title: string;
}

export default function FullOverlayLayout({
  children,
  footer,
  illustrationAlt = "",
  illustrationPath,
  onClose,
  title,
}: Props): JSX.Element {
  const { push } = useRouter();

  const redirectToHome = () => {
    push("/");
  };

  return (
    <div className={s.layoutWrapper}>
      <div className={s.closeButtonWrapper}>
        <Button variant="icon" onClick={onClose} aria-label="Close">
          <CrossIcon />
        </Button>
      </div>
      <aside className={s.aside}>
        <header>
          <button aria-label="Return to home" onClick={redirectToHome}>
            <Logo height={26} color="white" variant="full" />
          </button>
        </header>
        <div className={s.asideIllustration}>
          <Image
            alt={illustrationAlt}
            src={illustrationPath}
            fill
            sizes="100vw"
            style={{
              objectFit: "contain",
            }}
          />
        </div>
      </aside>
      <main className={s.main}>
        <header>
          <Text gutter="sm" variant="h3">
            {title}
          </Text>
        </header>
        <div className={s.mainMainAndFooterWrapper}>
          <main>{children}</main>
          {footer && <footer className="mt-4">{footer}</footer>}
        </div>
      </main>
    </div>
  );
}
