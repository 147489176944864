import { ReactNode } from "react";

import Badge, { BadgeSize } from "../../atoms/Badge";
import {
  ExclamationIcon,
  EyeIcon,
  CheckCircleIcon,
  ClockIcon,
} from "../../atoms/Icons";

type Variant = "neutral" | "yellow" | "green" | "teal" | "red";

interface Props {
  variant?: Variant;
  size?: BadgeSize;
  children: ReactNode;
  icon?: ReactNode;
}

const iconMap: Record<Variant, ReactNode> = {
  yellow: <ExclamationIcon />,
  neutral: <ClockIcon />,
  green: <CheckCircleIcon />,
  teal: <EyeIcon variant="open" />,
  red: <ExclamationIcon />,
};

export default function StatusBadge({
  variant = "neutral",
  size = "sm",
  icon,
  children,
}: Props) {
  return (
    <Badge
      variant={`${variant}Status`}
      icon={icon || iconMap[variant]}
      size={size}
    >
      {children}
    </Badge>
  );
}
