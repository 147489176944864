import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

type ChatBalloonVariant = "default" | "plus" | "question";

interface Props extends IconProps {
  variant?: ChatBalloonVariant;
}

export function ChatBalloonIcon({
  className,
  color = "currentColor",
  size,
  variant = "default",
}: Props): ReactElement {
  const variants = {
    default: (
      <g>
        <circle
          cx="7.39705"
          cy="12.1092"
          r="1.5"
          transform="rotate(135 7.39705 12.1092)"
          fill={color}
        />
        <circle
          cx="11.8971"
          cy="12.1092"
          r="1.5"
          transform="rotate(135 11.8971 12.1092)"
          fill={color}
        />
        <circle
          cx="16.3971"
          cy="12.1092"
          r="1.5"
          transform="rotate(135 16.3971 12.1092)"
          fill={color}
        />
        <path
          d="M7.50977 19.8018C8.83126 20.564 10.3645 21 11.9996 21C16.9702 21 21 16.9706 21 12C21 7.02945 16.9706 3 12 3C7.02944 3 3 7.02945 3 12C3 13.6351 3.43604 15.1684 4.19819 16.4899L4.20115 16.495C4.27448 16.6222 4.31146 16.6863 4.32821 16.7469C4.34401 16.804 4.34842 16.8554 4.34437 16.9146C4.34003 16.9781 4.3186 17.0441 4.27468 17.1758L3.50586 19.4823L3.50489 19.4853C3.34268 19.972 3.26157 20.2153 3.31938 20.3774C3.36979 20.5188 3.48169 20.6303 3.62305 20.6808C3.78482 20.7384 4.02705 20.6577 4.51155 20.4962L4.51758 20.494L6.82405 19.7251C6.95537 19.6814 7.02214 19.6592 7.08559 19.6548C7.14475 19.6508 7.19578 19.6561 7.25293 19.6719C7.31368 19.6887 7.37783 19.7257 7.50563 19.7994L7.50977 19.8018Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    ),
    plus: (
      <g>
        <path
          d="M7.50977 19.8018C8.83126 20.564 10.3645 21 11.9996 21C16.9702 21 21 16.9706 21 12C21 7.02945 16.9706 3 12 3C7.02944 3 3 7.02945 3 12C3 13.6351 3.43604 15.1684 4.19819 16.4899L4.20115 16.495C4.27448 16.6222 4.31146 16.6863 4.32821 16.7469C4.34401 16.804 4.34842 16.8554 4.34437 16.9146C4.34003 16.9781 4.3186 17.0441 4.27468 17.1758L3.50586 19.4823L3.50489 19.4853C3.34268 19.972 3.26157 20.2153 3.31938 20.3774C3.36979 20.5188 3.48169 20.6303 3.62305 20.6808C3.78482 20.7384 4.02705 20.6577 4.51155 20.4962L4.51758 20.494L6.82405 19.7251C6.95537 19.6814 7.02214 19.6592 7.08559 19.6548C7.14475 19.6508 7.19578 19.6561 7.25293 19.6719C7.31368 19.6887 7.37783 19.7257 7.50563 19.7994L7.50977 19.8018Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 9.3L12 14.3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M14.5 11.8L9.5 11.8"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    question: (
      <g>
        <path
          d="M7.50977 19.8018C8.83126 20.564 10.3645 21 11.9996 21C16.9702 21 21 16.9706 21 12C21 7.02945 16.9706 3 12 3C7.02944 3 3 7.02945 3 12C3 13.6351 3.43604 15.1684 4.19819 16.4899L4.20115 16.495C4.27448 16.6222 4.31146 16.6863 4.32821 16.7469C4.34401 16.804 4.34842 16.8554 4.34437 16.9146C4.34003 16.9781 4.3186 17.0441 4.27468 17.1758L3.50586 19.4823L3.50489 19.4853C3.34268 19.972 3.26157 20.2153 3.31938 20.3774C3.36979 20.5188 3.48169 20.6303 3.62305 20.6808C3.78482 20.7384 4.02705 20.6577 4.51155 20.4962L4.51758 20.494L6.82405 19.7251C6.95537 19.6814 7.02214 19.6592 7.08559 19.6548C7.14475 19.6508 7.19578 19.6561 7.25293 19.6719C7.31368 19.6887 7.37783 19.7257 7.50563 19.7994L7.50977 19.8018Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29999 10.23C9.29999 8.71519 10.528 7.5 12.0121 7.5C13.483 7.5 14.7 8.70426 14.7 10.2056C14.7 11.1 14.4932 11.416 14.1136 11.8957L12.8564 13.4847C12.5698 13.847 12.047 13.9057 11.6887 13.6159C11.3304 13.3261 11.2723 12.7975 11.559 12.4352L12.8162 10.8463C12.9601 10.6644 13.0384 10.4385 13.0384 10.2056C13.0384 9.64622 12.5793 9.17999 12.0121 9.17999C11.4316 9.17999 10.9615 9.65715 10.9615 10.23V10.44C10.9615 10.9039 10.5896 11.28 10.1308 11.28C9.67194 11.28 9.29999 10.9039 9.29999 10.44V10.23Z"
          fill={color}
        />
        <path
          d="M11.0582 15.5714C11.0582 15.0586 11.4612 14.6429 11.9582 14.6429C12.4553 14.6429 12.8582 15.0586 12.8582 15.5714C12.8582 16.0843 12.4553 16.5 11.9582 16.5C11.4612 16.5 11.0582 16.0843 11.0582 15.5714Z"
          fill={color}
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
