import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function CrossIcon({
  className,
  color = "currentColor",
  size,
  strokeWidth = "2",
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M4.92896 19.5711L19.0711 5.42894"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M4.92896 5.42894L19.0711 19.5711"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
