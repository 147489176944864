import { throttle } from "lodash";

// We consider that a user should not be able to click the same element (button, ...)
// more than once during some delay.
const CLICK_THROTTLE_TIME = 1000; // ms

export function getThrottledOnClick(onClick: (...args: any[]) => void) {
  return throttle(onClick, CLICK_THROTTLE_TIME, {
    leading: true,
    trailing: false,
  });
}
