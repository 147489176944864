import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  variant: "up" | "down" | "off";
}

export function VolumeIcon({
  className,
  color = "currentColor",
  size,
  variant = "off",
}: Props): ReactElement {
  const volume = {
    off: (
      <g>
        <path
          d="M6.5099 8.03736C5.84844 8.26174 5.32956 8.79452 5.11104 9.47371C5 9.81883 5 11.1459 5 12C5 12.8541 5 14.1812 5.11104 14.5263C5.32956 15.2055 5.84844 15.7383 6.5099 15.9626C6.84602 16.0766 7.26193 16.0766 8.09375 16.0766H9.65941C9.96817 16.0766 10.1226 16.0766 10.2652 16.1168C10.3916 16.1523 10.5108 16.2108 10.6172 16.2894C10.7372 16.3781 10.8343 16.5014 11.0283 16.748L12.8711 19.0895C13.8317 20.3101 14.312 20.9204 14.7328 20.9869C15.0971 21.0444 15.4652 20.9107 15.7134 20.6309C15.9202 20.3977 15.9778 19.9244 15.9938 19.0895M16 11.1V6.04635C16 4.477 16 3.69233 15.7134 3.36908C15.4652 3.08926 15.0971 2.95562 14.7328 3.01314C14.312 3.07957 13.8317 3.68989 12.8711 4.91051L11.9497 6.08127"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 3L20 20"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    up: (
      <g>
        <path
          d="M2 12C2 11.1459 2 9.81883 2.11104 9.47371C2.32956 8.79452 2.84844 8.26174 3.5099 8.03736C3.84602 7.92335 4.26193 7.92335 5.09375 7.92335H6.65941C6.96817 7.92335 7.12255 7.92335 7.26522 7.88323C7.39161 7.84768 7.5108 7.78923 7.61717 7.71063C7.73723 7.62191 7.83426 7.49862 8.02833 7.25203L9.87109 4.91051C10.8317 3.68989 11.312 3.07957 11.7328 3.01314C12.0971 2.95562 12.4652 3.08926 12.7134 3.36908C13 3.69233 13 4.477 13 6.04635V17.9536C13 19.523 13 20.3077 12.7134 20.6309C12.4652 20.9107 12.0971 21.0444 11.7328 20.9869C11.312 20.9204 10.8317 20.3101 9.87108 19.0895L8.02833 16.748C7.83426 16.5014 7.73723 16.3781 7.61717 16.2894C7.5108 16.2108 7.39161 16.1523 7.26522 16.1168C7.12255 16.0766 6.96817 16.0766 6.65941 16.0766H5.09375C4.26193 16.0766 3.84602 16.0766 3.5099 15.9626C2.84844 15.7383 2.32956 15.2055 2.11104 14.5263C2 14.1812 2 12.8541 2 12Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.126 7.61057C16.3597 7.84459 16.5748 8.09716 16.7689 8.36589C17.5072 9.3877 17.9423 10.643 17.9423 12C17.9423 13.7138 17.2483 15.2655 16.126 16.3894"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M18.6073 4.5C19.0067 4.89987 19.3742 5.33143 19.706 5.79058C20.9675 7.53651 21.7109 9.68144 21.7109 12C21.7109 14.9283 20.525 17.5797 18.6073 19.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    down: (
      <g>
        <path
          d="M2.95093 12C2.95093 11.1459 2.95093 9.81883 3.06449 9.47371C3.28797 8.79452 3.81865 8.26174 4.49514 8.03736C4.8389 7.92335 5.26426 7.92335 6.11499 7.92335H7.71623C8.03201 7.92335 8.1899 7.92335 8.33581 7.88323C8.46508 7.84768 8.58698 7.78923 8.69576 7.71063C8.81855 7.62191 8.91779 7.49862 9.11626 7.25203L11.0009 4.91051C11.9834 3.68989 12.4746 3.07957 12.905 3.01314C13.2775 2.95562 13.654 3.08926 13.9078 3.36908C14.2009 3.69233 14.2009 4.477 14.2009 6.04635V17.9536C14.2009 19.523 14.2009 20.3077 13.9078 20.6309C13.654 20.9107 13.2775 21.0444 12.905 20.9869C12.4746 20.9204 11.9834 20.3101 11.0009 19.0895L9.11626 16.748C8.91779 16.5014 8.81855 16.3781 8.69576 16.2894C8.58698 16.2108 8.46508 16.1523 8.33581 16.1168C8.1899 16.0766 8.03201 16.0766 7.71623 16.0766H6.11499C5.26426 16.0766 4.8389 16.0766 4.49514 15.9626C3.81865 15.7383 3.28797 15.2055 3.06449 14.5263C2.95093 14.1812 2.95093 12.8541 2.95093 12Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.2509 7.5C18.4846 7.73402 18.6998 7.9866 18.8939 8.25532C19.6322 9.27714 20.0673 10.5325 20.0673 11.8894C20.0673 13.6032 19.3733 15.155 18.2509 16.2789"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {volume[variant]}
    </SvgIcon>
  );
}
