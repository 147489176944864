import ctl from "@netlify/classnames-template-literals";

export const panelWrapper = ctl(`
  relative
  rounded-xl
  bg-[url(/img/purple_bg_gradient.png)]
  w-full
  lg:flex
  lg:flex-col
  lg:pt-12
  lg:px-16
  lg:pb-8
  xl:pt-20
  xl:px-16
`);
