import { ReactElement, ReactNode } from "react";

import { ORGANISATION_ID_OLIVANS } from "@olivahealth/constants";
import UserRole from "@olivahealth/graphql-server/src/domain/value-objects/UserRole";
import UserStatus from "@olivahealth/graphql-server/src/domain/value-objects/UserStatus";
import { NexusGenEnums } from "@olivahealth/graphql-server/src/ui/types/graphql.gen";

interface Option<T> {
  id: string | number;
  value: T;
  label: string | ReactElement;
  icon?: ReactNode;
}

interface User {
  readonly name?: string;
  readonly surname?: string;
}

interface UserForDropdown {
  readonly id: string;
  readonly name?: string;
  readonly surname?: string;
  readonly email: string;
  readonly role: ReadonlyArray<string>;
  readonly status: UserStatus;
}

function containsEmployeeRole(
  roles?: ReadonlyArray<NexusGenEnums["UserRoleEnum"]>,
): boolean {
  const employeeRoles = [
    UserRole.EMPLOYEE,
    UserRole.MANAGER,
    UserRole.ORGANISATION_ADMIN,
  ] as ReadonlyArray<NexusGenEnums["UserRoleEnum"]>;

  if (!roles) {
    return false;
  }

  return roles.some((role) => employeeRoles.includes(role));
}

function containsHigherEmployeeRole(
  roles?: ReadonlyArray<NexusGenEnums["UserRoleEnum"]>,
): boolean {
  const higherEmployeeRoles = [
    UserRole.MANAGER,
    UserRole.ORGANISATION_ADMIN,
  ] as ReadonlyArray<NexusGenEnums["UserRoleEnum"]>;

  if (!roles) {
    return false;
  }

  return roles.some((role) => higherEmployeeRoles.includes(role));
}

function containsProfessionalRole(
  roles?: ReadonlyArray<NexusGenEnums["UserRoleEnum"]>,
): boolean {
  const professionalRoles = [
    UserRole.CLINICAL_SUPERVISOR,
    UserRole.EXTERNAL_CLINICAL_SUPERVISOR,
    UserRole.MHP,
  ] as ReadonlyArray<NexusGenEnums["UserRoleEnum"]>;

  if (!roles) {
    return false;
  }

  return roles.some((role) => professionalRoles.includes(role));
}

function containsSupervisorRole(
  roles?: ReadonlyArray<NexusGenEnums["UserRoleEnum"]>,
): boolean {
  const supervisorRoles = [
    UserRole.CLINICAL_SUPERVISOR,
    UserRole.EXTERNAL_CLINICAL_SUPERVISOR,
  ] as ReadonlyArray<NexusGenEnums["UserRoleEnum"]>;

  if (!roles) {
    return false;
  }

  return roles.some((role) => supervisorRoles.includes(role));
}

function mapUsersToDropdown(
  users: readonly UserForDropdown[] | null | undefined,
): Array<Option<string>> {
  if (!users) {
    return [];
  }
  return users.map((user) => ({
    id: user.id,
    value: user.id,
    label: `
      ${user.status === UserStatus.SUSPENDED ? "[SUSPENDED] " : ""}
      ${getUserFullName(user)} (${user.email})<br />${user.id} / ${user.role}`,
  }));
}

function getUserFullName(user: User | null | undefined): string {
  return [user?.name, user?.surname].filter(Boolean).join(" ");
}

function getUsersFullName(users: ReadonlyArray<User>): Array<string> {
  return users.map((user) => getUserFullName(user));
}

function getUserInitials(user: User | null | undefined): string {
  const name = user?.name || "";
  const surname = user?.surname || "";
  return name.charAt(0) + surname.charAt(0);
}

function isOlivansOrganisationId(id?: string): boolean {
  return id === ORGANISATION_ID_OLIVANS;
}

export {
  containsEmployeeRole,
  containsHigherEmployeeRole,
  containsProfessionalRole,
  containsSupervisorRole,
  mapUsersToDropdown,
  getUserFullName,
  getUsersFullName,
  getUserInitials,
  isOlivansOrganisationId,
};
