/* eslint-disable no-restricted-imports */
import NextImage, { ImageProps, ImageLoaderProps } from "next/image";
import { ENVIRONMENT } from "@olivahealth/constants";

export default function Image(props: ImageProps) {
  return (
    <NextImage
      {...props}
      {...(ENVIRONMENT !== "local" ? { loader: cloudflareLoader } : {})}
    />
  );
}

function normalizeSrc(src: string) {
  return src.startsWith("/") ? src.slice(1) : src;
}

function cloudflareLoader({ src, width, quality }: ImageLoaderProps) {
  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(",");

  return `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
}

export * from "next/image";
