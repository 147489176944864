import ctl from "@netlify/classnames-template-literals";

export const layoutWrapper = ctl(`
  absolute
  top-0
  left-0
  flex
  h-full
  w-full
  flex-col
  md:flex-row
  md:p-8
  bg-white
`);

export const closeButtonWrapper = ctl(`
  absolute
  top-12
  right-8
  z-10
`);

export const aside = ctl(`
  hidden
  w-2/5
  flex-col
  justify-between
  rounded-xl
  bg-[url('/img/img_background_layout_gradient.png')]
  bg-cover
  p-8
  md:flex
`);

export const asideIllustration = ctl(`
  relative
  hidden
  h-full
  md:block
`);

export const main = ctl(`
  relative
  h-full
  w-3/5
  animate-fade-in-up
  md:ml-16
  p-16
`);

export const mainMainAndFooterWrapper = ctl(`
  flex
  h-full
  flex-col
  justify-between
`);
