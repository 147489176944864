import ctl from "@netlify/classnames-template-literals";

export const faq = ctl(`
  grid
  grid-cols-1
  gap-8
  md:grid-cols-12
`);

export const leftCol = ctl(`
  flex
  flex-col
  items-start
  gap-4
  md:col-span-4
`);
