import { useEffect } from "react";
import { useRouter } from "next/router";
import { useIntercom } from "react-use-intercom";

interface IProps {
  children: JSX.Element;
}

/**
 * This middleware is responsible to check if the Intercom messenger should be opened after page load.
 */
export default function IntercomMiddleware({ children }: IProps): JSX.Element {
  const { show } = useIntercom();
  const { query } = useRouter();

  // We voluntarily don't add "query" in useEffect props dependencies
  // because we want Intercom to be shown only on 1st arrival
  useEffect(() => {
    if (typeof query?.["contact-oliva"] !== "undefined") {
      show();
    }
  }, []);

  return children;
}
