import { ReactElement, useRef, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import {
  ParsedCountry,
  PhoneInput as RPhoneInput,
  PhoneInputRefType,
} from "react-international-phone";
import "react-international-phone/style.css";
import Text from "../../atoms/Text";
import isPhoneValid from "./isPhoneValid";
import * as s from "./styles";

export interface Props {
  defaultCountry?: string;
  defaultValue?: string;
  label?: string;
  name: string;
  onChange?: (
    phone: string,
    meta: { country: ParsedCountry; inputValue: string },
  ) => void;
  placeholder?: string;
  register?: UseFormRegister<any>;
  validationMessage?: string;
}

export default function PhoneInput({
  defaultCountry = "us",
  defaultValue,
  label,
  name,
  onChange,
  placeholder,
  validationMessage,
}: Props): ReactElement {
  const [phone, setPhone] = useState<string | undefined>(
    defaultValue ?? undefined,
  );
  const [isDirty, setIsDirty] = useState(false);
  const ref = useRef<PhoneInputRefType>(null);
  const isValid = isDirty && isPhoneValid(phone);
  const showValidation = isDirty && !isValid;

  const handleClickLabel = () => {
    ref.current?.focus();
  };

  const handlePhoneChange = (
    phone: string,
    meta: { country: ParsedCountry; inputValue: string },
  ) => {
    if (phone.length === 0 || meta.inputValue === "") {
      setIsDirty(false);
    } else if (phone.length > 7) {
      setIsDirty(true);
    }

    setPhone(phone);
    onChange?.(phone, meta);
  };

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <label
          htmlFor={name}
          onClick={handleClickLabel}
          onKeyDown={handleClickLabel}
        >
          <Text weight="bold">{label}</Text>
        </label>
      )}
      <RPhoneInput
        disableDialCodeAndPrefix
        showDisabledDialCodeAndPrefix
        defaultCountry={defaultCountry}
        inputProps={{ id: name, placeholder }}
        name={name}
        onChange={handlePhoneChange}
        ref={ref}
        value={phone}
        inputClassName={s.input(showValidation)}
        countrySelectorStyleProps={{
          buttonClassName: s.countrySelectorButton(showValidation),
          dropdownStyleProps: {
            listItemClassName: s.countrySelectorListItem,
            listItemCountryNameClassName: s.countrySelectorListItemCountryName,
            listItemDialCodeClassName: s.countrySelectorListItemDialCode,
          },
        }}
        dialCodePreviewStyleProps={{
          className: s.dialCodePreview(showValidation),
        }}
      />
      {showValidation && validationMessage && (
        <Text size="sm" color="secondary">
          {validationMessage}
        </Text>
      )}
    </div>
  );
}
