import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function ArticleIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M3 10C3 6.13401 6.13401 3 10 3H14C17.866 3 21 6.13401 21 10V14C21 17.866 17.866 21 14 21H10C6.13401 21 3 17.866 3 14V10Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="8.46478"
        cy="7.94138"
        r="2"
        transform="rotate(-45 8.46478 7.94138)"
        fill={color}
      />
      <path
        d="M7.45456 12.9091L16.5455 12.9091"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.45456 16.5454L16.5455 16.5454"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
