import ResourceType from "@olivahealth/graphql-server/src/domain/value-objects/ResourceType";
import ResourceArea from "@olivahealth/graphql-server/src/domain/value-objects/ResourceArea";
import WorkshopType from "@olivahealth/graphql-server/src/domain/value-objects/WorkshopType";
import MentalHealthTopics from "@olivahealth/graphql-server/src/domain/value-objects/MentalHealthTopics";

export interface ResourceTrackingProperties {
  contentId: string;
  contentName: string;
  contentType: ResourceType | WorkshopType | string;
  contentProvider: string;
  contentArea: ResourceArea[] | WorkshopType[];
  hasPlayback: boolean;
}

export interface RecommendedResourcesTrackingProperties {
  hasRecommendations: boolean;
  recommendedTheme1: MentalHealthTopics | null;
  recommendedQuantity1: number;
  recommendedTheme2: MentalHealthTopics | null;
  recommendedQuantity2: number;
}

export enum LoginErrorType {
  SSO_NOT_ENABLED_FOR_ORG = "SSO_NOT_ENABLED_FOR_ORG",
  SSO_MULTI_ORG_SELECT_ERROR = "SSO_MULTI_ORG_SELECT_ERROR",
  SSO_NO_ACCESS_TOKEN = "SSO_NO_ACCESS_TOKEN",
  SSO_NO_CUSTOM_LOGIN_TOKEN = "SSO_NO_CUSTOM_LOGIN_TOKEN",
  SSO_ERROR_DURING_AUTHORISATION = "SSO_ERROR_DURING_AUTHORISATION",
  SSO_ERROR_DURING_EMAIL_VERIFICATION = "SSO_ERROR_DURING_EMAIL_VERIFICATION",
  GENERAL_APP_ERROR = "GENERAL_APP_ERROR",
}
