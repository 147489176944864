import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

type TwoTrianglesVariant = "left" | "right";

interface Props extends IconProps {
  variant?: TwoTrianglesVariant;
}

export function TwoTrianglesIcon({
  className,
  size,
  color = "currentColor",
  variant = "left",
}: Props): ReactElement {
  const variants = {
    left: (
      <g>
        <path
          d="M19.6354 17.8448L13.8688 13.766C12.7104 12.9467 12.7104 11.0533 13.8688 10.234L19.6354 6.15518C19.9237 5.95127 20.2337 5.9595 20.4982 6.11424C20.7705 6.2736 21 6.59994 21 7.0604L21 16.9396C21 17.4001 20.7705 17.7264 20.4982 17.8858C20.2337 18.0405 19.9237 18.0487 19.6354 17.8448Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M9.63538 17.8448L3.86882 13.766C2.71039 12.9467 2.71039 11.0533 3.86882 10.234L9.63538 6.15518C9.92366 5.95127 10.2337 5.9595 10.4982 6.11424C10.7705 6.2736 11 6.59994 11 7.0604L11 16.9396C11 17.4001 10.7705 17.7264 10.4982 17.8858C10.2337 18.0405 9.92366 18.0487 9.63538 17.8448Z"
          stroke={color}
          strokeWidth="2"
        />
      </g>
    ),
    right: (
      <g>
        <path
          d="M4.36462 6.15518L10.1312 10.234C11.2896 11.0533 11.2896 12.9467 10.1312 13.766L4.36462 17.8448C4.07634 18.0487 3.76627 18.0405 3.50184 17.8858C3.22952 17.7264 3 17.4001 3 16.9396L3 7.0604C3 6.59994 3.22952 6.2736 3.50184 6.11424C3.76627 5.9595 4.07634 5.95127 4.36462 6.15518Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M14.3646 6.15518L20.1312 10.234C21.2896 11.0533 21.2896 12.9467 20.1312 13.766L14.3646 17.8448C14.0763 18.0487 13.7663 18.0405 13.5018 17.8858C13.2295 17.7264 13 17.4001 13 16.9396L13 7.0604C13 6.59994 13.2295 6.2736 13.5018 6.11424C13.7663 5.9595 14.0763 5.95127 14.3646 6.15518Z"
          stroke={color}
          strokeWidth="2"
        />
      </g>
    ),
  };
  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
