import { useEffect, useState } from "react";
import { OAuth2AuthCodePKCE } from "@bity/oauth2-auth-code-pkce";
import logger from "@olivahealth/logger/client";
import getBaseUrl from "@olivahealth/utils/getBaseUrl";

export const getJacksonBaseUrl = () => {
  return process.env.NEXT_PUBLIC_JACKSON_BASE_URL ?? "http://localhost:5225";
};

export default function useOAuthClient(): OAuth2AuthCodePKCE | null {
  const [oauthClient, setOauthClient] = useState<OAuth2AuthCodePKCE | null>(
    null,
  );
  useEffect(() => {
    if (!oauthClient) {
      setOauthClient(
        new OAuth2AuthCodePKCE({
          scopes: [],
          authorizationUrl: `${getJacksonBaseUrl()}/api/oauth/authorize`,
          tokenUrl: `${getJacksonBaseUrl()}/api/oauth/token`,
          redirectUrl: getBaseUrl(),
          // We use a dummy client id for initialisation
          // When we fetch the auth code, we will pass through tenant and product information
          clientId: "dummy-client-id",
          onAccessTokenExpiry(refreshAccessToken) {
            logger.info(
              "useOAuthClient",
              "OAuthClient access token expired, attempting to access new token via grant code or refresh token",
            );
            return refreshAccessToken();
          },
          onInvalidGrant(refreshAuthCodeOrRefreshToken) {
            logger.info(
              "useOAuthClient",
              "OAuthClient invalid grant, redirecting to auth server to obtain a new auth grant code.",
            );
            return refreshAuthCodeOrRefreshToken();
          },
        }),
      );
    }
  }, [oauthClient]);

  return oauthClient;
}
