import React, { cloneElement, ReactElement, ReactNode } from "react";
import ctl from "@netlify/classnames-template-literals";
import Text, { TextSize } from "../Text";

export type BadgeSize = "xs" | "sm" | "lg";
export type BadgeVariant =
  | "blue"
  | "default"
  | "green"
  | "orange"
  | "red"
  | "pink"
  | "purple"
  | "purpleDark"
  | "yellowStatus"
  | "greenStatus"
  | "tealStatus"
  | "redStatus"
  | "neutralStatus"
  | "greenAccent"
  | "empty";

interface Props {
  children?: ReactNode;
  border?: boolean;
  icon?: ReactNode;
  size?: BadgeSize;
  variant?: BadgeVariant;
}

export default function Badge({
  children,
  border = true,
  icon,
  size = "sm",
  variant = "default",
}: Readonly<Props>): JSX.Element {
  const className = getClasses(size, variant, border);
  const fontSize = getFontSize(size);
  const isLarge = size === "lg";
  const iconEl = getIconSize(icon, size);

  return (
    <span className={className}>
      {icon && <span className="mr-2">{iconEl}</span>}

      <Text
        variant="body"
        component="span"
        size={fontSize}
        weight={isLarge ? "bold" : "medium"}
      >
        {children}
      </Text>
    </span>
  );
}

export function getClasses(
  size: BadgeSize,
  variant: BadgeVariant,
  border: boolean,
): string {
  const baseStyles = ctl(`inline-flex items-center justify-center`);

  const sizeClasses = {
    xs: ctl(`py-0.5 px-2 rounded-2xl`),
    sm: ctl(`py-1 px-2 rounded-2xl`),
    lg: ctl(`py-2 px-3 rounded-3xl`),
  };

  const variantClasses = {
    blue: ctl(`bg-blue-100 text-blue-900`),
    default: ctl(`bg-neutral-100 text-neutral-900`),
    green: ctl(`bg-green-100 text-green-900`),
    orange: ctl(`bg-orange-100 text-orange-900`),
    pink: ctl(`bg-pink-100 text-pink-900`),
    purple: ctl(`bg-purple-100 text-purple-900`),
    purpleDark: ctl(`bg-purple-900 text-white`),
    red: ctl(`bg-status-red-50 text-status-red-700`),
    yellowStatus: ctl(
      `bg-status-yellow-50 text-status-yellow-700 ${border ? "border-status-yellow-500 border" : ""}`,
    ),
    greenStatus: ctl(
      `bg-status-green-50 text-status-green-700 ${border ? "border-status-green-500 border" : ""}`,
    ),
    tealStatus: ctl(
      `bg-status-teal-50 text-status-teal-700 ${border ? "border-status-teal-500 border" : ""}`,
    ),
    redStatus: ctl(
      `bg-status-red-50 text-status-red-700 ${border ? "border-status-red-500 border" : ""}`,
    ),
    neutralStatus: ctl(
      `bg-neutral-100 text-neutral-900 ${border ? "border-neutral-500 border" : ""}`,
    ),
    greenAccent: ctl(`
        bg-green-300 text-purple-900
    `),
    empty: ctl(`bg-white bg-opacity-30 w-20 h-6`),
  };

  return `${baseStyles} ${sizeClasses[size]} ${variantClasses[variant]}`;
}

function getFontSize(size: BadgeSize): TextSize {
  const fontSizes = {
    xs: "sm",
    sm: "base",
    lg: "2xl",
  };

  return fontSizes[size] as TextSize;
}

function getIconSize(icon: ReactNode, size: BadgeSize): ReactNode {
  if (!icon) {
    return null;
  }

  const iconSizes = {
    xs: "20px",
    sm: "24px",
    lg: "32px",
  };

  return cloneElement(icon as ReactElement, {
    size: iconSizes[size],
  });
}
