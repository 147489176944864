import ctl from "@netlify/classnames-template-literals";

import type { ToastVariant } from "./index";

interface ContainerStyles {
  body: string;
  toast: string;
}

export const closeButton = ctl(`
  absolute
  top-5
  right-2
  cursor-pointer
`);

export const container = (variant: ToastVariant): ContainerStyles => {
  const toastBodyBase = ctl(`  
    flex
    p-3
    pr-6
    w-full
    rounded-lg
    font-bold
    text-neutral-900
  `);
  const toastToastBase = ctl(`
    relative
    border
    rounded-lg
  `);

  const variantClasses = {
    default: {
      body: `${toastBodyBase} bg-status-teal-50`,
      toast: `${toastToastBase} border-status-teal-500`,
    },
    error: {
      body: `${toastBodyBase} bg-status-red-50`,
      toast: `${toastToastBase} border-status-red-500`,
    },
    info: {
      body: `${toastBodyBase} bg-status-teal-50`,
      toast: `${toastToastBase} border-status-teal-500`,
    },
    success: {
      body: `${toastBodyBase} bg-status-green-50`,
      toast: `${toastToastBase} border-status-green-500`,
    },
    warning: {
      body: `${toastBodyBase} bg-status-yellow-50`,
      toast: `${toastToastBase} border-status-yellow-500`,
    },
  };

  return variantClasses[variant];
};
