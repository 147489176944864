import { ReactElement, ReactNode } from "react";

export const DEFAULT_ICON_SIZE = 24;
export const DEFAULT_ICON_VIEWBOX = "0 0 24 24";

export interface IconProps {
  children?: ReactNode;
  className?: string;
  color?: string;
  fill?: string;
  label?: string;
  onClick?: () => void;
  size?: number;
  viewBox?: string;
  roundedBorder?: boolean;
  strokeWidth?: string;
  role?: string;
}

export function SvgIcon({
  children,
  className,
  fill,
  label,
  onClick,
  size,
  viewBox,
  role = "button",
}: IconProps): ReactElement {
  const iconSize = size ?? DEFAULT_ICON_SIZE;
  const viewBoxDimension = viewBox ?? DEFAULT_ICON_VIEWBOX;

  return (
    <span {...(onClick && { role, onClick, tabIndex: 0 })}>
      <svg
        className={className}
        height={iconSize}
        width={iconSize}
        viewBox={viewBoxDimension}
        fill={fill ?? "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        {label && <title>{label}</title>}
        {children}
      </svg>
    </span>
  );
}
