import { ReactNode } from "react";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import Disclosure, { Props as DisclosureProps } from "../../atoms/Disclosure";
import * as s from "./styles";

interface Props {
  buttonLabel?: string;
  buttonOnClick?: () => void;
  items: DisclosureProps[];
  onItemClick?: (isOpen: boolean, title?: string) => void;
  title: ReactNode | string;
  subtitle?: ReactNode | string;
  variant?: "default" | "ghost";
}

export interface FaqItem {
  children: ReactNode;
  defaultOpen?: boolean;
  icon?: ReactNode;
  title: string;
}

export default function FAQ({
  buttonLabel,
  buttonOnClick,
  items,
  onItemClick,
  title,
  subtitle,
  variant = "default",
}: Props): JSX.Element {
  return (
    <div className={s.faq}>
      <div className={s.leftCol}>
        <Text variant="h3">{title}</Text>
        {subtitle && (
          <Text color="secondary" variant="body">
            <span>{subtitle}</span>
          </Text>
        )}
        {buttonLabel && buttonOnClick && (
          <Button variant="link" onClick={buttonOnClick}>
            {buttonLabel}
          </Button>
        )}
      </div>
      <div className="md:col-span-8">
        {items.map((item) => {
          return (
            <Disclosure
              key={item.title}
              title={item.title}
              defaultOpen={item.defaultOpen}
              onItemClick={onItemClick}
              variant={variant}
            >
              {item.children}
            </Disclosure>
          );
        })}
      </div>
    </div>
  );
}
