import { ReactNode } from "react";
import cx from "classnames";

import "./style.css";

type TextAlign = "left" | "center" | "right";
type HoverEffect = "none" | "background-neutral";
type RowHeight = "base" | "xl";
type TableLayout = "fixed";

export function Table({
  children,
  tableLayout,
}: {
  children: ReactNode;
  tableLayout?: TableLayout;
}): JSX.Element {
  return (
    <table
      className={cx("oli-ui-table", {
        "table-fixed": tableLayout === "fixed",
      })}
    >
      {children}
    </table>
  );
}

export function TableHead({ children }: { children: ReactNode }): JSX.Element {
  return <thead>{children}</thead>;
}

export function TableBody({ children }: { children: ReactNode }): JSX.Element {
  return <tbody>{children}</tbody>;
}

export function TableRow({
  children,
  hoverEffect = "none",
  rowHeight = "base",
  onRowClicked,
}: {
  children: ReactNode;
  hoverEffect?: HoverEffect;
  rowHeight?: RowHeight;
  onRowClicked?: () => void;
}): JSX.Element {
  return (
    <tr
      onClick={onRowClicked}
      className={`${getHoverEffectClasses(hoverEffect)} ${getRowHeightClasses(
        rowHeight,
      )}`}
    >
      {children}
    </tr>
  );
}

export function TableCell({
  children,
  textAlign = "left",
  width,
}: {
  children: ReactNode;
  textAlign?: TextAlign;
  width?: string;
}): JSX.Element {
  return (
    <td
      className={cx(getTextAlignClasses(textAlign), {
        [`w-${width}`]: width,
      })}
    >
      {children}
    </td>
  );
}

function getTextAlignClasses(textAlign: TextAlign): string {
  switch (textAlign) {
    default:
    case "left":
      return "text-left";
    case "center":
      return "text-center";
    case "right":
      return "text-right";
  }
}

function getHoverEffectClasses(hoverEffect: HoverEffect): string {
  switch (hoverEffect) {
    case "background-neutral":
      return "hover:bg-neutral-50";
    default:
    case "none":
      return "";
  }
}

function getRowHeightClasses(rowHeight: RowHeight): string {
  switch (rowHeight) {
    case "base":
      return "h-12";
    case "xl":
      return "h-[76px]";
    default:
      return "";
  }
}
