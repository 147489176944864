import { useEffect, RefObject } from "react";

export default function useClickOutside(
  ref: RefObject<HTMLElement>,
  onClickOutside: () => void,
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node | null)) {
        onClickOutside();
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}
