import { QuestionCircleIcon } from "../../atoms/Icons";
import Tooltip from "../../molecules/Tooltip";
import * as s from "./styles";

interface Props {
  text: string;
}

export default function FieldTooltip({ text }: Props) {
  return (
    <Tooltip text={text}>
      <div className={s.iconContainer}>
        <QuestionCircleIcon size={20} />
      </div>
    </Tooltip>
  );
}
