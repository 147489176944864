import ctl from "@netlify/classnames-template-literals";

export const dayPickerFooterWrapper = ctl(`
  flex
  w-full
  space-x-6
`);

export const customCaptionWrapper = ctl(`
  mb-5
  flex
  w-full
  items-center
  justify-between
`);

export const customCaptionDate = ctl(`
  pl-3
  text-base
  font-bold
  leading-6
  text-neutral-900
  min-w-[6rem]
`);

export const customCaptionNavigationWrapper = ctl(`
  flex
  items-center
  justify-end
  space-x-2
  ml-36
`);

export const timeSelectionWrapper = ctl(`
  h-full
  md:w-[15rem]
  md:absolute
  right-6
  top-4
`);

export const timeSelectionButtonWrapper = ctl(`
  overflow-scroll
  h-[18rem]
  w-[18.5rem]
  md:w-[15rem]
`);

export const timeSelectionButton = ctl(`
  bg-neutral-50
  my-2
  p-4
  rounded-lg
  hover:bg-purple-50
  cursor-pointer
`);

export const dateDropdownButton = ctl(`
  items-center
  pl-12
  align-middle
  text-base
  !font-normal
`);

export const dateDropdownButtonIcon = ctl(`
  absolute
  right-2
  top-3
  !text-neutral-900
`);

export const dateDropdownButtonDate = ctl(`
  leading-2
  !text-base
  !font-normal
  !text-neutral-900
`);

export const removeLabel = ctl(`
  ml-4
  flex
  text-sm
  capitalize
  text-neutral-60
`);
