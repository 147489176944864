import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function BookmarkIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M5 7.03646V18.21C5 20.4564 7.55537 21.8924 9.3984 20.3956L11.0499 19.0543C11.6118 18.5979 12.3882 18.5979 12.9501 19.0543L14.6016 20.3956C16.4446 21.8924 19 20.4564 19 18.21V7.03335L19 6.9937C19 6.49723 19 6.06252 18.9691 5.70314C18.9362 5.32096 18.8629 4.93461 18.6632 4.56284C18.3678 4.01273 17.9014 3.57667 17.3427 3.30653L16.9128 4.19575L17.3427 3.30653C16.9691 3.12594 16.582 3.0592 16.1907 3.02885C15.8184 2.99997 15.3657 2.99998 14.8372 3L14.8002 3H9.2002L9.16324 3C8.63471 2.99998 8.18169 2.99997 7.80912 3.02884C7.41775 3.05918 7.03023 3.12586 6.65654 3.30653C6.09666 3.57721 5.63182 4.01378 5.33698 4.56284C5.13721 4.93485 5.06387 5.3215 5.03093 5.70411C4.99996 6.06394 4.99998 6.49931 5 6.99679C5 7.00997 5 7.0232 5 7.03646Z"
        stroke={color}
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
