import { useCallback, useEffect } from "react";
import { signInWithCustomToken } from "@firebase/auth";
import { useRouter } from "next/router";

import logger from "@olivahealth/logger/client";
import { useAuth } from "@olivahealth/firebase/client";
import { getGraphqlUrl } from "@olivahealth/utils/relayUtils";
import {
  SSO_MULTI_ORG_LOCAL_STORAGE_KEY,
  TRACK_EVENT_SSO_PROVIDER_NAME,
} from "@olivahealth/constants";

import { useAmplitude } from "../services/contexts/AmplitudeContext";
import { LoginErrorType } from "../types/tracking";
import useOAuthClient from "./useOAuthClient";

export type AuthAuthorizedType =
  | "loading"
  | "success"
  | "in_progress"
  | "error";

const getSavedOrganisationId = (): string | null | undefined => {
  try {
    return localStorage.getItem(SSO_MULTI_ORG_LOCAL_STORAGE_KEY);
  } catch (error) {
    logger.error(
      "getSavedOrganisationId",
      "Failed to get saved organisation id",
      {
        errorMessage: (error as Error).message,
        errorStack: (error as Error).stack,
      },
    );
  }
};

const fetchCustomLoginToken = async (
  tokenValue: string,
  savedOrganisationId: string | null | undefined,
): Promise<string | undefined> => {
  try {
    const data = await fetch(
      `${getGraphqlUrl()}/sso/login?accessToken=${tokenValue}${
        savedOrganisationId ? `&organisationId=${savedOrganisationId}` : ""
      }`,
    ).then((response) => response.json());
    return data?.token;
  } catch (error) {
    logger.error(
      "fetchCustomLoginToken",
      "Failed to fetch custom login token from access token",
      {
        errorMessage: (error as Error).message,
        errorStack: (error as Error).stack,
      },
    );
  }
};

export default function useLoginReturningFromAuthServer(
  setAuthStatus: (status: AuthAuthorizedType) => void,
) {
  const oAuthClient = useOAuthClient();
  const auth = useAuth();
  const router = useRouter();
  const { trackEvent } = useAmplitude();

  const removeAuthCodeAndStateFromUrl = useCallback(async () => {
    const { query } = router;
    delete query.code;
    delete query.state;
    await router.replace({ pathname: router.pathname, query });
  }, [router]);

  useEffect(() => {
    const { query } = router;
    const hasAuthParams = Boolean(query.code && query.state);

    if (hasAuthParams) {
      setAuthStatus("loading");
    }
  }, [router, setAuthStatus]);

  useEffect(() => {
    const trackLoginError = (errorType: LoginErrorType) => {
      trackEvent("Login error occurred", {
        provider: TRACK_EVENT_SSO_PROVIDER_NAME,
        errorType,
      });
    };

    const loginUserReturningFromAuthServer = async () => {
      try {
        const { query } = router;
        const hasAuthParams = Boolean(query.code && query.state);

        if (!oAuthClient || !hasAuthParams) {
          return;
        }

        const shouldTrySSOLogin = await oAuthClient.isReturningFromAuthServer();

        if (!shouldTrySSOLogin) {
          return setAuthStatus("success");
        }

        const token = await oAuthClient?.getAccessToken();
        const accessToken = token?.token?.value;

        if (!accessToken) {
          logger.error(
            "useLoginReturningFromAuthServer",
            "Should login with SSO but no access token found",
          );
          trackLoginError(LoginErrorType.SSO_NO_ACCESS_TOKEN);
          return setAuthStatus("error");
        }

        try {
          const savedOrganisationId = getSavedOrganisationId();
          const customLoginToken = await fetchCustomLoginToken(
            accessToken,
            savedOrganisationId,
          );

          if (!customLoginToken) {
            logger.error(
              "useLoginReturningFromAuthServer",
              "Should login with SSO but no custom login token found",
            );
            trackLoginError(LoginErrorType.SSO_NO_CUSTOM_LOGIN_TOKEN);
            return setAuthStatus("error");
          }

          await signInWithCustomToken(auth, customLoginToken);
          await removeAuthCodeAndStateFromUrl();
          trackEvent("Logged in", { provider: TRACK_EVENT_SSO_PROVIDER_NAME });
          return setAuthStatus("success");
        } catch (error) {
          trackLoginError(LoginErrorType.GENERAL_APP_ERROR);
          logger.error(
            "useLoginReturningFromAuthServer",
            "Failed to login with custom token",
            {
              errorMessage: (error as Error).message,
              errorStack: (error as Error).stack,
            },
          );
          return setAuthStatus("error");
        }
      } catch (error) {
        trackLoginError(LoginErrorType.GENERAL_APP_ERROR);
        logger.error(
          "useLoginReturningFromAuthServer",
          "Failed to verify when returning from auth server",
          {
            errorMessage: (error as Error).message,
            errorStack: (error as Error).stack,
          },
        );
        return setAuthStatus("error");
      }
    };

    loginUserReturningFromAuthServer();
  }, [
    auth,
    oAuthClient,
    router,
    setAuthStatus,
    removeAuthCodeAndStateFromUrl,
    trackEvent,
  ]);
}
