import {
  Button,
  CheckCircleIcon,
  CrossIcon,
  ExclamationIcon,
  Text,
} from "@olivahealth/oli-ui";
import cx from "classnames";
import { ReactElement, useEffect, useState } from "react";
import tokens from "../../../theme/tokens";

type AlertBannerVariant = "default" | "error" | "warning" | "info" | "success";

interface Props {
  variant?: AlertBannerVariant;
  heading: string;
  description?: string;
  icon?: ReactElement;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  secondaryButtonOnClick?: () => void;
  primaryButtonOnClick?: () => void;
  showClose?: boolean;
  initialIsVisible?: boolean;
}

const variantClasses = {
  default: "bg-neutral-50 border-neutral-500",
  info: "bg-accent-blue-50 border-accent-blue-500",
  success: "bg-status-green-50 border-status-green-500",
  warning: "bg-status-yellow-50 border-status-yellow-500",
  error: "bg-status-red-50 border-status-red-500",
};

export const iconColours = {
  default: tokens.colors["neutral"][500],
  info: tokens.colors["accent-blue"][500],
  success: tokens.colors["status-green"][500],
  warning: tokens.colors["status-yellow"][500],
  error: tokens.colors["status-red"][500],
};

const icons = {
  default: <ExclamationIcon variant="circle" color={iconColours["default"]} />,
  info: <ExclamationIcon variant="square" color={iconColours["info"]} />,
  success: <CheckCircleIcon color={iconColours["success"]} />,
  warning: (
    <ExclamationIcon variant="triangle" color={iconColours["warning"]} />
  ),
  error: <ExclamationIcon variant="circle" color={iconColours["error"]} />,
};

export default function AlertBanner({
  variant = "default",
  heading,
  description,
  icon = icons[variant],
  primaryButtonLabel,
  primaryButtonOnClick,
  secondaryButtonLabel,
  secondaryButtonOnClick,
  showClose = false,
  initialIsVisible = true,
}: Props): ReactElement | null {
  const [isVisible, setIsVisible] = useState<boolean>(initialIsVisible);

  useEffect(() => {
    setIsVisible(initialIsVisible);
  }, [initialIsVisible]);

  return isVisible ? (
    <div className={cx("rounded-xl border p-4", variantClasses[variant])}>
      <div className="flex flex-col">
        <div className="flex items-center">
          <div className="flex w-full items-center gap-x-2">
            {icon}
            <Text weight="bold">{heading}</Text>

            {showClose && (
              <span className="ml-auto">
                <Button
                  variant="icon"
                  onClick={() => setIsVisible(false)}
                  aria-label="CLose alert"
                >
                  <CrossIcon color="currentColor" size={18} />
                </Button>
              </span>
            )}
          </div>
        </div>
        {description || primaryButtonLabel || secondaryButtonLabel ? (
          <div className="mt-2 ml-8 flex flex-col items-start">
            {description && <Text color="secondary">{description}</Text>}

            <div className="flex space-x-6 sm:items-center ">
              {primaryButtonLabel && (
                <span className="mt-4 max-w-max">
                  <Button variant="secondary" onClick={primaryButtonOnClick}>
                    {primaryButtonLabel}
                  </Button>
                </span>
              )}
              {secondaryButtonLabel && (
                <span className="mt-4 max-w-max">
                  <Button variant="link" onClick={secondaryButtonOnClick}>
                    {secondaryButtonLabel}
                  </Button>
                </span>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
}
