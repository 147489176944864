import { useState } from "react";

interface Props {
  size?: number;
}

// TODO: Add some transitions to the path to make animation smoother
const smilePath = {
  small: "M37.5 47.3L41 47.5L44.3 47.3",
  large:
    "M33.7 47.3L36.3778 47.9452C40.073 48.8357 43.927 48.8357 47.6222 47.9452L50.3 47.3",
};

export function CalendarPlaceholder({ size = 96 }: Props) {
  const [path, setPath] = useState(smilePath.small);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 93 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="transition transition-all ease-in-out"
      onMouseEnter={() => {
        setPath(smilePath.large);
      }}
      onMouseLeave={() => {
        setPath(smilePath.small);
      }}
    >
      <circle
        cx="43.5"
        cy="47.5"
        r="42.8"
        fill="white"
        stroke="#91C676"
        strokeWidth="1.4"
      />
      <path
        d="M13.3392 23.573C13.0114 17.1154 18.1588 11.7 24.6247 11.7H66C72.2408 11.7 77.3 16.7592 77.3 23V64C77.3 70.2408 72.2408 75.3 66 75.3H26.7062C20.6881 75.3 15.7259 70.5833 15.4208 64.573L13.3392 23.573Z"
        fill="#DCF8EB"
        stroke="#91C676"
        strokeWidth="1.4"
      />
      <path
        d="M9.38542 21.6123C9.03423 15.1404 14.1874 9.7 20.6688 9.7H66C72.2408 9.7 77.3 14.7592 77.3 21V61.5C77.3 67.7408 72.2408 72.8 66 72.8H22.8665C16.8636 72.8 11.9084 68.1064 11.5831 62.1123L9.38542 21.6123Z"
        fill="#DCF8EB"
        stroke="#91C676"
        strokeWidth="1.4"
      />
      <path
        d="M8.7 21C8.7 14.7592 13.7592 9.7 20 9.7H66C72.2408 9.7 77.3 14.7592 77.3 21V48C77.3 60.3159 67.3159 70.3 55 70.3H20C13.7592 70.3 8.7 65.2408 8.7 59V21Z"
        fill="white"
        stroke="#91C676"
        strokeWidth="1.4"
      />
      <circle cx="26.5" cy="39.5" r="3" fill="#91C676" stroke="#91C676" />
      <circle cx="55.5" cy="39.5" r="3" fill="#91C676" stroke="#91C676" />
      <path d={path} stroke="#91C676" strokeWidth="1.4" strokeLinecap="round" />

      <path
        d="M24.5 16.3425V1"
        stroke="#91C676"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.5 16.3425V1"
        stroke="#91C676"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.5 53C62.5 63 56 67 50.5 70C71 72 78 55.5 77 47.5C72.5 53 67 54 62.5 53Z"
        fill="#DCF8EB"
        stroke="#91C676"
        strokeWidth="1.4"
      />
      <circle opacity="0.5" cx="88" cy="25" r="5" fill="#F5E0EF" />
      <circle opacity="0.5" cx="84" cy="11" r="2" fill="#F5E0EF" />
    </svg>
  );
}
