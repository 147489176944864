import { ReactElement, SyntheticEvent, useState } from "react";
import cx from "classnames";
import ReactAudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { RESOURCE_PROGRESS_TRACKING_INTERVAL_MS } from "@olivahealth/constants";
import {
  CrossIcon,
  SkipTrackIcon,
  TriangleIcon,
  TwoTrianglesIcon,
  VerticalBarsIcon,
  VolumeIcon,
} from "../../atoms/Icons";
import tokens from "../../../theme/tokens";
import * as s from "./styles";

// overrides for the lib's default styling
import "./react-h5-audio-player.css";
import PlayerHeader from "./PlayerHeader";

export type PlayerVariant = "default" | "inline";

type Props = Pick<
  (typeof ReactAudioPlayer)["defaultProps"],
  | "onClickNext"
  | "onClickPrevious"
  | "onEnded"
  | "onLoadedMetaData"
  | "onPause"
  | "onPlay"
  | "onPlayError"
  | "onListen"
  | "src"
> & {
  autoplay?: boolean;
  onClose?: (e: SyntheticEvent<HTMLDivElement>) => void;
  onStart?: (e: Event) => void;
  subtitle?: string;
  thumbnail?: string;
  title?: string;
  variant?: PlayerVariant;
};

export default function AudioPlayer({
  autoplay = false,
  onClickNext,
  onClickPrevious,
  onClose,
  onEnded,
  onListen,
  onLoadedMetaData,
  onPause,
  onPlay,
  onPlayError,
  onStart,
  src,
  subtitle,
  title,
  thumbnail,
  variant = "default",
}: Props): ReactElement | null {
  const isMobile = window?.innerWidth < 768;
  const isDefault = variant === "default";
  const layout = getPlayerLayout(isMobile, isDefault);
  const [hasStarted, setHasStarted] = useState(false);

  const handleClickCloseButton = (e: SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();

    onClose?.(e);
  };

  /**
   * Since the audio player doesn't have a `onStart` callback, we need to use the `onListen` callback
   * to determine when the audio has started playing. When this function is called for the first time,
   * we trigger the onStart callback, on subsequent calls we trigger the onListen callback.
   */
  const handleListenCallback = (e: Event) => {
    if (!hasStarted) {
      onStart?.(e);
      setHasStarted(true);
    }

    onListen?.(e);
  };

  return (
    <div
      className={cx({
        [s.wrapper]: isDefault,
        [s.wrapperInline]: !isDefault,
      })}
      data-testid="audio-player"
    >
      {isDefault && (
        <div
          role="button"
          tabIndex={0}
          onClick={handleClickCloseButton}
          onKeyDown={handleClickCloseButton}
          className={s.closeButton}
        >
          <CrossIcon />
        </div>
      )}
      <ReactAudioPlayer
        showFilledVolume
        showSkipControls={isDefault}
        showJumpControls={false}
        autoPlay={autoplay}
        src={src}
        customAdditionalControls={[]}
        customControlsSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
        customIcons={{
          play: (
            <span className={s.playPauseButton}>
              <TriangleIcon
                variant="right"
                color={tokens.colors["purple"][500]}
                size={48}
              />
            </span>
          ),
          pause: (
            <span className={s.playPauseButton}>
              <VerticalBarsIcon
                color={tokens.colors["purple"][500]}
                size={48}
              />
            </span>
          ),
          previous: (
            <SkipTrackIcon
              variant="left"
              color={tokens.colors.white}
              size={32}
            />
          ),
          next: (
            <SkipTrackIcon
              variant="right"
              color={tokens.colors.white}
              size={32}
            />
          ),
          rewind: (
            <TwoTrianglesIcon
              variant="left"
              color={tokens.colors.white}
              size={32}
            />
          ),
          forward: (
            <TwoTrianglesIcon
              variant="right"
              color={tokens.colors.white}
              size={32}
            />
          ),
          volume: (
            <VolumeIcon variant="down" color={tokens.colors["purple"][200]} />
          ),
          volumeMute: (
            <VolumeIcon variant="off" color={tokens.colors["purple"][200]} />
          ),
        }}
        layout={layout}
        listenInterval={RESOURCE_PROGRESS_TRACKING_INTERVAL_MS}
        onEnded={onEnded}
        onListen={handleListenCallback}
        onLoadedMetaData={onLoadedMetaData}
        onPause={onPause}
        onPlay={onPlay}
        onPlayError={onPlayError}
        onClickPrevious={onClickPrevious}
        onClickNext={onClickNext}
        showDownloadProgress={false}
        header={
          isDefault ? (
            <PlayerHeader
              subtitle={subtitle}
              thumbnail={thumbnail}
              title={title}
            />
          ) : null
        }
      />
    </div>
  );
}

function getPlayerLayout(
  isMobile: boolean,
  isDefault: boolean,
): (typeof ReactAudioPlayer)["defaultProps"]["layout"] {
  if (isMobile && isDefault) {
    return "stacked-reverse";
  }

  return "horizontal-reverse";
}
