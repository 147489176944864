import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function LetterIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <rect
        x="22"
        y="4"
        width="17"
        height="20"
        rx="7"
        transform="rotate(90 22 4)"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M16.7543 9.00001C16.4942 9.25967 16.2136 9.4987 15.915 9.71444C14.7797 10.5348 13.3849 11.0182 11.8771 11.0182C9.97289 11.0182 8.24876 10.247 6.99999 9.00001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
