import { ReactNode } from "react";
import { useRouter } from "next/router";
import cx from "classnames";
import { Button, ExclamationIcon } from "@olivahealth/oli-ui";

import * as s from "./styles";

export interface Props {
  buttonLabel?: string | null;
  buttonLink?: string;
  description?: ReactNode;
  onClickButton?: () => void;
  showIcon?: boolean;
  title: string;
  variant?: "light" | "dark";
}

export default function ErrorPage({
  buttonLabel,
  buttonLink,
  description,
  onClickButton,
  showIcon = true,
  title,
  variant = "dark",
}: Props): JSX.Element {
  const { push } = useRouter();

  const showButton = buttonLabel && (buttonLink || onClickButton);
  const isDarkVariant = variant === "dark";

  const handleClickButton = () => {
    if (buttonLink) {
      return push(buttonLink);
    }

    if (onClickButton) {
      return onClickButton();
    }
  };

  return (
    <div
      className={cx(s.container, {
        "bg-blue-800": isDarkVariant,
        "bg-blue-100": !isDarkVariant,
      })}
    >
      <div className={s.textColumn}>
        <div
          className={cx(s.textWrapper, {
            "text-white": isDarkVariant,
            "text-purple-500": !isDarkVariant,
          })}
        >
          {showIcon && <ExclamationIcon size={48} className="mb-8" />}
          <h1 className="mb-4 text-4xl">{title}</h1>
          <p>{description}</p>
          {showButton && (
            <div className="mt-12">
              <Button
                variant={isDarkVariant ? "tertiary" : "primary"}
                onClick={handleClickButton}
              >
                {buttonLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={s.imageColumn}>
        <div className={s.image} />
      </div>
    </div>
  );
}
