import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  variant?: "outline" | "dashed" | "solid";
}

export function CircleIcon({
  className,
  color = "currentColor",
  size,
  strokeWidth = "2",
  variant = "outline",
}: Readonly<Props>): ReactElement {
  const variantProps = {
    outline: {},
    dashed: {
      strokeDasharray: "2 4",
    },
    solid: {
      fill: color,
    },
  };

  return (
    <SvgIcon size={size} className={className}>
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...variantProps[variant]}
      />
    </SvgIcon>
  );
}
