import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useUser } from "@olivahealth/firebase/client";
import logger from "@olivahealth/logger/client";
import { ENVIRONMENT } from "@olivahealth/constants";

import { OlivaHook } from "../../../../hooks/OlivaHook";
import { useSignOut } from "../../../../services/contexts/SignOutContext";

const INACTIVITY_TIMER =
  (ENVIRONMENT === "production" ? 2 : 24) * 60 * 60 * 1000; // 2h / 24h

interface UseSignedOutAfterInactivityMiddlewareData {
  signedOut: boolean;
}

interface UseSignedOutAfterInactivityMiddlewareEffects {
  setSignedOut: Dispatch<SetStateAction<boolean>>;
}
type UseSignedOutAfterInactivityMiddleware = OlivaHook<
  UseSignedOutAfterInactivityMiddlewareData,
  UseSignedOutAfterInactivityMiddlewareEffects
>;

export default function useSignedOutAfterInactivityMiddleware(): UseSignedOutAfterInactivityMiddleware {
  const { signOut } = useSignOut();
  const { data: connectedUser } = useUser();

  const [signedOut, setSignedOut] = useState<boolean>(false);

  const onIdle = () => {
    logger.info(
      "useSignedOutAfterInactivityMiddleware",
      "Automatically signing out user after inactivity",
      {
        inactivityTimer: INACTIVITY_TIMER,
        activeTime: getActiveTime(),
        elapsedTime: getElapsedTime(),
        lastActiveTime: getLastActiveTime(),
        totalActiveTime: getTotalActiveTime(),
        totalElapsedTime: getTotalElapsedTime(),
        totalIdleTime: getTotalIdleTime(),
      },
    );
    signOut("Automatic sign out after inactivity");
    setSignedOut(true);
  };

  const {
    getActiveTime,
    getElapsedTime,
    getLastActiveTime,
    getTotalActiveTime,
    getTotalElapsedTime,
    getTotalIdleTime,
    start,
  } = useIdleTimer({
    onIdle,
    eventsThrottle: 500,
    startManually: true,
    stopOnIdle: true,
    timeout: INACTIVITY_TIMER,
    crossTab: true,
  });

  useEffect(() => {
    if (connectedUser) {
      start();
    }
  }, [connectedUser, start]);

  return {
    status: "success",
    data: {
      signedOut,
    },
    effects: {
      setSignedOut,
    },
  };
}
