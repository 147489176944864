import ctl from "@netlify/classnames-template-literals";

export const getLabelStyle = (hasLabelText: boolean, isDisabled: boolean) =>
  ctl(`
    flex
    p-2
    text-base
    text-neutral-900
    ${isDisabled ? "" : "cursor-pointer"}
`);

export const getInputStyle = (hasLabelText: boolean, isDisabled: boolean) =>
  ctl(`
    w-6
    h-6
    ${hasLabelText ? "mr-4" : ""}
    text-neutral-50
    border-2
    ${isDisabled ? "border-neutral-300" : "border-neutral-900"}
    checked:text-purple-200
    checked:border-purple-500
    checked:bg-dot-radio-button
    checked:hover:border-purple-500
    checked:hover:text-purple-200
    focus:ring-0
    ${isDisabled ? "" : "hover:border-purple-500"}
`);
