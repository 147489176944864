import ctl from "@netlify/classnames-template-literals";

export const tabWrapper = ctl(`
  focus-visible:outline-none
  h-full
`);

export const tabBase = ctl(`
  border-b-2
  cursor-pointer
  py-4
  flex
  flex-col
  h-full
`);

export const tabInactive = ctl(`
  border-none
  text-gray-500
`);

export const tabActive = ctl(`
  text-neutral-900  
  font-bold
  border-black
`);

export const headerWrapper = ctl(`
  flex
  flex-col
  lg:flex-row
`);

export const iconTagWrapper = ctl(`
  flex
  items-center
  lg:items-start
  order-first
  lg:order-none
  mb-2
  lg:mb-0
`);

export const xsTagWrapper = ctl(`
  ml-2 
  lg:hidden
`);

export const lgTagWrapper = ctl(`
  ml-0
  lg:ml-2 
  hidden
  lg:inline
`);
