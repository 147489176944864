import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function LinkIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M4.6878 11.1651C4.60375 11.3107 4.52551 11.458 4.45303 11.6069C2.85874 14.881 4.04753 18.8783 7.24998 20.7272C10.598 22.6602 14.8792 21.5131 16.8122 18.1651"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19.3122 13.8349C19.3963 13.6893 19.4745 13.542 19.547 13.3931C21.1413 10.119 19.9525 6.1217 16.75 4.27276C13.402 2.33976 9.12084 3.48689 7.18784 6.83494"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13.75 9.46891L10.25 15.5311"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
