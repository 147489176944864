import ctl from "@netlify/classnames-template-literals";

export const container = ctl(`
  flex
  w-full
  h-full
  md:grid
  md:grid-cols-2
  md:gap-4
`);

export const textColumn = ctl(`
  w-full
  md:col-span-1
`);

export const textWrapper = ctl(`
  flex
  flex-col
  h-full
  justify-center
  pl-8
  md:pl-40
`);

export const imageColumn = ctl(`
  hidden
  md:flex
  md:col-span-1
`);

export const image = ctl(`
  w-full
  h-full
  bg-handstrings
  bg-cover
`);
