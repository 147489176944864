import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function TrashCanIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M4.64888 8.4595L5.87371 17.1401C6.22181 19.6072 8.33314 21.4415 10.8247 21.4415L13.1755 21.4415C15.6671 21.4415 17.7784 19.6072 18.1265 17.1401L19.3513 8.4595"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M2.74561 8.45953C8.70096 6.15994 15.2992 6.15994 21.2545 8.45953"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.60614 3.95908C9.28361 2.78794 10.5498 2 12.0001 2C13.4504 2 14.7166 2.78794 15.3941 3.95908"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
