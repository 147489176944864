import { ReactNode } from "react";
import Panel from "../../atoms/Panel";
import * as s from "./styles";

interface Props {
  panelChildren?: ReactNode;
  children?: ReactNode;
}

export default function LayoutWithPanel({ panelChildren, children }: Props) {
  return (
    <div className={s.wrapper}>
      <div className="flex w-2/5">
        <Panel>{panelChildren}</Panel>
      </div>
      <main className="flex w-3/5">{children}</main>
    </div>
  );
}
