import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function FunnelIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M10 15.9051L10 20C10 20.8241 10.9408 21.2944 11.6 20.8L12.8 19.9C13.5555 19.3334 14 18.4443 14 17.5V15.8176C14 14.9152 14.4063 14.0607 15.1062 13.491L18.8736 10.4244C19.5863 9.84419 20 8.97401 20 8.055C20 6.36777 18.6322 5 16.945 5H7.06463C5.37209 5 4.00001 6.37209 4.00002 8.06463C4.00003 8.97414 4.40401 9.83665 5.10271 10.4189L8.92057 13.6005C9.60454 14.1704 10 15.0148 10 15.9051Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
