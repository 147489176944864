import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function ThreeDotsIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <circle cx="12" cy="5" r="2" fill={color} />
      <circle cx="12" cy="12" r="2" fill={color} />
      <circle cx="12" cy="19" r="2" fill={color} />
    </SvgIcon>
  );
}
