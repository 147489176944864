import { ReactNode } from "react";
import { useWindowWidth } from "@react-hook/window-size";
import UserRole from "@olivahealth/graphql-server/src/domain/value-objects/UserRole";
import { MOBILE_BREAKPOINT } from "../../../utils/breakpoints";
import { TextColor } from "../../atoms/Text";
import NavbarMobile from "./NavbarMobile";
import NavbarDesktop from "./NavbarDesktop";

export enum NavbarLinkType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  ORGANISATION_ADMIN = "organisation_admin",
  ADMIN = "admin",
}

export interface NavbarLink {
  activeRegex?: string;
  href?: string;
  icon?: ReactNode;
  isExternal?: boolean;
  label: string;
  role: UserRole[];
  type: NavbarLinkType;
  id?: string;
  rightSideIcon?: ReactNode;
  color?: TextColor;
}

export interface NavbarProps {
  isOrganisationAdmin?: boolean;
  hasTeamSessionsOnly?: boolean;
  labels: {
    admin: string;
    careJourney: string;
    company: string;
  };
  links: NavbarLink[];
}

/**
 * On mobile the Navbar is a dropdown menu, on desktop it's a vertical list.
 * Administration section  is rendered only on desktop.
 */
export default function Navbar({
  isOrganisationAdmin,
  hasTeamSessionsOnly,
  labels,
  links,
}: NavbarProps): JSX.Element {
  const isMobile = useWindowWidth() < MOBILE_BREAKPOINT;
  const {
    adminLinks,
    mobileLinks,
    organisationAdminLinks,
    primaryLinks,
    secondaryLinks,
    tertiaryLinks,
  } = getLinks(links);

  if (isMobile && mobileLinks.length > 0) {
    return (
      <NavbarMobile
        mobileLinks={mobileLinks}
        organisationAdminLinks={organisationAdminLinks}
        isOrganisationAdmin={isOrganisationAdmin}
        labels={labels}
      />
    );
  }

  return (
    <NavbarDesktop
      adminLinks={adminLinks}
      organisationAdminLinks={organisationAdminLinks}
      primaryLinks={primaryLinks}
      secondaryLinks={secondaryLinks}
      tertiaryLinks={tertiaryLinks}
      isOrganisationAdmin={isOrganisationAdmin}
      hasTeamSessionsOnly={hasTeamSessionsOnly}
      labels={labels}
    />
  );
}

interface Links {
  adminLinks: NavbarLink[];
  mobileLinks: NavbarLink[];
  organisationAdminLinks: NavbarLink[];
  primaryLinks: NavbarLink[];
  secondaryLinks: NavbarLink[];
  tertiaryLinks: NavbarLink[];
}

export function getLinks(links: NavbarLink[]): Links {
  const mobileLinks = links.filter(
    (link) =>
      link.type === NavbarLinkType.PRIMARY ||
      link.type === NavbarLinkType.SECONDARY ||
      link.type === NavbarLinkType.TERTIARY,
  );
  const organisationAdminLinks = links.filter(
    (link) =>
      link.type === NavbarLinkType.ORGANISATION_ADMIN &&
      link.role.includes(UserRole.ORGANISATION_ADMIN),
  );
  const adminLinks = links.filter((link) => link.type === NavbarLinkType.ADMIN);
  const primaryLinks = links.filter(
    (link) => link.type === NavbarLinkType.PRIMARY,
  );
  const secondaryLinks = links.filter(
    (link) => link.type === NavbarLinkType.SECONDARY,
  );
  const tertiaryLinks = links.filter(
    (link) => link.type === NavbarLinkType.TERTIARY,
  );

  return {
    adminLinks,
    mobileLinks,
    organisationAdminLinks,
    primaryLinks,
    secondaryLinks,
    tertiaryLinks,
  };
}
