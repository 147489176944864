import tokens from "../../../theme/tokens";

type LogoColor = "black" | "white" | "light" | "dark";
type LogoVariant = "symbol" | "wordmark" | "full";

interface Props {
  color?: LogoColor;
  height?: number;
  variant?: LogoVariant;
}

const DEFAULT_LOGO_HEIGHT = 38;

export default function Logo({
  color = "dark",
  height = DEFAULT_LOGO_HEIGHT,
  variant = "full",
}: Props): JSX.Element {
  const logoColor = getLogoColor(color);
  const path = getLogoPath(variant, logoColor);
  const viewBox = getLogoViewBox(variant);
  const width = getLogoWith(variant, height);

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      {path}
    </svg>
  );
}

export function getLogoColor(color: LogoColor): string {
  switch (color) {
    case "black":
      return tokens.colors.black;
    case "white":
      return tokens.colors.white;
    case "light":
      return tokens.colors["purple"][500];
    case "dark":
      return tokens.colors["purple"][900];
  }
}

export function getLogoPath(variant: LogoVariant, color: string): JSX.Element {
  switch (variant) {
    case "symbol":
      return (
        <g clipPath="url(#clip0_31_3071)">
          <path
            d="M0.317781 27.9732L4.4829 38C11.2668 35.1998 18.5473 35.4035 24.8318 38L28.9969 27.9732C20.1361 24.3132 9.87655 24.0247 0.314941 27.9732H0.317781Z"
            fill={color}
          />
          <path
            d="M14.6602 0C8.65938 0 3.79346 4.85076 3.79346 10.8329C3.79346 16.815 8.65938 21.6658 14.6602 21.6658C20.661 21.6658 25.527 16.815 25.527 10.8329C25.527 4.85076 20.661 0 14.6602 0ZM14.6602 15.3471C12.1606 15.3471 10.1319 13.3276 10.1319 10.8329C10.1319 8.33822 12.1577 6.31872 14.6602 6.31872C17.1627 6.31872 19.1885 8.34105 19.1885 10.8329C19.1885 13.3247 17.1598 15.3471 14.6602 15.3471Z"
            fill={color}
          />
        </g>
      );
    case "wordmark":
      return (
        <g clipPath="url(#clip0_31_3105)">
          <path
            d="M12.9011 11.3279C5.77669 11.3279 0 17.0866 0 24.1888C0 31.291 5.77669 37.0497 12.9011 37.0497C20.0255 37.0497 25.8022 31.291 25.8022 24.1888C25.8022 17.0866 20.0255 11.3279 12.9011 11.3279ZM12.9011 29.5486C9.93329 29.5486 7.52445 27.1501 7.52445 24.1888C7.52445 21.2274 9.93045 18.8289 12.9011 18.8289C15.8717 18.8289 18.2777 21.2274 18.2777 24.1888C18.2777 27.1501 15.8717 29.5486 12.9011 29.5486Z"
            fill={color}
          />
          <path
            d="M34.7567 1.77909V36.4076H27.5132V1.77909H34.7567Z"
            fill={color}
          />
          <path
            d="M37.103 36.4076V11.9699H44.3466V36.4076H37.103Z"
            fill={color}
          />
          <path
            d="M44.8291 11.9699H52.4699L58.2267 27.4047L63.9835 11.9699H71.4257L61.4527 36.4076H54.8021L44.8291 11.9699Z"
            fill={color}
          />
          <path
            d="M85.4274 36.4076L85.3281 33.3416C83.8896 35.8645 81.0608 37.0525 78.1328 37.0525C73.5676 37.0525 70.4409 34.1844 70.4409 29.8796C70.4409 25.5747 73.4683 22.6077 79.5713 21.7167L85.1295 20.9248V20.6278C85.1295 18.5998 83.4924 18.0567 80.1671 18.0567H71.9305V11.9728H80.9133C88.0093 11.9728 92.129 14.7418 92.129 21.0747V36.4104H85.4302L85.4274 36.4076ZM80.1189 31.5597C83.1462 31.5597 85.1295 29.3818 85.1295 26.6127V25.7727L80.8112 26.4657C78.4789 26.8107 77.2873 27.8516 77.2873 29.2856C77.3355 30.6715 78.4789 31.5597 80.116 31.5597H80.1189Z"
            fill={color}
          />
          <path
            d="M40.7261 9.18393C43.1017 9.18393 45.0274 7.26416 45.0274 4.89602C45.0274 2.52788 43.1017 0.608116 40.7261 0.608116C38.3506 0.608116 36.4248 2.52788 36.4248 4.89602C36.4248 7.26416 38.3506 9.18393 40.7261 9.18393Z"
            fill={color}
          />
        </g>
      );
    case "full":
      return (
        <g clipPath="url(#clip0_31_3091)">
          <path
            d="M12.9011 11.3279C5.77669 11.3279 0 17.0866 0 24.1888C0 31.291 5.77669 37.0497 12.9011 37.0497C20.0255 37.0497 25.8022 31.291 25.8022 24.1888C25.8022 17.0866 20.0255 11.3279 12.9011 11.3279ZM12.9011 29.5486C9.93329 29.5486 7.52445 27.1501 7.52445 24.1888C7.52445 21.2274 9.93045 18.8289 12.9011 18.8289C15.8717 18.8289 18.2777 21.2274 18.2777 24.1888C18.2777 27.1501 15.8717 29.5486 12.9011 29.5486Z"
            fill={color}
          />
          <path
            d="M34.7567 1.77909V36.4076H27.5132V1.77909H34.7567Z"
            fill={color}
          />
          <path
            d="M37.103 36.4076V11.9699H44.3466V36.4076H37.103Z"
            fill={color}
          />
          <path
            d="M44.8291 11.9699H52.4699L58.2267 27.4047L63.9835 11.9699H71.4257L61.4527 36.4076H54.8021L44.8291 11.9699Z"
            fill={color}
          />
          <path
            d="M85.4274 36.4076L85.3281 33.3416C83.8896 35.8645 81.0608 37.0525 78.1328 37.0525C73.5676 37.0525 70.4409 34.1844 70.4409 29.8796C70.4409 25.5747 73.4683 22.6077 79.5713 21.7167L85.1295 20.9248V20.6278C85.1295 18.5998 83.4924 18.0567 80.1671 18.0567H71.9305V11.9728H80.9133C88.0093 11.9728 92.129 14.7418 92.129 21.0747V36.4104H85.4302L85.4274 36.4076ZM80.1189 31.5597C83.1462 31.5597 85.1295 29.3818 85.1295 26.6127V25.7727L80.8112 26.4657C78.4789 26.8107 77.2873 27.8516 77.2873 29.2856C77.3355 30.6715 78.4789 31.5597 80.116 31.5597H80.1189Z"
            fill={color}
          />
          <path
            d="M40.7261 9.18393C43.1017 9.18393 45.0274 7.26416 45.0274 4.89602C45.0274 2.52788 43.1017 0.608116 40.7261 0.608116C38.3506 0.608116 36.4248 2.52788 36.4248 4.89602C36.4248 7.26416 38.3506 9.18393 40.7261 9.18393Z"
            fill={color}
          />
          <path
            d="M104.318 27.9732L108.483 38C115.267 35.1998 122.547 35.4035 128.832 38L132.997 27.9732C124.136 24.3132 113.877 24.0247 104.315 27.9732H104.318Z"
            fill={color}
          />
          <path
            d="M118.66 0C112.659 0 107.793 4.85076 107.793 10.8329C107.793 16.815 112.659 21.6658 118.66 21.6658C124.661 21.6658 129.527 16.815 129.527 10.8329C129.527 4.85076 124.661 0 118.66 0ZM118.66 15.3471C116.161 15.3471 114.132 13.3276 114.132 10.8329C114.132 8.33822 116.158 6.31872 118.66 6.31872C121.163 6.31872 123.188 8.34105 123.188 10.8329C123.188 13.3247 121.16 15.3471 118.66 15.3471Z"
            fill={color}
          />
        </g>
      );
  }
}

export function getLogoViewBox(variant: LogoVariant): string {
  switch (variant) {
    case "symbol":
      return "0 0 29 38";
    case "wordmark":
      return "0 0 92 38";
    case "full":
      return "0 0 133 38";
  }
}

/**
 * Given the variant and height we calculate the width of the logo to keep the same aspect ratio for each variant
 * @param variant
 * @param height
 * @returns number
 */
export function getLogoWith(variant: LogoVariant, height: number): number {
  /**
   * The aspect ratio is the relation between the width and the height of the logo
   * For example: full logo ratio is 3.5 (width is 3.5 times bigger than the height)
   */
  const ratios = {
    symbol: 0.76,
    wordmark: 2.42,
    full: 3.5,
  };

  return Math.round(height * ratios[variant]);
}
