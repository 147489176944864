import { ReactNode } from "react";
import Text from "../../atoms/Text";
import Button, { ButtonVariant } from "../../atoms/Button";
import Tag from "../../atoms/Tag";
import * as s from "./styles";

export interface Props {
  buttonLabel: string;
  buttonOnClick: () => void;
  buttonVariant?: ButtonVariant;
  content: ReactNode;
  image?: ReactNode;
  info?: ReactNode | string;
  variant?: "default" | "secondary";
  tagLabel?: string;
  title: string;
  theme?: "dark" | "light" | "grey";
}

export default function FeaturedBanner({
  buttonLabel,
  buttonOnClick,
  buttonVariant = "primary",
  content,
  image,
  info,
  variant = "default",
  tagLabel,
  title,
  theme = "dark",
}: Props): JSX.Element {
  const isDark = theme === "dark";
  const tag = getTag({ tagLabel, isDark });

  return (
    <div className={s.card(theme)}>
      <div className={s.content}>
        <div className="flex flex-col">
          {tag}
          <Text variant={variant === "default" ? "h2" : "h3"} gutter="sm">
            {title}
          </Text>
          <Text
            variant="body"
            size={variant === "default" ? "xl" : "base"}
            gutter="sm"
            component="span"
          >
            {content}
          </Text>
        </div>
        <div className="mt-6">
          <Button variant={buttonVariant} onClick={buttonOnClick}>
            {buttonLabel}
          </Button>
        </div>
        {info && (
          <div className="mt-4">
            <Text variant="body" size="sm" color="light" weight="medium">
              {info}
            </Text>
          </div>
        )}
      </div>
      {image && <div className={s.imageWrapper}>{image}</div>}
    </div>
  );
}

function getTag({
  tagLabel,
  isDark,
}: { isDark: boolean } & Pick<Props, "tagLabel">): JSX.Element | null {
  if (!tagLabel) {
    return null;
  }

  return (
    <div className="mb-4">
      <Tag
        theme={isDark ? "dark" : "light"}
        variant={isDark ? "popular" : "info"}
        weight="bold"
      >
        {tagLabel}
      </Tag>
    </div>
  );
}
