import ctl from "@netlify/classnames-template-literals";

export const wrapper = ctl(`
  flex
  flex-col
  w-full
  justify-between
  border-gray-200
  bg-white
  px-3
  py-3
  sm:px-6
`);

export const container = ctl(`
  hidden
  w-full
  sm:flex
  sm:flex-1
  sm:items-center
  sm:justify-between
`);

export const navbar = ctl(`
  flex
  space-x-2
  text-center
  items-center
  justify-center
  rounded-md
`);

export const rowsInfo = ctl(`
  flex
  h-8
  items-center
  justify-between
  space-x-2
  text-sm
  text-neutral-600
`);
