import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function MapPinIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M4 11C4 6.58172 7.58172 3 12 3C16.4183 3 20 6.58172 20 11C20 12.8025 19.303 14.4541 18.1844 16.0225C17.0595 17.5996 15.5485 19.037 14.0005 20.4023L13.5201 20.826C12.6515 21.592 11.3485 21.592 10.4799 20.826L9.99955 20.4023C8.4515 19.037 6.9405 17.5996 5.81562 16.0225C4.69698 14.4541 4 12.8025 4 11Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="12"
        cy="10.1716"
        r="2"
        transform="rotate(-45 12 10.1716)"
        fill={color}
      />
    </SvgIcon>
  );
}
