import ctl from "@netlify/classnames-template-literals";
import cx from "classnames";
import { ReactNode, useEffect, useState } from "react";

import Button, { ButtonVariant } from "../../atoms/Button";
import { CrossIcon } from "../../atoms/Icons";
import Text from "../../atoms/Text";
import tokens from "../../../theme/tokens";
import * as s from "./styles";

export type BannerVariant =
  | "primary"
  | "secondary"
  | "warning"
  | "error"
  | "info";
type BannerActionType = "button" | "link";
type BannerGutter = "none" | "md";

export interface BannerAction {
  action: () => void;
  title: string;
  actionType?: BannerActionType;
}

interface ActionButtonProps {
  cta: BannerAction;
  variant: ButtonVariant;
}

interface ClassWrapper {
  wrapperClass: string;
  iconColor: string;
  primaryButtonClass: string;
  secondaryButtonClass: string;
}

interface ClassesType {
  [key: string]: ClassWrapper;
}

export interface Props {
  dataTestId?: string;
  gutter?: BannerGutter;
  icon?: ReactNode;
  messageIcon?: ReactNode;
  isOpen?: boolean;
  message: string;
  onCloseHook?: () => void;
  description?: string | ReactNode;
  showCloseButton?: boolean;
  primaryAction?: BannerAction;
  secondaryAction?: BannerAction;
  badge?: ReactNode;
  variant?: BannerVariant;
}

const classWrapper: ClassesType = {
  info: {
    wrapperClass: s.info,
    iconColor: "text-status-teal-700",
    primaryButtonClass: "info",
    secondaryButtonClass: "secondary",
  },
  primary: {
    wrapperClass: s.purple,
    iconColor: "bg-oli-primary-700",
    primaryButtonClass: "primary",
    secondaryButtonClass: "secondary",
  },
  secondary: {
    wrapperClass: s.purpleDark,
    iconColor: "bg-oli-primary-700",
    primaryButtonClass: "primary",
    secondaryButtonClass: "secondaryInverse",
  },
  warning: {
    wrapperClass: s.warning,
    iconColor: "text-status-yellow-700",
    primaryButtonClass: "warning",
    secondaryButtonClass: "secondary",
  },
  error: {
    wrapperClass: s.error,
    iconColor: "text-status-red-700",
    primaryButtonClass: "error",
    secondaryButtonClass: "secondary",
  },
};

export default function MessageBanner({
  dataTestId,
  gutter = "none",
  primaryAction,
  secondaryAction,
  message,
  icon,
  description,
  isOpen = false,
  onCloseHook,
  messageIcon,
  showCloseButton = true,
  badge,
  variant = "primary",
}: Props) {
  const [open, setOpen] = useState<boolean>(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if (!open) {
    return null;
  }

  const containerStyles = classWrapper[variant];
  const openedClassName = getClassesOpened({ gutter });

  const isLink = (actionType: BannerActionType | undefined) =>
    actionType === "link";

  return (
    <div
      className={cx(s.messageBannerWrapper, containerStyles.wrapperClass, {
        [openedClassName]: open,
      })}
      data-testid={dataTestId}
    >
      {icon && (
        <div className={cx(s.iconWrapper, containerStyles.iconColor)}>
          {icon}
        </div>
      )}
      <div className={s.messageAndCtaWrapper(Boolean(icon))}>
        {messageIcon && <div>{messageIcon}</div>}
        <div className="flex w-full flex-col items-start justify-between">
          {badge && <div className="flex justify-end w-full pb-2">{badge}</div>}
          <div className="flex-col">
            <Text gutter={description ? "xs" : "md"} weight="bold">
              {message}
            </Text>
            {description && typeof description === "string" ? (
              <Text gutter="md">{description}</Text>
            ) : (
              description
            )}
            <div className={s.buttonWrapper}>
              {primaryAction &&
                ActionButton({
                  cta: primaryAction,
                  variant: (isLink(primaryAction.actionType)
                    ? "link"
                    : containerStyles.primaryButtonClass) as ButtonVariant,
                })}
              {secondaryAction &&
                ActionButton({
                  cta: secondaryAction,
                  variant: (isLink(secondaryAction.actionType)
                    ? "link"
                    : containerStyles.secondaryButtonClass) as ButtonVariant,
                })}
            </div>
          </div>
        </div>
        {showCloseButton && (
          <div className={s.closeIcon}>
            <Button
              variant="icon"
              size="xs"
              onClick={() => {
                setOpen(false);
                onCloseHook?.();
              }}
            >
              <CrossIcon color={tokens.colors["neutral"][700]} size={14} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

const ActionButton = ({ cta, variant }: ActionButtonProps) => {
  const { title, action } = cta;

  return (
    <Button className="w-full lg:w-auto" variant={variant} onClick={action}>
      {title}
    </Button>
  );
};

function getClassesOpened({ gutter }: { gutter: BannerGutter }): string {
  const gutterClasses = {
    none: "",
    md: "mb-16",
  };

  return ctl(`${gutterClasses[gutter]}`);
}
