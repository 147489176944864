import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function HomeIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M21.1191 11.8393V16.9783C21.1191 19.1874 19.3283 20.9783 17.1191 20.9783H6.88086C4.67172 20.9783 2.88086 19.1874 2.88086 16.9783V11.8393C2.88086 10.6135 3.3311 9.4305 4.14606 8.51492L8.2652 3.88719C10.2539 1.65298 13.7461 1.65298 15.7348 3.88719L19.8539 8.51492C20.6689 9.4305 21.1191 10.6135 21.1191 11.8393Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle cx="12" cy="15.8145" r="2" fill={color} />
    </SvgIcon>
  );
}
