import ctl from "@netlify/classnames-template-literals";

export const wrapper = ctl(`
  flex
  flex-col
  rounded-xl
`);

export const ratingWrapper = ctl(`
  flex
  flex-wrap
  gap-1
  md:flex-nowrap
  justify-start
`);

export const labelsWrapper = ctl(`
  mt-2
  flex
  justify-between
`);
