import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

type LockVariant = "default" | "check";

interface Props extends IconProps {
  variant?: LockVariant;
}

export function LockIcon({
  className,
  color = "currentColor",
  size,
  variant = "default",
}: Props): ReactElement {
  const variants = {
    default: (
      <g>
        <path
          d="M16 7V6C16 3.79086 14.2091 2 12 2V2C9.79086 2 8 3.79086 8 6V7"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M14 6C17.866 6 21 9.13401 21 13V15C21 18.866 17.866 22 14 22H10C6.13401 22 3 18.866 3 15L3 13C3 9.13401 6.13401 6 10 6L14 6Z"
          stroke={color}
          strokeWidth="2"
        />
        <circle
          cx="12"
          cy="11.8284"
          r="2"
          transform="rotate(45 12 11.8284)"
          fill={color}
        />
      </g>
    ),
    check: (
      <g>
        <path
          d="M16 6V6C16 3.79086 14.2091 2 12 2V2C9.79086 2 8 3.79086 8 6V6"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M21 12.2604V15.2C21 18.9555 18.0695 22 14.4545 22H9.54545C5.9305 22 3 18.9555 3 15.2L3 11.8C3 8.04446 5.9305 5 9.54546 5L14.4545 5C15.6468 5 16.7645 5.33114 17.7273 5.90972"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M15 9.05769L16.473 10.9803C16.8694 11.4977 17.6468 11.5037 18.0511 10.9925L22 6"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle
          cx="12"
          cy="11.8284"
          r="2"
          transform="rotate(45 12 11.8284)"
          fill={color}
        />
      </g>
    ),
  };
  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
