import {
  useFirestoreDocData,
  useFirestore,
  useAuth,
  useUser,
  FirebaseAppProvider,
  useSigninCheck,
  useFirebaseApp,
  useIdTokenResult,
  FirestoreProvider,
  useFirestoreCollectionData,
  useFirestoreCollection,
  AuthProvider,
} from "reactfire";

import { getAuth } from "@firebase/auth";
import { getFirestore, getDocs, collection } from "@firebase/firestore";
import { initializeApp, getApp, getApps } from "@firebase/app";

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}.appspot.com`,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

if (getApps().length === 0) {
  initializeApp(config);
}

export {
  config,
  useAuth,
  getApp,
  getDocs,
  collection,
  AuthProvider,
  useUser,
  getAuth,
  useSigninCheck,
  useIdTokenResult,
  FirebaseAppProvider,
  useFirestoreCollectionData,
  useFirebaseApp,
  getFirestore,
  FirestoreProvider,
  useFirestoreDocData,
  useFirestoreCollection,
  useFirestore,
};
