import { Transition } from "@headlessui/react";
import cx from "classnames";
import { add, format } from "date-fns";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { DayPicker, useNavigation } from "react-day-picker";

import useClickOutside from "@olivahealth/utils/reactHooks/useClickOutside";
import { ChevronIcon, CalendarIcon } from "../../atoms/Icons";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import * as s from "../../shared/inputStyles";
import * as sharedStyles from "./styles";
import "./style.css";

// https://github.com/gpbl/react-day-picker/blob/6fc2614/src/components/Caption/Caption.tsx#L13
interface CaptionProps {
  /** The ID for the heading element. Must be the same as the labelled-by in Table. */
  id?: string;
  /** The month when the caption is displayed. */
  displayMonth: Date;
}

type Variant = "default" | "date-time";

export interface TimeRange {
  start: string;
  end: string;
}

export interface Props {
  disabled?: boolean;
  hasError?: boolean;
  id: string;
  label?: string;
  removeLabel?: string;
  enabledAfterDays?: number;
  onChange: (item: string | Date | undefined) => void;
  placeholder?: string;
  preSelectedItem?: Date;
  size?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  required?: boolean;
  fixedWeeks?: boolean;
  showOutsideDays?: boolean;
  labelInfoIcon?: ReactElement;
  includeConfirmCancel?: boolean;
  times?: TimeRange[];
  preSelectedTime?: TimeRange;
  fullWidthButton?: boolean;
  onTimeChange?: (time: TimeRange) => void;
  setIsDayPickerOpen?: Dispatch<SetStateAction<boolean>>;
  variant?: Variant;
}

const defaultTimes: TimeRange[] = [
  { start: "09:00", end: "10:00" },
  { start: "10:00", end: "11:00" },
  { start: "11:00", end: "12:00" },
  { start: "12:00", end: "13:00" },
  { start: "13:00", end: "14:00" },
  { start: "14:00", end: "15:00" },
  { start: "15:00", end: "16:00" },
  { start: "16:00", end: "17:00" },
  { start: "17:00", end: "18:00" },
];

export default function DayPickerDropdown({
  disabled = false,
  hasError = false,
  preSelectedItem,
  size,
  id,
  label,
  onChange,
  placeholder,
  required = false,
  showOutsideDays = false,
  fixedWeeks = false,
  labelInfoIcon,
  removeLabel = "Remove",
  cancelLabel = "Cancel",
  confirmLabel = "Confirm date",
  enabledAfterDays,
  includeConfirmCancel = true,
  times = defaultTimes,
  preSelectedTime,
  fullWidthButton,
  onTimeChange,
  setIsDayPickerOpen,
  variant = "default",
}: Props) {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Date | undefined>(
    preSelectedItem || undefined,
  );
  const [selectedTemp, setSelectedTemp] = useState<Date | undefined>(undefined);
  const [selectedTime, setSelectedTime] = useState<TimeRange | undefined>(
    preSelectedTime,
  );

  const today = new Date();

  const disabledDays = enabledAfterDays !== undefined && [
    { from: new Date(1970, 1, 1), to: add(today, { days: enabledAfterDays }) },
  ];

  const showTimeSection = variant === "date-time";

  const defaultMonth = () => {
    const month = today.getMonth();
    const year = today.getFullYear();

    return new Date(year, month);
  };

  useEffect(() => {
    if (preSelectedItem) {
      onChange(preSelectedItem);
    }
  }, [onChange, preSelectedItem]);

  useEffect(() => {
    if (preSelectedTime) {
      setSelectedTime(preSelectedTime);
    }
  }, [preSelectedTime]);

  useEffect(() => {
    if (selectedTime) {
      onTimeChange?.(selectedTime);
    }
  }, [selectedTime, onTimeChange]);

  useEffect(() => {
    onChange(selected);
  }, [onChange, selected]);

  useEffect(() => {
    setIsDayPickerOpen?.(isOpen);
  }, [isOpen, setIsDayPickerOpen]);

  useEffect(() => {
    if (!isOpen && selectedTemp) {
      setSelectedTemp(undefined);
    }
  }, [isOpen, selectedTemp]);

  useClickOutside(wrapperRef, () => {
    if (selectedTemp && !includeConfirmCancel) {
      setSelected(selectedTemp);
    }
    setIsOpen(false);
  });

  const handleClickButtonCancel = () => {
    setIsOpen(false);
  };

  const handleClickButtonConfirm = () => {
    if (selectedTemp) {
      setSelected(selectedTemp);
    }
    setIsOpen(false);
  };

  const handleClickButtonRemove = () => {
    setSelected(undefined);
    setSelectedTime(undefined);
  };

  const formatDate = (date?: Date) => {
    const formattedDate = date
      ? date.toLocaleDateString("en-US", {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        })
      : "";

    if (formattedDate && selectedTime) {
      return `${formattedDate}, ${selectedTime.start} - ${selectedTime.end}`;
    }

    if (selectedTime) {
      return `${selectedTime.start} - ${selectedTime.end}`;
    }

    return formattedDate;
  };

  const timePicker = (
    <div className={sharedStyles.timeSelectionWrapper}>
      <Text weight="bold">
        <span className="h-9 block">
          {selected ? format(selected, "EEEE, d MMMM yyyy") : ""}
        </span>
      </Text>
      <div className={sharedStyles.timeSelectionButtonWrapper}>
        {times?.map((time) => (
          <div
            key={time.start}
            className={cx(sharedStyles.timeSelectionButton, {
              "bg-purple-50": selectedTime?.start === time.start,
            })}
            onClick={(e) => {
              setSelectedTime(
                selectedTime?.start === time.start ? undefined : time,
              );
              e.stopPropagation();
            }}
          >
            {time.start} - {time.end}
          </div>
        ))}
      </div>
    </div>
  );

  const dayPickerFooter = (
    <div>
      {showTimeSection && <div className="md:hidden my-4">{timePicker}</div>}
      <div className={sharedStyles.dayPickerFooterWrapper}>
        {includeConfirmCancel ? (
          <>
            <div className="flex w-1/3">
              <Button variant="secondary" onClick={handleClickButtonCancel}>
                {cancelLabel}
              </Button>
            </div>
            <div className="flex w-2/3">
              <Button
                variant="primary"
                disabled={!selectedTemp}
                onClick={handleClickButtonConfirm}
              >
                {confirmLabel}
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );

  function CustomCaption(props: CaptionProps) {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    return (
      <div className={sharedStyles.customCaptionWrapper}>
        <div
          className={cx("flex items-center", {
            "justify-between w-full": !showTimeSection,
          })}
        >
          <div className={sharedStyles.customCaptionDate}>
            {format(props.displayMonth, "MMM yyy")}
          </div>
          <div className={sharedStyles.customCaptionNavigationWrapper}>
            <Button
              size="xs"
              justify="center"
              variant="icon"
              iconLeft={<ChevronIcon direction="left" size={20} />}
              disabled={!previousMonth}
              onClick={() => previousMonth && goToMonth(previousMonth)}
            />
            <Button
              size="xs"
              justify="center"
              variant="icon"
              iconLeft={<ChevronIcon direction="right" size={20} />}
              disabled={!nextMonth}
              onClick={() => nextMonth && goToMonth(nextMonth)}
            />
          </div>
        </div>
        {showTimeSection && (
          <div className="w-[18rem] hidden md:block">{timePicker}</div>
        )}
      </div>
    );
  }

  return (
    <div ref={wrapperRef} className="flex-col">
      <div className="flex w-full">
        {label && (
          <label
            className={cx(s.label, {
              [s.labelDisabled]: disabled,
            })}
            htmlFor={id}
          >
            {label}
            {required && <span className={s.labelRequired}>*</span>}
            {labelInfoIcon ?? null}
          </label>
        )}
        <div
          className={cx(s.datePickerDateButton, {
            "!border-neutral-600": isOpen,
            "!border-neutral-300": !isOpen,
            "w-full": fullWidthButton,
          })}
        >
          <Button
            variant="secondary"
            justify="start"
            width="full"
            className={cx(sharedStyles.dateDropdownButton, {
              "!px-3 !py-1": size === "small",
            })}
            iconRight={
              <ChevronIcon
                direction="down"
                className={cx(sharedStyles.dateDropdownButtonIcon, {
                  "-rotate-180 transform transition-all": isOpen,
                  "rotate-0 transform transition-all": !isOpen,
                })}
              />
            }
            iconLeft={
              selected || selectedTime ? (
                <CalendarIcon className=" text-neutral-900" />
              ) : (
                <CalendarIcon variant="add" className=" text-neutral-400" />
              )
            }
            onClick={() => setIsOpen(!isOpen)}
          >
            {selected || selectedTime ? (
              <div className={sharedStyles.dateDropdownButtonDate}>
                {formatDate(selected)}
              </div>
            ) : (
              <div className="!text-neutral-600 ">{placeholder}</div>
            )}
          </Button>
          <span
            className={cx(s.icon, "left-4", {
              [s.iconError]: hasError,
              [s.iconDisabled]: disabled,
            })}
          ></span>
        </div>
        {(selected || selectedTime) && (
          <div className={sharedStyles.removeLabel}>
            <Button variant="link" onClick={handleClickButtonRemove}>
              {removeLabel}
            </Button>
          </div>
        )}
      </div>
      <div className="relative z-40">
        <Transition show={isOpen} {...s.transitionProps}>
          <div className={cx(s.DayPickerMenu)}>
            <DayPicker
              components={{
                Caption: CustomCaption,
              }}
              disabled={disabledDays}
              defaultMonth={defaultMonth()}
              fromMonth={defaultMonth()}
              mode="single"
              selected={selected}
              onSelect={setSelectedTemp}
              footer={dayPickerFooter}
              showOutsideDays={showOutsideDays}
              fixedWeeks={fixedWeeks}
              formatters={{
                formatWeekdayName: (nameOfDay) => format(nameOfDay, "eee"),
              }}
            />
          </div>
        </Transition>
      </div>
    </div>
  );
}
