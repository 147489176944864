import { useRouter } from "next/router";
import { ReactNode } from "react";
import { IntercomProvider as Provider } from "react-use-intercom";
import { INTERCOM_APP_ID } from "@olivahealth/constants";
import { getUserFullName } from "@olivahealth/utils/helpers/user";
import { useAmplitude } from "./AmplitudeContext";
import { useUserData } from "./UserDataContext";

interface IProps {
  children: ReactNode;
}

export default function IntercomProvider({ children }: IProps) {
  const props = useIntercomBootProps();
  const { trackEvent } = useAmplitude();
  const { pathname } = useRouter();

  const onShow = () => {
    trackEvent("Support widget navigated", {
      pageUrl: pathname,
    });
  };

  return (
    <Provider
      appId={INTERCOM_APP_ID}
      autoBoot
      shouldInitialize
      autoBootProps={props}
      onShow={onShow}
    >
      {children}
    </Provider>
  );
}

export function useIntercomBootProps() {
  const { data } = useUserData();

  if (!data) {
    return {};
  }

  const userData = {
    email: data.email,
    userId: data.id,
    userHash: data.verificationHash,
    name: getUserFullName(data),
    createdAt: data.createdAt,
    customAttributes: {
      roles: (data.role ?? []).toString(),
      languages: (data.languages ?? []).toString(),
    },
  };

  const companyData = data.organisation
    ? {
        companyId: data.organisation.id,
        name: data.organisation.name,
        plan: data.organisation.plan?.name ?? "",
      }
    : null;

  return {
    ...userData,
    ...(companyData ? { company: companyData } : {}),
  };
}
