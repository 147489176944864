import ctl from "@netlify/classnames-template-literals";

export const wrapper = ctl(`
  flex
  h-[100vh]
  w-full
  rounded-lg
  border
  border-transparent
  p-6
  hover:border-neutral-200
`);
