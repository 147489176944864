import React, { ReactNode } from "react";
import { Tab as HuiTab } from "@headlessui/react";
import cx from "classnames";

import Text from "../Text";
import Tag from "../Tag";
import * as s from "./styles";

interface ITabProps {
  headerText: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  fullWidth?: boolean;
  subHeader?: string;
  tagText?: string;
  tabTitleTestId?: string;
}

export function Tab({
  headerText,
  iconLeft,
  iconRight,
  fullWidth,
  subHeader,
  tagText,
  tabTitleTestId,
}: ITabProps) {
  return (
    <HuiTab
      className={cx(s.tabWrapper, {
        "w-full": fullWidth,
      })}
      data-testid={tabTitleTestId}
    >
      {({ selected }) => (
        <div
          className={cx(s.tabBase, {
            [s.tabActive]: selected,
            [s.tabInactive]: !selected,
            "pl-4": fullWidth,
          })}
        >
          <div className={s.headerWrapper}>
            {iconLeft && (
              <div className={cx(s.iconTagWrapper, "lg:mr-2")}>
                {iconLeft}
                {tagText && (
                  <span className={s.xsTagWrapper}>
                    <Tag variant="info">{tagText}</Tag>
                  </span>
                )}
              </div>
            )}
            <Text variant="h4">{headerText}</Text>
            {iconRight && (
              <div className={cx(s.iconTagWrapper, "lg:ml-2")}>
                {iconRight}
                {tagText && (
                  <span className={s.xsTagWrapper}>
                    <Tag variant="info">{tagText}</Tag>
                  </span>
                )}
              </div>
            )}
            {tagText && (
              <span className={s.lgTagWrapper}>
                <Tag variant="info">{tagText}</Tag>
              </span>
            )}
          </div>
          {subHeader && (
            <div className="mt-3">
              <Text size="sm" color="secondary">
                {subHeader}
              </Text>
            </div>
          )}
        </div>
      )}
    </HuiTab>
  );
}
