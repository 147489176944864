import { useCallback, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import TextareaAutosize from "react-textarea-autosize";
import cx from "classnames";

import { getThrottledOnClick } from "@olivahealth/utils/helpers/core/element";
import useDebounce from "@olivahealth/utils/reactHooks/useDebounce";
import MentalHealthTopics from "@olivahealth/graphql-server/src/domain/value-objects/MentalHealthTopics";
import type { Question } from "../../types/Question";
import Text from "../../atoms/Text";
import * as s from "../../shared/surveyStyles";
import { DEBOUNCE_TEXT_TIME } from "../TextQuestion";

export type RadioQuestionVariant = "primary" | "secondary";

export type ExtendedTopics = MentalHealthTopics | "NONE_OF_THE_ABOVE";

interface Props {
  isDisabled?: boolean;
  name?: string;
  onChange?: (value: string) => void;
  question: Question;
  selected?: string | ExtendedTopics[] | undefined;
  selectedSubValue?: string | null;
  handleSubValueChange?: (optionValue: string, subValue: string) => void;
  subValuePlaceholder?: string;
  variant?: RadioQuestionVariant;
  renderInRow?: boolean;
}

export default function RadioQuestion({
  isDisabled,
  name,
  onChange,
  question,
  selected,
  handleSubValueChange,
  selectedSubValue,
  subValuePlaceholder,
  variant = "primary",
  renderInRow = false,
}: Readonly<Props>): JSX.Element | null {
  const [selectedItem, setSelectedItem] = useState<
    string | ExtendedTopics[] | undefined
  >(selected);

  const handleOnChange = useCallback(
    (value: string) => {
      setSelectedItem(value);
      onChange?.(value);
    },
    [onChange],
  );

  useEffect(() => {
    setSelectedItem(selected);
  }, [selected]);

  const [subValueText, setSubValueText] = useState<string | undefined>(
    selectedSubValue || undefined,
  );
  const debouncedSubValueText = useDebounce(subValueText, DEBOUNCE_TEXT_TIME);

  useEffect(() => {
    if (debouncedSubValueText === undefined || !handleSubValueChange) {
      return;
    }

    if (typeof debouncedSubValueText !== "undefined" && selected) {
      handleSubValueChange?.(selected.toString(), debouncedSubValueText);
    }
  }, [debouncedSubValueText]);

  if (!question.options?.length) {
    return null;
  }

  return (
    <>
      {question.question && (
        <Text variant="h3" component="h1" color="accent-primary" gutter="md">
          <span
            dangerouslySetInnerHTML={{
              __html: question.question,
            }}
          />
        </Text>
      )}
      <RadioGroup
        className={renderInRow ? s.optionGroupRow : s.optionGroup}
        disabled={isDisabled}
        name={name}
        value={selectedItem}
      >
        {question.options.map((option) => {
          const onClick = () => handleOnChange(option.value);
          const throttledOnClick = getThrottledOnClick(onClick);
          const hasSubValue = (option.subValue?.length ?? -1) >= 0;
          const isSelected = selected == option.value;

          return (
            <RadioGroup.Option
              key={option.id}
              as="button"
              value={option.value}
              className={({ checked }) => {
                const isRow = renderInRow ? "flex-1" : "";

                if (variant === "secondary") {
                  return `${s.optionSecondary} ${checked && s.optionSecondarySelected} ${isRow}`;
                }

                return `${s.option} ${checked && s.optionSelected} ${isRow}`;
              }}
              onClick={throttledOnClick}
            >
              <div
                className={cx(s.optionsContainer, {
                  "flex-col items-baseline": hasSubValue,
                })}
              >
                <div className="flex items-center">
                  {option.icon && <span className="mr-3">{option.icon}</span>}
                  <div className="flex flex-col">
                    <RadioGroup.Label className={s.optionLabel}>
                      {option.label}
                    </RadioGroup.Label>
                    {option.sublabel && (
                      <RadioGroup.Description
                        className={cx("text-sm text-gray-500 text-left", {
                          ["hidden"]: hasSubValue && !isSelected,
                          "block mb-2 mt-3": hasSubValue && isSelected,
                        })}
                      >
                        {option.sublabel}
                      </RadioGroup.Description>
                    )}
                  </div>
                </div>
                {hasSubValue && isSelected ? (
                  <TextareaAutosize
                    autoFocus
                    value={subValueText}
                    name={question.id}
                    id={question.id}
                    minRows={1}
                    maxRows={1}
                    placeholder={subValuePlaceholder}
                    onChange={(event) => {
                      setSubValueText(event.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === " ") {
                        setSubValueText((text) => `${text} `);
                      }
                    }}
                    disabled={isDisabled}
                    className={s.textarea}
                  />
                ) : null}
              </div>
            </RadioGroup.Option>
          );
        })}
      </RadioGroup>
    </>
  );
}
