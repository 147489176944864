import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function VideoIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <rect
        x="22"
        y="3.375"
        width="17.25"
        height="20"
        rx="7"
        transform="rotate(90 22 3.375)"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M10.8941 9.02295L14.354 11.3537C14.7987 11.6532 14.7987 12.3468 14.354 12.6463L10.8941 14.977C10.864 14.9973 10.8448 14.9997 10.8334 15C10.8183 15.0003 10.7967 14.9965 10.7724 14.983C10.7481 14.9694 10.7272 14.9494 10.7127 14.9255C10.6999 14.9042 10.6875 14.8725 10.6875 14.8226L10.6875 9.17737C10.6875 9.1275 10.6999 9.09579 10.7127 9.0745C10.7272 9.05059 10.7481 9.03056 10.7724 9.01702C10.7967 9.0035 10.8183 8.99967 10.8334 9.00002C10.8448 9.00029 10.864 9.0027 10.8941 9.02295Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
