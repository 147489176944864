import DOMPurify from "dompurify";

export function capitaliseFirstLetter(string: string | null): string | null {
  if (!string || string.trim() === "") {
    return null;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatListToHumanReadable(list: Array<string>): string {
  return list.join(", ").replace(/, ([^,]*)$/, " and $1"); // Magical regex to replace the last comma by an "and"
}

export function htmlSanitizer(obj: Record<string, any>) {
  const cleanedObj: Record<string, any> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === "string") {
        cleanedObj[key] = DOMPurify.sanitize(obj[key], {
          USE_PROFILES: { html: false },
        });
      } else {
        cleanedObj[key] = obj[key];
      }
    }
  }
  return cleanedObj;
}
