import { ReactNode } from "react";
import {
  toast as reactToast,
  ToastContainer as ReactToastContainer,
} from "react-toastify";

import tokens from "../../../theme/tokens";
import {
  CheckCircleIcon,
  CrossCircleIcon,
  CrossIcon,
  ExclamationIcon,
} from "../../atoms/Icons";
import * as s from "./styles";

// import general react-toastify styles
import "react-toastify/dist/ReactToastify.css";

export type ToastVariant = "default" | "error" | "info" | "success" | "warning";
export interface Props {
  body: string | ReactNode;
  variant?: ToastVariant;
}

const toastOptions = {
  hideProgressBar: true,
  pauseOnHover: true,
  closeOnClick: true,
  position: reactToast.POSITION.TOP_RIGHT,
  autoClose: 3000,
};

export default function toast({
  body,
  variant = "default",
}: Props): number | string {
  const variantIconColor = {
    default: null,
    error: tokens.colors["status-red"][500],
    info: tokens.colors["status-teal"][500],
    success: tokens.colors["status-green"][500],
    warning: tokens.colors["status-yellow"][500],
  };

  switch (variant) {
    case "default":
    default:
      return reactToast(body, toastOptions);
    case "error":
      return reactToast.error(body, {
        ...toastOptions,
        autoClose: 5000,
        icon: () => <CrossCircleIcon color={variantIconColor.error} />,
      });
    case "info":
      return reactToast.info(body, {
        ...toastOptions,
        icon: () => (
          <ExclamationIcon color={variantIconColor.info} variant="square" />
        ),
      });
    case "success":
      return reactToast.success(body, {
        ...toastOptions,
        icon: () => <CheckCircleIcon color={variantIconColor.success} />,
      });
    case "warning":
      return reactToast.warning(body, {
        ...toastOptions,
        icon: () => (
          <ExclamationIcon
            color={variantIconColor.warning}
            variant="triangle"
          />
        ),
      });
  }
}

export function toastClearWaitingQueue() {
  reactToast.clearWaitingQueue();
}

export function ToastContainer({ limit }: { limit?: number }) {
  return (
    <ReactToastContainer
      {...(limit ? { limit } : {})}
      closeButton={CloseButton}
      bodyClassName={(props) => s.container(props?.type ?? "default").body}
      toastClassName={(props) => s.container(props?.type ?? "default").toast}
    />
  );
}

export function CloseButton() {
  return (
    <span className={s.closeButton}>
      <CrossIcon color={tokens.colors["neutral"][700]} size={10} />
    </span>
  );
}
