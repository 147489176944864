import { useState, useEffect } from "react";

import Checkbox from "../Checkbox";

import * as s from "./styles";

interface Props {
  label: string;
  handlePillClick?: () => void;
  isChecked?: boolean;
  variant?: "default" | "full-width";
}

export default function Pill({
  label,
  handlePillClick,
  isChecked = false,
  variant = "default",
}: Props) {
  const [checked, setChecked] = useState<boolean>(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const togglePillChecked = () => {
    setChecked(!checked);
    handlePillClick?.();
  };

  const isFullWidth = variant === "full-width";

  return (
    <button
      className={s.getPillContainerStyles(checked, isFullWidth)}
      onClick={togglePillChecked}
    >
      <Checkbox
        name="pill"
        label={label}
        value="pill"
        checked={checked}
        onChange={togglePillChecked}
        changeValueOnLabelClick={false}
      />
    </button>
  );
}
