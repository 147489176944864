import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function LineChartIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M21.8421 20.5L12 20.5L8.15789 20.5C4.84418 20.5 2.15789 17.8137 2.15789 14.5L2.15789 3.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.39471 19.1579L7.36543 13.2165C8.39032 11.1667 11.2861 11.0863 12.4232 13.0761C13.5698 15.0828 16.4971 14.9796 17.4997 12.8973L20.9473 5.73685"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
