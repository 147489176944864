import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
  getValue,
} from "@firebase/remote-config";
import { FeatureFlags } from "@olivahealth/utils/types/FeatureFlags";
import { FEATURE_FLAGS_DEFAULT_VALUES } from "@olivahealth/constants";

interface FlagsContext {
  [key: string]: string | number | boolean;
}

/**
 * the `defaults` prop is used to pass some default values to the featureFlags
 * it's also useful to add a new local flag before creating it in Remote Config
 */
interface Props {
  children: ReactNode;
  defaults: FeatureFlags;
}

// defines the default value of each feature flag

const FeatureFlagsContext = createContext(FEATURE_FLAGS_DEFAULT_VALUES);

/**
 * Custom hook to access the feature flags
 * @returns flags: FeatureFlagsDefaults - returns an object of all flags available
 */
export const useFlags = (): FeatureFlags => useContext(FeatureFlagsContext);

export default function FeatureFlagsProvider({
  children,
  defaults,
}: Props): JSX.Element {
  const [flags, setFlags] = useState<FeatureFlags>(defaults);

  useEffect(() => {
    const remoteConfig = getRemoteConfig();

    /**
     * defines the maximum age of an entry in the config cache before it is considered stale. defaults to 10s
     */
    remoteConfig.settings.minimumFetchIntervalMillis = 6000;

    // pass the default values as the default config
    remoteConfig.defaultConfig = defaults as unknown as FlagsContext;

    fetchAndActivate(remoteConfig)
      .then(() => {
        return getAll(remoteConfig);
      })
      .then(() => {
        // get the `flags` key from the remote config as a string and parse its value
        const remoteValues = JSON.parse(
          getValue(remoteConfig, "flags").asString(),
        );
        // create a new flags object by combining the local flags and the remote values
        const newFlags = {
          ...flags,
          ...remoteValues,
        };

        setFlags(newFlags);
      })
      .catch((e) => console.error("Remote config failed to load", e));
  }, []);

  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}
