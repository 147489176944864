import ctl from "@netlify/classnames-template-literals";

export const wrapper = ctl(`
  fixed
  bottom-0
  left-0
  right-0
  w-full
`);

export const wrapperInline = ctl(`
  relative
  min-w-full
`);

export const closeButton = ctl(`
  absolute
  top-0
  right-0
  cursor-pointer
  p-1
  text-white
`);

export const playPauseButton = ctl(`
  flex
  h-12
  w-12
  items-center
  justify-center
  rounded-full
  bg-white
`);

export const header = ctl(`
  mr-16
  flex
  w-96
`);

export const headerSubtitle = ctl(`
  mb-1
  text-sm
  font-bold
  text-purple-200
`);

export const headerTitle = ctl(`
  flex
  flex-wrap
  text-xl
  font-bold
  text-white
`);
