import cx from "classnames";
import Link from "next/link";
import Text from "../../atoms/Text";
import * as s from "./styles";
import type { NavbarLink } from "./index";

export default function NavLink({
  activeRegex,
  href,
  icon,
  isExternal,
  label,
  id,
  rightSideIcon,
  color,
}: NavbarLink): JSX.Element | null {
  const regex = activeRegex ? new RegExp(activeRegex) : false;
  const isActive =
    (!isExternal && href && regex && regex.test(window.location.pathname)) ??
    false;

  if (!href) {
    return null;
  }

  if (isExternal) {
    return (
      <a
        href={href}
        className={cx(s.item, {
          [s.itemSelected]: isActive,
        })}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={id}
        // data-intercom-target={id + "-intercom"}
      >
        {icon && <span>{icon}</span>}
        <Text weight="bold">{label}</Text>
      </a>
    );
  }

  return (
    <Link
      href={href}
      className={cx(s.item, {
        [s.itemSelected]: isActive,
      })}
      data-testid={id}
      // data-intercom-target={id + "-intercom"}
    >
      {icon && <span>{icon}</span>}
      <Text color={color} weight="bold">
        {label}
      </Text>
      {rightSideIcon && <span className="ml-auto">{rightSideIcon}</span>}
    </Link>
  );
}
