import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  variant?: "add" | "alt" | "block" | "check" | "cross" | "default";
}

export function CalendarIcon({
  className,
  color = "currentColor",
  size,
  variant = "default",
}: Props): ReactElement {
  const calendar = {
    add: (
      <g>
        <path
          d="M15 4C18.866 4 22 7.13401 22 11V14C22 17.866 18.866 21 15 21H9C5.13401 21 2 17.866 2 14L2 11C2 7.13401 5.13401 4 9 4L15 4Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M12 9L12 16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M15.5 12.5L8.5 12.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M7 2L7 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M17 2L17 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    alt: (
      <g>
        <path
          d="M15 3.99997C18.866 3.99997 22 7.13398 22 11V14C22 17.866 18.866 21 15 21H9C5.13401 21 2 17.866 2 14L2 11C2 7.13398 5.13401 3.99997 9 3.99997L15 3.99997Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M7 2L7 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M17 2L17 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M8 11H16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M8 15H16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    block: (
      <g>
        <path
          d="M15 4C18.866 4 22 7.13401 22 11V14C22 17.866 18.866 21 15 21H9C5.13401 21 2 17.866 2 14L2 11C2 7.13401 5.13401 4 9 4L15 4Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M7 2L7 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M17 2L17 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle cx="12" cy="12.6477" r="4" stroke={color} strokeWidth="2" />
        <line
          x1="8.24988"
          y1="15.0073"
          x2="15.3355"
          y2="10.9164"
          stroke={color}
          strokeWidth="2"
        />
      </g>
    ),
    check: (
      <g>
        <path
          d="M15 4C18.866 4 22 7.13401 22 11V14C22 17.866 18.866 21 15 21H9C5.13401 21 2 17.866 2 14L2 11C2 7.13401 5.13401 4 9 4L15 4Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M9 12.5577L10.4634 14.4678C10.8628 14.989 11.6476 14.9905 12.0488 14.4707L15.5 10"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M7 2L7 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M17 2L17 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    cross: (
      <g>
        <path
          d="M15 4C18.866 4 22 7.13401 22 11V14C22 17.866 18.866 21 15 21H9C5.13401 21 2 17.866 2 14L2 11C2 7.13401 5.13401 4 9 4L15 4Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M14.4749 10.0251L9.52511 14.9749"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M14.4749 14.9749L9.52511 10.0251"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M7 2L7 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M17 2L17 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    default: (
      <g>
        <path
          d="M15 4C18.866 4 22 7.13401 22 11V14C22 17.866 18.866 21 15 21H9C5.13401 21 2 17.866 2 14L2 11C2 7.13401 5.13401 4 9 4L15 4Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M8 2L8 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M16 2L16 7"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M6.5655 11.6926C5.97971 11.1068 5.97971 10.1571 6.5655 9.5713C7.15129 8.98551 8.10103 8.98551 8.68682 9.5713C9.27261 10.1571 9.27261 11.1068 8.68682 11.6926C8.10103 12.2784 7.15129 12.2784 6.5655 11.6926Z"
          fill={color}
        />
        <path
          d="M6.5655 16.7852C5.97971 16.1994 5.97971 15.2497 6.5655 14.6639C7.15129 14.0781 8.10103 14.0781 8.68682 14.6639C9.27261 15.2497 9.27261 16.1994 8.68682 16.7852C8.10103 17.371 7.15129 17.371 6.5655 16.7852Z"
          fill={color}
        />
        <path
          d="M10.9058 11.6926C10.32 11.1068 10.32 10.1571 10.9058 9.5713C11.4916 8.98551 12.4413 8.98551 13.0271 9.5713C13.6129 10.1571 13.6129 11.1068 13.0271 11.6926C12.4413 12.2784 11.4916 12.2784 10.9058 11.6926Z"
          fill={color}
        />
        <path
          d="M10.9058 16.7852C10.32 16.1994 10.32 15.2497 10.9058 14.6639C11.4916 14.0781 12.4413 14.0781 13.0271 14.6639C13.6129 15.2497 13.6129 16.1994 13.0271 16.7852C12.4413 17.371 11.4916 17.371 10.9058 16.7852Z"
          fill={color}
        />
        <path
          d="M15.5354 11.6926C14.9496 11.1068 14.9496 10.1571 15.5354 9.5713C16.1212 8.98551 17.0709 8.98551 17.6567 9.5713C18.2425 10.1571 18.2425 11.1068 17.6567 11.6926C17.0709 12.2784 16.1212 12.2784 15.5354 11.6926Z"
          fill={color}
        />
        <path
          d="M15.5354 16.7852C14.9496 16.1994 14.9496 15.2497 15.5354 14.6639C16.1212 14.0781 17.0709 14.0781 17.6567 14.6639C18.2425 15.2497 18.2425 16.1994 17.6567 16.7852C17.0709 17.371 16.1212 17.371 15.5354 16.7852Z"
          fill={color}
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {calendar[variant]}
    </SvgIcon>
  );
}
