import Head from "next/head";
import { useRouter } from "next/router";
import { APP_STORE_ID } from "@olivahealth/constants";

interface Props {
  title?: string;
  description?: string;
  thumbnailImage?: string | null;
}

const pathToThumbnailImage = {
  // @TODO replace this image by the one provided by design
  resources:
    "https://uploads-ssl.webflow.com/60d2012e9eb657a1ead6a80a/60e31272c59f8774de5e1b26_oliva-open-graph-image.jpg",
  // add more thumbnails here
};

// @see https://nextjs.org/docs/pages/api-reference/components/head#avoid-duplicated-tags
export default function HeadTags({
  description,
  title,
  thumbnailImage,
}: Props) {
  const { pathname } = useRouter();

  const titleText =
    title || "Oliva | Proper Mental Healthcare For The Whole Team";
  const descriptionText =
    description ||
    "Comprehensive mental health support for employees & managers. Give your team access to therapy courses & drop-in sessions from a curated team of professionals.";
  const thumbnailImageSrc =
    thumbnailImage ||
    pathToThumbnailImage[pathname.split("/")[1]] ||
    "https://uploads-ssl.webflow.com/63cd73a53f5ddf4cb3946ce6/65a9015c75aa6339f3cd5321_meta_notaperk.webp";

  return (
    <Head>
      {/* Generic meta tags */}
      <title key="title">{titleText}</title>
      <meta key="description" name="description" content={descriptionText} />

      <link key="icon" rel="icon" href="/favicon.ico" />

      {/* Open Graph meta tags */}
      <meta key="og:title" property="og:title" content={titleText} />
      <meta key="og:site_name" property="og:site_name" content="Oliva" />
      <meta key="og:type" property="og:type" content="website" />
      <meta
        key="og:description"
        property="og:description"
        content={descriptionText}
      />
      <meta key="og:image" property="og:image" content={thumbnailImageSrc} />

      {/* Twitter specific meta tags */}
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />

      {/* iOS/Android smart banners for mobile app downloads */}
      <meta
        key="apple-itunes-app"
        name="apple-itunes-app"
        content={`app-id=${APP_STORE_ID}`}
      />
    </Head>
  );
}
