import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function SquareIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="6"
        stroke={color}
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
