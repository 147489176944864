// Includes the preview to comply with third party CORS, eg Cronofy
export function getRunningBaseUrl(host: string): string {
  if (host === "localhost:3000") {
    return `http://${host}`;
  }
  return `https://${host}`;
}

export default function getBaseUrl(): string {
  const url = process.env.NEXT_PUBLIC_VERCEL_URL ?? "";

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
    return "https://olivahealth.app";
  }

  if (process.env.NEXT_PUBLIC_VERCEL_ENV === "staging") {
    return "https://staging.olivahealth.app";
  }

  if (!url) {
    return "http://localhost:3000";
  }

  return url.match(/https?:\/\/.+?(?=\/|$)/)?.[0] ?? "";
}
