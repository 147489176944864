import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function EmojiIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M20 12.4412C20 17.4118 15.9706 21.4412 11 21.4412C6.02944 21.4412 2 17.4118 2 12.4412C2 7.47063 6.02944 3.44119 11 3.44119C11.6318 3.44119 12.2484 3.50629 12.8434 3.63016"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="8.12132"
        cy="10"
        r="1.5"
        transform="rotate(-45 8.12132 10)"
        fill={color}
      />
      <circle
        cx="14.1213"
        cy="10"
        r="1.5"
        transform="rotate(-45 14.1213 10)"
        fill={color}
      />
      <path
        d="M15.3894 14.4412C15.1554 14.6749 14.9028 14.89 14.6341 15.0842C13.6123 15.8225 12.3569 16.2576 11 16.2576C9.28616 16.2576 7.73444 15.5635 6.61055 14.4412"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18.0588 3L18.0588 10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.5588 6.5L14.5588 6.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
