import { Menu, Transition } from "@headlessui/react";
import { ChevronIcon, HomeIcon } from "../../atoms/Icons";
import Text from "../../atoms/Text";
import Divider from "../../atoms/Divider";
import NavLink from "./NavLink";
import * as s from "./styles";
import { transitionProps } from "./utils";
import type { NavbarProps, NavbarLink } from "./index";

type NavbarMobileProps = Pick<NavbarProps, "isOrganisationAdmin" | "labels"> & {
  mobileLinks: NavbarLink[];
  organisationAdminLinks: NavbarLink[];
};

export default function NavbarMobile({
  isOrganisationAdmin,
  labels,
  mobileLinks,
  organisationAdminLinks,
}: NavbarMobileProps) {
  return (
    <nav>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className={s.dropdownButton}>
              <HomeIcon />
              <ChevronIcon direction={open ? "up" : "down"} />
            </Menu.Button>
            <Transition {...transitionProps} className="z-100 relative">
              <Menu.Items className={s.dropdownItems}>
                {isOrganisationAdmin && (
                  <div className="ml-4">
                    <Text variant="label" color="secondary">
                      {labels.careJourney}
                    </Text>
                  </div>
                )}

                {mobileLinks.map((link) => (
                  <Menu.Item key={`${link.href}-${link.label}`}>
                    <NavLink {...link} />
                  </Menu.Item>
                ))}

                {isOrganisationAdmin && organisationAdminLinks.length > 0 && (
                  <>
                    <Divider />
                    <div className="ml-4">
                      <Text variant="label" color="secondary">
                        {labels.company}
                      </Text>
                    </div>

                    {organisationAdminLinks.map((link) => (
                      <Menu.Item key={`${link.href}-${link.label}`}>
                        <NavLink {...link} />
                      </Menu.Item>
                    ))}
                  </>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </nav>
  );
}
