import { addDays } from "date-fns";
import {
  SURVEY_PROFESSIONAL_NPS_DISMISSED_PERIOD,
  SURVEY_PROFESSIONAL_NPS_DISMISSED_STORAGE_KEY,
  SURVEY_EMPLOYEE_NPS_DISMISSED_PERIOD,
  SURVEY_EMPLOYEE_NPS_DISMISSED_STORAGE_KEY,
} from "@olivahealth/constants";

type NPSType = "employee" | "professional";

const getLocalStorageKey = (type: NPSType): string => {
  if (type === "employee") {
    return SURVEY_EMPLOYEE_NPS_DISMISSED_STORAGE_KEY;
  }

  return SURVEY_PROFESSIONAL_NPS_DISMISSED_STORAGE_KEY;
};

const getDismissPeriod = (type: NPSType): number => {
  if (type === "employee") {
    return SURVEY_EMPLOYEE_NPS_DISMISSED_PERIOD;
  }

  return SURVEY_PROFESSIONAL_NPS_DISMISSED_PERIOD;
};

export function setLocalStorageDismissedAt(type: NPSType) {
  const key = getLocalStorageKey(type);
  const now = new Date().toISOString();
  localStorage.setItem(key, now);
}

export function resetLocalStorageDismissedAt(type: NPSType) {
  const key = getLocalStorageKey(type);
  localStorage.removeItem(key);
}

export function isSurveyNPSDismissed(type: NPSType): boolean {
  const key = getLocalStorageKey(type);
  const dismissedAt = localStorage.getItem(key);

  if (!dismissedAt) {
    return false;
  }

  const dismissPeriod = getDismissPeriod(type);

  return new Date() < addDays(new Date(dismissedAt), dismissPeriod);
}
