import ctl from "@netlify/classnames-template-literals";

export const searchBarWrapper = ctl(`
  relative
  flex
  items-center
  justify-between
  border
  border-neutral-200
  align-middle
  rounded-lg
  bg-neutral-50
  py-2
  pl-3
  pr-10
`);

export const searchInputBar = ctl(`
  bg-transparent
  w-full
  px-2
  py-1
  text-base
  border-none
  outline-none
  placeholder:text-neutral-600
  text-neutral-900
`);

export const searchCloseButton = ctl(`
  absolute
  right-0
  top-0
  mt-3
  mr-3
`);
