import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

type ShieldVariant = "default" | "check" | "one";

interface Props extends IconProps {
  variant?: ShieldVariant;
}

export function ShieldIcon({
  className,
  color = "currentColor",
  size,
  variant = "default",
}: Props): ReactElement {
  const variants = {
    default: (
      <path
        d="M10.2 3L13.8 3C17.205 3 20 5.82312 20 9.34896V14.6575C20 16.2822 19.1493 17.7729 17.7856 18.5732L15.0828 20.1595C13.1733 21.2802 10.8267 21.2802 8.91717 20.1595L6.21442 18.5732C4.85071 17.7729 4 16.2822 4 14.6575L4 9.34896C4 5.82312 6.79504 3 10.2 3Z"
        stroke={color}
        strokeWidth="2"
      />
    ),
    check: (
      <g>
        <path
          d="M10.2 3L13.8 3C17.205 3 20 5.82312 20 9.34896V14.6575C20 16.2822 19.1493 17.7729 17.7856 18.5732L15.0828 20.1595C13.1733 21.2802 10.8267 21.2802 8.91717 20.1595L6.21442 18.5732C4.85071 17.7729 4 16.2822 4 14.6575L4 9.34896C4 5.82312 6.79504 3 10.2 3Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M9 12.0577L10.473 13.9803C10.8694 14.4977 11.6468 14.5037 12.0511 13.9925L16 9"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    one: (
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2071 10.003C11.3152 9.89492 11.5 9.97147 11.5 10.1243V15.2959C11.5 15.8482 11.9477 16.2959 12.5 16.2959C13.0523 16.2959 13.5 15.8482 13.5 15.2959V8.50301C13.5 7.16665 11.8843 6.4974 10.9393 7.44235L9.79289 8.58879C9.40237 8.97932 9.40237 9.61248 9.79289 10.003C10.1834 10.3935 10.8166 10.3935 11.2071 10.003Z"
          fill={color}
        />
        <path
          d="M10.2 3L13.8 3C17.205 3 20 5.82312 20 9.34896V14.6575C20 16.2822 19.1493 17.7729 17.7856 18.5732L15.0828 20.1595C13.1733 21.2802 10.8267 21.2802 8.91717 20.1595L6.21442 18.5732C4.85071 17.7729 4 16.2822 4 14.6575L4 9.34896C4 5.82312 6.79504 3 10.2 3Z"
          stroke={color}
          strokeWidth="2"
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
