import { useCallback } from "react";
// eslint-disable-next-line no-restricted-imports
import { useTranslation as useTranslationBase } from "next-i18next";
import { type UseTranslationOptions } from "react-i18next";
import { htmlSanitizer } from "@olivahealth/utils/stringManipulation";
import Trans from "../rebrand/molecules/Trans";

export interface TFunction {
  (translateKey: string, options?: Record<string, any>): string;
}

export default function useTranslation(
  key?: string | string[],
  options?: UseTranslationOptions<any>,
) {
  const { t, i18n } = useTranslationBase(key, options);

  const translate: TFunction = useCallback(
    (translateKey: string, options: Record<string, any> = {}) => {
      const sanitizedOptions = htmlSanitizer(options);
      const text = t(translateKey, sanitizedOptions);
      if (!text) {
        return translateKey;
      }
      return text as string;
    },
    [t],
  );

  return {
    t: translate,
    Trans,
    translationExists: i18n?.exists,
  };
}
