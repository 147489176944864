import { ReactElement } from "react";
import cx from "classnames";
import Text, { TextColor } from "../Text";
import "./styles.css";

interface Props {
  backgroundColor?: string;
  color?: TextColor;
  count: number;
}

export default function CounterDot({
  backgroundColor = "bg-purple-900",
  color = "white",
  count,
}: Props): ReactElement {
  return (
    <span className="counter-dot__wrapper">
      <span className={cx("counter-dot", backgroundColor)}>
        <Text size="xs" weight="bold" color={color}>
          {count > 9 ? "9+" : count}
        </Text>
      </span>
    </span>
  );
}
