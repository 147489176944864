import cx from "classnames";
import tokens from "../../../theme/tokens";

export interface Props {
  label?: string;
  className?: string;
  percent: number;
}

export default function ProgressCircular({ label, percent, className }: Props) {
  const radius = 40;
  // const percentageRemaining = (remainingSessions / totalSessions) * 100;
  const circumference = 2 * Math.PI * radius;
  // proportion of full circumference which should not be filled in as progress
  const offset = circumference * (1 - percent / 100);

  return (
    <div className={cx("flex justify-center items-center", className)}>
      <svg viewBox="0 0 100 100">
        {/* Background circle */}
        <circle
          className="text-blue-100"
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
          strokeWidth="10"
          stroke={tokens.colors["status-teal"][100]}
        />
        {/* Progress circle */}
        <circle
          className="text-blue-500"
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
          strokeWidth="10"
          stroke={tokens.colors["status-teal"][300]}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        {/* Text inside the circle */}
        <text
          className="text-center text-2xl font-semibold"
          x="50"
          y="50"
          dy=".3em"
          fill={tokens.colors["white"]}
          textAnchor="middle"
        >
          {label ? label : `${percent}%`}
        </text>
      </svg>
    </div>
  );
}
