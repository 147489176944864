import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function UserPlaceholderIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
      <circle cx="12" cy="8.625" r="2.625" fill={color} />
      <path
        d="M6 18.0027C7.19765 15.761 9.43616 14.2527 12 14.2527C14.5638 14.2527 16.8023 15.761 18 18.0027"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
