import ctl from "@netlify/classnames-template-literals";
import type { Props } from ".";

const baseCard = ctl(`
  flex
  flex-col
  justify-between
  gap-16
  rounded-xl
  p-8
  border
  lg:flex-row
`);

export const card = (theme: Props["theme"]) =>
  ctl(`
    ${baseCard}
    ${theme === "dark" && "bg-gradient-to-l from-neutral-900 to-purple-900 text-white"}
    ${theme === "light" && "border-neutral-300 bg-white text-neutral-900"}
    ${theme === "grey" && "border-neutral-100 bg-neutral-100"}
`);

export const content = ctl(`
  flex
  w-full
  flex-col
  justify-between
`);

export const imageWrapper = ctl(`
  flex
  h-auto
  max-h-[350px]
  w-auto
  max-w-[350px]
  items-center
  justify-center
`);
