import ctl from "@netlify/classnames-template-literals";
import cx from "classnames";
import React, { MouseEvent, ReactNode } from "react";
import { getThrottledOnClick } from "@olivahealth/utils/helpers/core/element";

type ButtonJustify =
  | "around"
  | "between"
  | "center"
  | "end"
  | "evenly"
  | "normal"
  | "start"
  | "stretch";
type ButtonSize = "xs" | "sm" | "md" | "lg";
type ButtonType = "button" | "submit" | "reset";

export type ButtonVariant =
  | "base"
  | "primary"
  | "primaryDark"
  | "primaryInverse"
  | "secondary"
  | "secondaryInverse"
  | "tertiary"
  | "tertiaryInverse"
  | "quaternary"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "danger"
  | "transparent"
  | "icon"
  | "link"
  | "linkPrimary"
  | "green";
type ButtonWidth = "min" | "full" | "auto";

interface Props {
  children?: ReactNode;
  disabled?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  justify?: ButtonJustify;
  onClick?: (e: MouseEvent<HTMLButtonElement> | undefined) => void;
  size?: ButtonSize;
  type?: ButtonType;
  variant?: ButtonVariant;
  width?: ButtonWidth;
}

export default function Button({
  children,
  iconLeft,
  iconRight,
  justify = "center",
  onClick,
  size = "md",
  type = "button",
  variant = "primary",
  width = "auto",
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  const className = getClasses({ justify, size, variant, width });
  const isIconButton = variant === "icon";

  const handleClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    /**
     * We should not prevent default behavior for submit and reset buttons
     * because it will prevent the form from submitting or resetting.
     */
    if (type === "button") {
      e.preventDefault();
    }

    onClick?.(e);
  };

  const onClickButtonThrottled = getThrottledOnClick(handleClickButton);

  return (
    <button
      {...props}
      className={className}
      onClick={onClickButtonThrottled}
      type={type}
      tabIndex={0}
    >
      {iconLeft && (
        <span className={cx({ "mr-2": !isIconButton })}>{iconLeft}</span>
      )}
      {children}
      {iconRight && (
        <span
          className={cx({
            "ml-2 flex self-end": !isIconButton,
          })}
        >
          {iconRight}
        </span>
      )}
    </button>
  );
}

function getClasses({
  justify,
  size,
  variant,
  width,
}: {
  justify: ButtonJustify;
  size: ButtonSize;
  variant: ButtonVariant;
  width: ButtonWidth;
}): string {
  const baseStyles = ctl(`
    flex
    group
    items-center
    font-sans
    font-medium
    rounded-lg
    transition-colors
  `);

  const justifyStyles = {
    around: ctl(`justify-around`),
    between: ctl(`justify-between`),
    center: ctl(`justify-center`),
    end: ctl(`justify-end`),
    evenly: ctl(`justify-evenly`),
    normal: ctl(`justify-normal`),
    start: ctl(`justify-start`),
    stretch: ctl(`justify-stretch`),
  };

  const sizeStyles = {
    xs: ctl(`py-1 px-1`),
    sm: ctl(`py-1.5 px-4`),
    md: variant === "icon" ? ctl(`p-[9px]`) : ctl(`py-3 px-4`),
    lg: ctl(`py-4 px-5`),
  };

  const variantStyles = {
    base: ctl(`
      ${baseStyles}
      border
      bg-purple-500
      border-purple-500
      text-white
      hover:bg-purple-600
      hover:border-purple-600
      active:bg-purple-700
      active:border-purple-700
      focus-visible:outline-none
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-purple-900
      disabled:pointer-events-none
      disabled:bg-neutral-200
      disabled:border-neutral-200
      disabled:text-neutral-400
    `),
    primary: ctl(`
      ${baseStyles}
      border
      bg-purple-500
      border-purple-500
      text-white
      ${width === "min" ? "" : "min-w-[12rem]"}
      hover:bg-purple-600
      hover:border-purple-600
      active:bg-purple-700
      active:border-purple-700
      focus-visible:outline-none
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-purple-900
      disabled:pointer-events-none
      disabled:bg-neutral-200
      disabled:border-neutral-200
      disabled:text-neutral-400
    `),
    primaryDark: ctl(`
      ${baseStyles}
      border
      bg-purple-900
      border-purple-900
      text-white
      ${width === "min" ? "" : "min-w-[12rem]"}
      hover:bg-purple-800
      hover:border-purple-800
      active:bg-purple-700
      active:border-purple-700
      focus-visible:outline-none
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-purple-700
      disabled:pointer-events-none
      disabled:bg-neutral-200
      disabled:border-neutral-200
      disabled:text-neutral-400
    `),
    primaryInverse: ctl(`
      ${baseStyles}
      border
      bg-white
      border-white
      text-purple-600
      ${width === "min" ? "" : "min-w-[12rem]"}
      focus-visible:outline-none
      focus-visible:bg-neutral-50
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-neutral-900
      disabled:pointer-events-none
      disabled:bg-neutral-800
      disabled:border-neutral-800
      disabled:text-neutral-600
    `),
    secondary: ctl(`
      ${baseStyles}
      border
      border-neutral-500
      bg-transparent
      text-neutral-900
      hover:bg-neutral-50
      hover:border-neutral-600
      active:border-neutral-600
      active:bg-neutral-100
      focus-visible:outline-none
      focus-visible:bg-neutral-50
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-neutral-900
      disabled:pointer-events-none
      disabled:border-neutral-300
      disabled:text-neutral-600
    `),
    secondaryInverse: ctl(`
      ${baseStyles}
      border
      border-white
      bg-transparent
      text-white
      hover:bg-white
      hover:bg-opacity-30
      active:bg-white
      active:bg-opacity-40
      focus-visible:outline-none
      focus-visible:bg-white
      focus-visible:bg-opacity-20
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-transparent
      focus-visible:ring-white
      disabled:pointer-events-none
      disabled:border-neutral-300
      disabled:text-neutral-600
    `),
    info: ctl(`
      ${baseStyles}
      border
      bg-status-teal-500
      text-white
      hover:bg-status-teal-600
      active:bg-status-teal-700
      focus-visible:outline-none
      focus-visible:bg-status-teal-500
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-status-teal-800
      disabled:pointer-events-none
      disabled:text-neutral-600
    `),
    success: ctl(`
      ${baseStyles}
      bg-green-300
      text-purple-900
      hover:bg-green-500
      active:bg-green-700
      focus-visible:outline-none
      focus-visible:bg-green-500
      focus-visible:ring-green-800
      disabled:pointer-events-none
      disabled:text-neutral-600
    `),
    warning: ctl(`
      ${baseStyles}
      border
      bg-status-yellow-500
      text-white
      hover:bg-status-yellow-600
      active:bg-status-yellow-700
      focus-visible:outline-none
      focus-visible:bg-status-yellow-500
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-status-yellow-800
      disabled:pointer-events-none
      disabled:text-neutral-600
    `),
    error: ctl(`
      ${baseStyles}
      border
      bg-status-red-500
      text-white
      hover:bg-status-red-600
      active:bg-status-red-700
      focus-visible:outline-none
      focus-visible:bg-status-red-500
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-status-red-800
      disabled:pointer-events-none
      disabled:text-neutral-600
    `),
    danger: ctl(`
      ${baseStyles}
      border
      border-status-red-500
      bg-white
      text-status-red-500
      hover:bg-status-red-500
      hover:text-white
      active:bg-status-red-500
      focus-visible:outline-none
      focus-visible:bg-status-red-500
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-status-red-500
      focus-visible:text-white
      disabled:pointer-events-none
      disabled:text-neutral-600
    `),
    transparent: ctl(`
      ${baseStyles}
      text-white
      bg-white
      bg-opacity-20
      hover:bg-opacity-30
      active:bg-opacity-30
      focus-visible:outline-none
      focus-visible:bg-opacity-30
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-purple-100
      disabled:bg-opacity-10
      disabled:pointer-events-none
      disabled:text-neutral-600
    `),
    tertiary: ctl(`
      ${baseStyles}
      border
      border-transparent
      text-neutral-900
      hover:bg-neutral-50
      hover:border-white
      active:bg-neutral-100
      active:border-neutral-100
      focus-visible:outline-none
      focus-visible:bg-transparent
      focus-visible:ring-offset-1
      focus-visible:ring-1
      focus-visible:ring-offset-transparent
      focus-visible:ring-neutral-900
      disabled:pointer-events-none
      disabled:text-neutral-600
    `),
    tertiaryInverse: ctl(`
      ${baseStyles}
      border-transparent
      text-white
      hover:bg-neutral-50
      hover:bg-opacity-20
      active:bg-neutral-100
      active:bg-opacity-40
      focus-visible:outline-none
      focus-visible:bg-transparent
      focus-visible:ring-offset-1
      focus-visible:ring-1
      focus-visible:ring-offset-transparent
      focus-visible:ring-white
      disabled:pointer-events-none
    `),
    quaternary: ctl(`
      ${baseStyles}
      bg-neutral-50
      text-neutral-900
      hover:bg-neutral-100
      active:bg-neutral-100
      focus-visible:outline-none
      focus-visible:bg-neutral-50
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-white
      focus-visible:ring-neutral-900
      disabled:bg-neutral-200
      disabled:pointer-events-none
    `),
    link: ctl(`
      ${baseStyles}
      !w-auto
      !px-0.5
      !py-0.5
      text-neutral-600
      underline
      underline-offset-4
      hover:text-neutral-700
      active:text-neutral-800
      focus-visible:outline-none
      focus-visible:bg-transparent
      focus-visible:ring-offset-1
      focus-visible:ring-1
      focus-visible:ring-offset-transparent
      focus-visible:ring-neutral-900
      disabled:pointer-events-none
      disabled:text-neutral-600
      disabled:no-underline
    `),
    linkPrimary: ctl(`
      ${baseStyles}
      !w-auto
      !px-0.5
      !py-0.5
      text-purple-500
      hover:text-purple-700
      active:text-purple-700
      focus-visible:outline-none
      focus-visible:bg-transparent
      focus-visible:ring-offset-1
      focus-visible:ring-1
      focus-visible:ring-offset-transparent
      focus-visible:ring-neutral-900
      disabled:pointer-events-none
      disabled:text-neutral-600
      disabled:no-underline
    `),
    icon: ctl(`
      ${baseStyles}
      border
      border-transparent
      text-neutral-900
      hover:bg-neutral-50
      hover:border-neutral-50
      active:bg-neutral-100
      active:border-neutral-100
      focus-visible:outline-none
      focus-visible:bg-transparent
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-transparent
      focus-visible:ring-neutral-900
      disabled:pointer-events-none
      disabled:text-neutral-600
    `),
    green: ctl(`
      ${baseStyles}
      border
      bg-green-300
      border-green-300
      text-purple-900
      ${width === "min" ? "" : "min-w-[12rem]"}
      hover:bg-green-500
      hover:border-green-500
      focus-visible:outline-none
      focus-visible:bg-green-500
      focus-visible:ring-offset-2
      focus-visible:ring-2
      focus-visible:ring-offset-purple-500
      focus-visible:ring-white
      disabled:pointer-events-none
      disabled:bg-neutral-800
      disabled:border-neutral-800
      disabled:text-neutral-600
    `),
  };

  return `${variantStyles[variant]} ${justifyStyles[justify]} ${sizeStyles[size]} w-${width}`;
}
