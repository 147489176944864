import ctl from "@netlify/classnames-template-literals";

export const input = (showValidation: boolean) =>
  ctl(`
  !rounded-r-lg
  !px-4
  !py-3
  !pl-0
  !h-auto
  w-full
  !font-sans
  !text-base
  !text-neutral-900
  border
  !border-l-white
  focus:!ring-0
  ${showValidation ? "!border-red-500" : "border-neutral-300"}
`);

export const countrySelectorButton = (showValidation: boolean) =>
  ctl(`
  !rounded-l-lg
  !px-4
  !py-3
  !h-auto
  border
  ${showValidation ? "!border-red-500" : "border-neutral-300"}
`);

export const countrySelectorListItem = ctl(`
  bg-white
  !px-4
  !py-2
  hover:!bg-neutral-100
`);

export const countrySelectorListItemCountryName = ctl(`
  !font-sans
  !text-base
  !text-neutral-900
`);

export const countrySelectorListItemDialCode = ctl(`
  !font-sans
  !text-base
  !text-neutral-500
`);

export const dialCodePreview = (showValidation: boolean) =>
  ctl(`
  !font-sans
  !text-base
  !text-neutral-500
  border
  ${showValidation ? "!border-red-500" : "border-neutral-300"}
`);
