import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function CreditCardIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <rect
        x="22"
        y="2.875"
        width="17.25"
        height="20"
        rx="7"
        transform="rotate(90 22 2.875)"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M5.58331 8.29166L18.4166 8.29166"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="15.5882"
        cy="14.2826"
        r="2"
        transform="rotate(-45 15.5882 14.2826)"
        fill={color}
      />
    </SvgIcon>
  );
}
