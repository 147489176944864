import React from "react";

interface Props {
  size: number;
}

export default function Noise({ size }: Props) {
  return (
    <span className="mix-blend-multiply">
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
        <filter id="n" x="0" y="0">
          <feTurbulence
            type="fractalNoise"
            baseFrequency="0.75"
            stitchTiles="stitch"
          />
        </filter>

        <rect width={size} height={size} fill="#fff" />
        <rect width={size} height={size} filter="url(#n)" opacity="0.80" />
      </svg>
    </span>
  );
}
