import { Tab as HuiTab } from "@headlessui/react";
import cx from "classnames";
import React, { ReactNode } from "react";

import { Tab } from "../../atoms/Tab";
import * as s from "./styles";

export interface TabOptions {
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  tabHeaderText: string;
  tabContent: ReactNode;
  tabSubHeaderText?: string;
  tabTagText?: string;
  tabTitleTestId?: string;
  tabPanelTestId?: string;
  unmount?: boolean;
}

export interface ITabSetProps {
  handleTabChange?: (index: number) => void;
  fullHeight?: boolean;
  spaceEvenly?: boolean;
  selectedTabIndex?: number;
  tabOptions: TabOptions[];
}

export default function TabSet({
  fullHeight,
  handleTabChange,
  spaceEvenly,
  selectedTabIndex,
  tabOptions,
}: ITabSetProps) {
  return (
    <HuiTab.Group selectedIndex={selectedTabIndex} onChange={handleTabChange}>
      <div className={cx(s.tabListWrapper)}>
        <HuiTab.List className="flex">
          {tabOptions.map((option) => (
            <div
              key={option.tabHeaderText}
              className={cx({
                "flex-1": spaceEvenly,
                [s.tabWrapper]: !spaceEvenly,
              })}
            >
              <Tab
                tabTitleTestId={option.tabTitleTestId}
                iconLeft={option.iconLeft}
                iconRight={option.iconRight}
                fullWidth={spaceEvenly}
                subHeader={option.tabSubHeaderText}
                headerText={option.tabHeaderText}
                tagText={option.tabTagText}
              />
            </div>
          ))}
        </HuiTab.List>
      </div>
      <HuiTab.Panels
        className={cx({
          "h-full": fullHeight,
        })}
      >
        {tabOptions.map((option, index) => (
          <HuiTab.Panel
            className={cx({
              "h-full": fullHeight,
            })}
            data-testid={option.tabPanelTestId ?? undefined}
            key={index}
            unmount={option.unmount ?? false}
          >
            {option.tabContent}
          </HuiTab.Panel>
        ))}
      </HuiTab.Panels>
    </HuiTab.Group>
  );
}
