import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function UploadIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M22 17C19.1819 18.8885 15.7296 20 12 20C8.27044 20 4.81808 18.8885 2 17"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16 7L14.1213 5.12132C12.9497 3.94975 11.0503 3.94975 9.87868 5.12132L8 7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 16L12 5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
