import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function CopyIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M3 11.8182C3 9.15717 5.15717 7 7.81818 7H12.1818C14.8428 7 17 9.15717 17 11.8182V16.1818C17 18.8428 14.8428 21 12.1818 21H7.81818C5.15717 21 3 18.8428 3 16.1818V11.8182Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M7 6.78278C7.60198 4.60171 9.60039 3 11.9729 3H15.8417C18.6905 3 21 5.30947 21 8.15834V12.0271C21 14.3996 19.3983 16.398 17.2172 17"
        stroke={color}
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
