import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function VerticalBarsIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M4 7.5C4 5.567 5.567 4 7.5 4C9.433 4 11 5.567 11 7.5V16.5C11 18.433 9.433 20 7.5 20C5.567 20 4 18.433 4 16.5V7.5ZM14 7.5C14 5.567 15.567 4 17.5 4C19.433 4 21 5.567 21 7.5V16.5C21 18.433 19.433 20 17.5 20C15.567 20 14 18.433 14 16.5V7.5Z"
        stroke={color}
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
