import ctl from "@netlify/classnames-template-literals";

export const inputBar = ctl(`
  flex
  flex-row
  bg-neutral-200 
  p-2
  pl-5
  flex
  items-center
  justify-between
  w-full
  rounded
  shadow-sm
  text-left
  cursor-pointer
  focus:outline-none
  focus:ring-1
  focus:ring-blue-500
  focus:border-blue-500
`);

export const optionsList = ctl(`
  w-auto
  absolute
  z-10
  min-w-full
  bg-white
  max-h-56
  rounded
  overflow-y-auto
  text-base
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
  sm:text-sm
`);

export const option = ctl(`
  cursor-pointer
  select-none
  relative
  py-2
  pl-3
  pr-9
  hover:bg-purple-500
  hover:text-white
`);
