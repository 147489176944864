import {
  useFirebaseApp,
  getAuth,
  getFirestore,
  AuthProvider,
  FirestoreProvider,
} from "@olivahealth/firebase/client";

export default function ReactfireContext({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const app = useFirebaseApp();

  const auth = getAuth(app);
  const firestore = getFirestore(app);

  return (
    <FirestoreProvider sdk={firestore}>
      <AuthProvider sdk={auth}>{children}</AuthProvider>
    </FirestoreProvider>
  );
}
