const DEFAULT_LOGO_SIZE = 24;

const logos = {
  apple: {
    name: "Apple",
    src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADnSURBVHgB7ZTBCgFRFIZ/tigLCyuNN5CyoeQVLG2k2ExNyRvYWXgIWSjlHdh5AQtZaKwsSdbinO7IRuP+o1nx1beauf+5nXvuBX6d7KcfkohGXzyLHmJgIN4D64iBQxA+QwzUxKs4gkX/lUTIt4zYg2mDhk1hdp0SXbEqpsWtOBZ9EBTEDV69trEFggUZfhLLtuFFMlytgMAjw3dhYe8uWg4cR5AFLuAogSzgg0NHuMsuuIE7B32X2iBYkQWe5m0LNCKET0AyJ8J1khyQ6FnsLQs0ERFHXMJM1hBmJNWOuIZ56Fz8+YYHpNeFknpkSyoAAAAASUVORK5CYII=",
  },
  exchange: {
    name: "Exchange",
    src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGmSURBVHgB7ZS/SsNAHMe/uSRYLergoNUldRMR7SS6dXIS30Ao6CuIj6BP4CA4KLjq4NCtmzooiqKCYo2F2ooItbbWNs2dl2Jray5p0rkfCLk/3/t+80vuAnRpg/R/YPFS3+HDMZuSQZdBo4eRsG51A1sZraLQI24waXeV9syV0WWHgGcGZ3SZ0Wj8NABDZglITHMSmqtjNW8F/tBMiSS4OdzMm/EbUAvh5p7FjYDQ2tWTtfhs/8NR3BMkmFrqhx8Imp+sDeUihV+Er2h+PIg5flncvHwjfpuHH/jXva+3iUhgmWtDPThPfeHhrdwylyugnbteVciCa4BFMEAw3K+iV22VXCQVV3NTJlHEQnp9yFntsFFKvCDZoBh4LUAtGfZlREW2qe9YwWfZRDpn2E8iZzCTF5pzNIkaiZH1O8014CRZROq9gtlwHyZCAdu8wsNd0AitHjS0IsUxD7CuTmFgM/X2XwUS09stpKoMvzQqyGxOh627vJ32/h/wAIFf+Fb0Um1nAb/7nEk06jVEEMB2IfRm1/VDlN2I6FYI38KPEIoloUcXIT9wPIiK4P/U+wAAAABJRU5ErkJggg==",
  },
  google: {
    name: "Google",
    src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIESURBVHgB7VQ9axRRFD33zbITIQGjNn6Ao03AwribTix2GgVXcBfUQnsrCysbixgEyT+wdO1skmwRbBR2CauNILsBi0UhU0RQGwcJxt3NvOt9yRImszOTJSmSIgfm47173znn3nlvgCMcNGiYJC7gOAKUJNvZnFBoyr1JdXi7rU0V4Bs4gS5eQuOOXCompQILM2lClOLaAaMhxGeRbrFJS8glhTOJ5Bo1EQiT1w2ZOO5Ku65J7KqMfdi4hxTECiBvTeNT4PQd+iJWpoYIhE1cxy0RadM7fEUKBlrEtREHilfwOQDey9XlopC8xR4xWAHp3KZu3gImrCW62dlBfuZJ61kSmW0HCyvP861dBNSk1LH1PiqNiYBB00hAp2OZxw4BhTRoPoZ9IqYC2dP9Alobp6Rd3yNhngmPLaUfbmjrtHln8PIgXQRck1Nr2T/n/l7MVtYnzJS7WK7WEYNLTz/c/t0bq26vVcGFH7M5L5wz0CJy4b9Ym5rvk4OI5+/OFYvRvMKrxwW/N1rZXkeoRMljKzC4v1gcX+tlljXoXCixIe34qLXKauCK0Vj/cx7t9gMEge0xaXdoAYPSQsmRU1CTz+EgBb1/46tfWo/c1dmpb3HxxF1ULVc92XSuOHgdF8+A9QjpNyfHfl1OIgeG/F2baphULmA9acbSIi8rHsSEjyMcevwHV0Ku/UJdC3gAAAAASUVORK5CYII=",
  },
  microsoft: {
    name: "Microsoft",
    src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgB7ZMxCoAwDEV/i6vu4jW8iLODeg0nb+AVvIp3cPAYugoSI04txE6lQ/vG8JNHIAESoVF24WwqEsMKyzzmigOdlJnq3Zip4ZkkiECQ2YX3FKXwDaz0HaF4yk5B0W6/DddRQpO8+OQSMD0kNA8mzY9G4qMxg9ECzyRBBIJEeB5zkxRsO7SqJwAAAABJRU5ErkJggg==",
  },
  office365: {
    name: "Office 365",
    src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGCSURBVHgB7ZVLS0JBGIafOZ4EKcIiaFGLKGjRplWLskBaRJdVi35AdCGidf+hRX+hTetWQgRhiEY3iKBlhAVWUmpGF+hiZ/qELJVMzVoYvnBgLt+875yBeQbKXaqYYj1A9fUDPZZiXkOb0mwpk5VHE3+zl9iPA65c9CmDUTEcF2NnjjIfFh7TzlKdj5u8AXE3zpcEc1IxLMbdFCiborM+wGGqb6ZP6iFqI7e4lGI6kWBE0qvQH1t5knbQgoCYbMvIlNb05AvMCIjeMSnmi5+JHMh5b4hhwNT4nZvEU1ORPsYoQOZ3k3LuM40B9ihBBn+sSkAloKwDFCG5vVF+MyBqcS/88Qgi+htstAoagpSoDFTUNLGsw+wnIRZNMBZzcWQYnErgiVMIKZzSFKmcuA67abFZQlSLWel2vA+fyXcsq84F4V2S1p69LhvXhT04vfQaiglpuuXvWvh6pztaMG49s9C4y2XaeOEKdeOw2xk0XpmSbvIRWhWDNYcDb/U6F/xLvQFxiW9udBxhswAAAABJRU5ErkJggg==",
  },
  outlook: {
    name: "Outlook",
    src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALMSURBVHgB7VXNa9RAFP/NJNl0s93NVtG1q7ZbsYLU9uZFRPDitRYK9Sh4Fjx48CToxX/Agwe1xUu1JxFPgpceBLUXK1TwIBStUtzuh22ySTaZcZJ0k+5nu1fxBy+ZNzPv/d7HZAL8xz4gvRala4vXxZZHAFdxcNiE0Svu0tyyr1D0Zi/06dyHSigbbShyr50se5SLF987px9KQlHjuBRFgn54oNmQUr72vIVg5MHqkG1ryt59W2Y1xRiPysi5h/RYHlo6TkobVDAynm0hAFlrzcBy1RKoK5w0nHFkk1oYPg/1QDErQDqHg0KOo+Oxo0BHB52DMI5+EPdAGDaivVDQMDuZge1xzL8v48umFa0Z2w4c1YrM6o6HaqnW7JTInTII5eKYhoW5E7j/ZhOyRPD6xiiuPl3H6s9akE1lqwbX/BM58HugJKQmAkJpNwKO2akMnnwoYeFjOZibyKmYnkjj03cz7sM+4G49YqAxAQ8cagpFcceNCEuGK1IWY48BQho96YXq21eP2wn8Hghjv97T5zLIKAS68DwzpePzRi1cFz0hnica7UbCXE/0gYViWrB+l+GeOi93LJH/eLhcxMmMjJXb46jVGebfbeHFSik8BELITgXUsaNo69sqvhmOmDOQGhpEQk9DDNBOwMIumxbDzaUfUCkRPjkcZ7csuwSS5wh9AJwQEP/Y2iaSQ2mow8eamttGENWXhWSWy0LSXeJwDByZPI267aG8/gtKVoeaPw4id79xohXmsTviI0r6Y8IYJLJ9SZbsy5w2vjj/5SKRyiNVKIgrYxhmsQTLMsS1m/bPZkeCrtf1mWcbd4mEe20GVMREYjPPcWAUy7DFySSqFs0Xb40Fm7pe1zQhg0hKm+x17kNKJJDJ55DRxbpRFEnanUvUClH1Kun9P2qCms0GUiuVYVYr0XxPD2cXizMcTEefYJRXvs7lXuKfwF9obWX16rAWXwAAAABJRU5ErkJggg==",
  },
  slack: {
    name: "Slack",
    src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK7SURBVHgB7VRNSFRRFD7n3meZLhojjH6ZUTcRmBRICYESRIOI4cY2hkW7FlGtq1FcO0i4qxCJcKWj/dhCaCC0cONY4UaZnv0IRjozUjnzZu49nXGcfDPOjGRbv8V775x73vedd34ewA62AG4VUDseuQ9AdwFxtCSm21b39OjoshomgHok9E43dt7etsDZiR+HFRlf0zYBXbVWvBEiGkz7BEpXwO0x83EIKIDVmPzFt/DfbBAlZ77PHpOghCzEUVAg0FAWRom1TNyDgH0oKAj/CCPbceZtyCm0tZGVimq+PhivKw+yEFWP3qvIRVQz5HFgKZRMXfAs2P0ZPaidCHUD4S3IDXOXhlMrP7tbgOhh2qmBqgwFQkucZNPBdfS8d3d2pM8zS5SfPAlnzKBm1KCzD7QUN9bIkxkTT5wNGQJI4Id8QNRcNz8ZNLOWeNIFaH50dwXT5Otxr+yvZfbAUM1SF51XWmdMChpCY5xm3p0rM9k0a0Y9ldyceqWsYVYheKn7AcUbAaQgKnx5BQYu3xGgrL38JWBII3hkvv91yNnuCCvrEshdi671uLHY4AGeLqamFhip5lkdSZUDMeLYXawxnwDq+BPOqDHZekWJPtbxz2triqmcfKY/OdvqHN4Pi1roCV62TSOuiGApEfXzY0NuAaCLtP7MRY7MVbVWFFngTNq8xUKr+Akl1XGOLLA/VG+3MgKZtCulhGHDgN7sV6UQ3A4Y5n+QCXnAjfdm2ln4fPTaISWXf7tMX3i2qrWyyJJztujrzvmnj7gS+N13etPC6WKlDroDpt23aZOPfXm8AAUQen6yYekZXJEiHtFC9JY3BWYLxRf8F+VCXBNnTu38NTcF6cnQUI1j2wJGApXdZiO0NusbcCiDSmG7Ai5zwORMU01D9DuKcEwq6eMRfQGpqezY3zT9DXbwP/gDpdgS+A7hasEAAAAASUVORK5CYII=",
  },
};

type LogoVariant =
  | "apple"
  | "exchange"
  | "google"
  | "microsoft"
  | "office365"
  | "outlook"
  | "slack";

interface Props {
  size?: number;
  variant: LogoVariant;
}

export default function Logo({
  size = DEFAULT_LOGO_SIZE,
  variant,
}: Props): JSX.Element {
  if (!logos[variant]) {
    throw new Error(`Invalid logo variant: ${variant}`);
  }

  const logo = logos[variant];

  return <img src={logo.src} alt={logo.name} height={size} width={size} />;
}
