import { useEffect, useState } from "react";
import { Listbox as Dropdown } from "@headlessui/react";
import cx from "classnames";

import { CheckIcon, ChevronIcon } from "../../atoms/Icons";
import Text from "../../atoms/Text";

import * as s from "./styles";

export interface SingleSelectDropdownItem {
  label: string;
  name: string;
  selected: boolean;
  subLabel?: string | number;
}

interface Props {
  title: string;
  items: SingleSelectDropdownItem[];
  handleItemChange?: (item: SingleSelectDropdownItem | undefined) => void;
}

export default function SingleSelectDropdown({
  title,
  items,
  handleItemChange,
}: Props) {
  const [selectedItems, setSelectedItems] =
    useState<SingleSelectDropdownItem[]>(items);
  const [activeItem, setActiveItem] = useState<
    SingleSelectDropdownItem | undefined
  >(items[0]);

  useEffect(() => {
    const activeItem: SingleSelectDropdownItem | undefined = selectedItems.find(
      (item) => item.selected,
    );
    setActiveItem(activeItem);
    handleItemChange?.(activeItem);
  }, [selectedItems]);

  const handleSelect = (itemValue: string) => {
    const updatedItems = selectedItems.map((item) => ({
      ...item,
      selected: item.name === itemValue,
    }));

    setSelectedItems(updatedItems);
  };

  const handleSelected = (itemValue: string) => {
    const selected = selectedItems.find((item) => item.name === itemValue);

    if (selected?.selected) {
      return;
    }

    handleSelect(itemValue);
  };

  return (
    <Dropdown
      as="div"
      className="relative"
      onChange={handleSelected}
      value={activeItem?.name}
    >
      {({ open }) => (
        <>
          <Dropdown.Button
            className={cx(s.button, {
              "bg-neutral-100": open,
              "bg-white": !open,
            })}
          >
            {title}: {activeItem?.label}
            <ChevronIcon
              size={20}
              direction="down"
              className={cx("ml-2 transform transition-all duration-300", {
                "-rotate-180": open,
                "rotate-0": !open,
              })}
            />
          </Dropdown.Button>
          <Dropdown.Options className={s.optionList}>
            {selectedItems.map((item, index) => (
              <Dropdown.Option
                key={`${item}-${index}`}
                value={item.name}
                className={cx(s.option)}
              >
                <div className={cx(s.truncatedText)}>
                  {item.selected && (
                    <CheckIcon size={22} className="text-purple-500" />
                  )}
                </div>
                <div className="flex w-full items-center justify-between">
                  <div>{item.label}</div>
                  {item.subLabel && <Text size="sm">{item.subLabel}</Text>}
                </div>
              </Dropdown.Option>
            ))}
          </Dropdown.Options>
        </>
      )}
    </Dropdown>
  );
}
