import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

type UserVariant = "default" | "block";

interface Props extends IconProps {
  variant?: UserVariant;
}

export function UserIcon({
  className,
  color = "currentColor",
  size,
  variant = "default",
}: Props): ReactElement {
  const variants = {
    default: (
      <g>
        <circle cx="12" cy="6.49634" r="3.5" fill={color} />
        <path
          d="M4 19C5.59687 16.011 8.58154 14 12 14C15.4185 14 18.4031 16.011 20 19"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    block: (
      <g>
        <circle cx="17.8484" cy="14.5" r="4.5" stroke={color} strokeWidth="2" />
        <path
          d="M14.3483 16.934L21.6517 11.9129"
          stroke={color}
          strokeWidth="2"
        />
        <circle cx="10" cy="6.49634" r="3.5" fill={color} />
        <path
          d="M10 14C6.58154 14 3.59687 16.011 2 19"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
