import cx from "classnames";
import React, { ReactNode } from "react";

import * as s from "./styles";

interface Props {
  children?: ReactNode;
}

export default function Panel({ children }: Props) {
  return <aside className={cx(s.panelWrapper)}>{children}</aside>;
}
