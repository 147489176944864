import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import PapaParse from "papaparse";
import { UploadIcon } from "../../atoms/Icons";
import Button from "../../atoms/Button/index";
import FieldTooltip from "../Tooltip/index";

export interface Props {
  onFileLoaded: (values: string[]) => void;
  label?: string;
  type?: string;
  tooltipText?: string;
  isDisabled: boolean;
}

const FileUpload = ({
  onFileLoaded,
  label = "Upload file",
  type = "csv",
  tooltipText,
  isDisabled,
}: Props) => {
  const [csvData, setCSVData] = useState<string[]>([]);
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const list = csvData.flat();
    if (list.length > 0) {
      onFileLoaded(list);
    }
  }, [csvData]);

  const onBtnClick = () => {
    //  Collecting node-element and performing click
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const contents = e.target?.result;
          if (typeof contents === "string") {
            const parsedData = PapaParse.parse(contents).data;
            if (Array.isArray(parsedData)) {
              setCSVData(parsedData.flatMap((row: any) => row.map(String)));
            }
          }
        } catch (error) {
          // Handle the error, e.g., display an error message or log it
          console.error("Error parsing CSV file:", error);
        }
      };

      reader.readAsText(file);
    }
  };

  return isDisabled && tooltipText ? (
    <FieldTooltip text={tooltipText} placement="top">
      <div>
        <input
          type="file"
          id="invite-csv"
          ref={inputFileRef}
          onChange={(e) => handleFileChange(e)}
          accept={`.${type}`}
          style={{ display: "none" }}
        />
        <Button
          variant="link"
          disabled={isDisabled}
          className="items-center text-sm text-neutral-600"
          onClick={onBtnClick}
          iconLeft={<UploadIcon size={18} />}
        >
          {label}
        </Button>
      </div>
    </FieldTooltip>
  ) : (
    <div>
      <input
        type="file"
        id="invite-csv"
        ref={inputFileRef}
        onChange={(e) => handleFileChange(e)}
        accept={`.${type}`}
        style={{ display: "none" }}
      />
      <Button
        variant="link"
        disabled={isDisabled}
        className="items-center text-sm text-neutral-600"
        onClick={onBtnClick}
        iconLeft={<UploadIcon size={18} />}
      >
        {label}
      </Button>
    </div>
  );
};

export default FileUpload;
