import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { RESOURCE_PROGRESS_TRACKING_INTERVAL_MS } from "@olivahealth/constants";

export type { ReactPlayerProps as Props } from "react-player";

export default function VideoPlayer(props: ReactPlayerProps): JSX.Element {
  if (!props.url) {
    return <div>Video URL is not defined</div>;
  }

  return (
    <ReactPlayer
      {...props}
      config={{
        file: {
          attributes: {
            controlsList: "nodownload",
            crossOrigin: "true",
          },
          forceVideo: true,
          tracks: props.tracks ?? [],
        },
      }}
      controls={props.controls ?? true}
      data-testid="video-player"
      fallback={<div>loading...</div>}
      height="100%"
      pip={props.pip ?? true}
      progressInterval={RESOURCE_PROGRESS_TRACKING_INTERVAL_MS}
      stopOnUnmount={props.stopOnUnmount ?? false}
      style={{ ...props.style, position: "absolute", top: 0, left: 0 }}
      width="100%"
    />
  );
}
