import "../../datadog.client.config";
import { useEffect } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { config, FirebaseAppProvider } from "@olivahealth/firebase/client";
import { createEnvironment } from "@olivahealth/utils/relayUtils";
import { FeatureFlagsValues } from "@olivahealth/utils/types/FeatureFlags";

import { FEATURE_FLAGS_DEFAULT_VALUES } from "@olivahealth/constants";
import AmplitudeContextProvider from "../services/contexts/AmplitudeContext";
import SignOutContextProvider from "../services/contexts/SignOutContext";
import AuthAuthorizedContextProvider from "../services/contexts/AuthAuthorizedContext";
import ReactfireContext from "../services/contexts/ReactfireContext";
import FeatureFlagsProvider from "../services/contexts/FeatureFlagsContext";
import UserDataProvider from "../services/contexts/UserDataContext";
import usePreserveScroll from "../hooks/usePreserveScroll";
import { ErrorBoundary } from "../components/common/ErrorBoundary";
import ErrorPage from "../components/common/ErrorPage";
import IntercomProvider from "../services/contexts/IntercomProvider";

import "tailwindcss/tailwind.css";
import "../styles/main.css";
import "../styles/cronofy.css";
import "../styles/sendbird.css";
import "../styles/swiper.css";
import EmployeeOnboardingMiddleware from "../components/common/EmployeeOnboardingMiddleware";
import HeadTags from "../components/common/HeadTags";
import IntercomMiddleware from "../components/common/IntercomMiddleware";
import SignedOutAfterInactivityMiddleware from "../rebrand/organisms/auth/SignedOutAfterInactivityMiddleware";
import ChatProvider from "../services/contexts/ChatContext";
import SharedStoreContextProvider from "../services/contexts/SharedStoreContext";

const gb = new GrowthBook<FeatureFlagsValues>({
  apiHost: process.env.NEXT_PUBLIC_FEATURE_FLAGS_ENDPOINT,
  clientKey: process.env.NEXT_PUBLIC_FEATURE_FLAGS_KEY,
  enableDevMode: true,
  subscribeToChanges: true,
});

function App({ Component, pageProps }: AppProps): JSX.Element {
  usePreserveScroll();

  useEffect(() => {
    gb.loadFeatures();
  }, []);

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "true") {
    return (
      <ErrorPage
        title="Oliva is currently in maintenance mode."
        description="We are working to bring it back to you as soon as possible."
      />
    );
  }

  return (
    <FirebaseAppProvider firebaseConfig={config}>
      <ReactfireContext>
        <RelayEnvironmentProvider environment={createEnvironment()}>
          <GrowthBookProvider growthbook={gb}>
            <FeatureFlagsProvider defaults={FEATURE_FLAGS_DEFAULT_VALUES}>
              <AmplitudeContextProvider>
                <SharedStoreContextProvider>
                  <SignOutContextProvider>
                    <AuthAuthorizedContextProvider>
                      <UserDataProvider>
                        <SignedOutAfterInactivityMiddleware>
                          <ChatProvider>
                            <EmployeeOnboardingMiddleware>
                              <IntercomProvider>
                                <IntercomMiddleware>
                                  <>
                                    <HeadTags />
                                    <ErrorBoundary>
                                      <Component {...pageProps} />
                                    </ErrorBoundary>
                                  </>
                                </IntercomMiddleware>
                              </IntercomProvider>
                            </EmployeeOnboardingMiddleware>
                          </ChatProvider>
                        </SignedOutAfterInactivityMiddleware>
                      </UserDataProvider>
                    </AuthAuthorizedContextProvider>
                  </SignOutContextProvider>
                </SharedStoreContextProvider>
              </AmplitudeContextProvider>
            </FeatureFlagsProvider>
          </GrowthBookProvider>
        </RelayEnvironmentProvider>
      </ReactfireContext>
    </FirebaseAppProvider>
  );
}

export default appWithTranslation(App);
