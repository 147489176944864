import React, { Context, createContext, useContext } from "react";
import { graphql, useMutation } from "react-relay";
import { useAuth } from "@olivahealth/firebase/client";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import logger from "@olivahealth/logger/client";
import { dangerouslyClearStoreSource } from "@olivahealth/utils/relayUtils";

import useOAuthClient from "../../hooks/useOAuthClient";
import { useAmplitude } from "./AmplitudeContext";
import { SignOutContextMutation as IMutation } from "./__generated__/SignOutContextMutation.graphql";

interface ISignOutContext {
  signOut: (reason: string) => void;
}

const defaultContext: ISignOutContext = {
  signOut: () => null,
};

const SignOutMutationContextMutation = graphql`
  mutation SignOutContextMutation {
    revokeToken {
      success
    }
  }
`;

const SignOutContext: Context<ISignOutContext> = createContext(defaultContext);
export const useSignOut = (): ISignOutContext => useContext(SignOutContext);

export default function SignOutContextProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [revokeToken] = useMutation<IMutation>(SignOutMutationContextMutation);
  const { clearStorage } = useAmplitude();
  const auth = useAuth();
  const oauthClient = useOAuthClient();

  const signOut = (reason: string) => {
    logger.info("SignOutContextProvider", "Signing out user", {
      reason,
    });
    revokeToken({
      variables: {},
      onCompleted: () => {},
    });
    dangerouslyClearStoreSource();
    auth.signOut();
    oauthClient?.reset();
    datadogRum.clearUser();
    datadogLogs.clearUser();
    clearStorage();
  };

  return (
    <SignOutContext.Provider
      value={{
        signOut,
      }}
    >
      {children}
    </SignOutContext.Provider>
  );
}
