import ctl from "@netlify/classnames-template-literals";

const wrapperBase = ctl(`
  bg-neutral-50
  items-center
  flex
  rounded-xl
`);

export const wrapper = ctl(`
  ${wrapperBase}
  p-4
`);

export const wrapperSm = ctl(`
  ${wrapperBase}
  p-2
`);

export const content = ctl(`
  flex
  flex-row
  space-x-2
`);

export const cta = ctl(`
  text-left
  text-sm
  font-medium
  text-neutral-600
  underline
  whitespace-nowrap
`);
