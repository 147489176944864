import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

type NoteVariant = "default" | "check" | "cross" | "edit";

interface Props extends IconProps {
  variant?: NoteVariant;
}

export function NoteIcon({
  className,
  color = "currentColor",
  size,
  variant = "default",
}: Props): ReactElement {
  const variants = {
    default: (
      <g>
        <path
          d="M3 10C3 6.13401 6.13401 3 10 3H14C17.866 3 21 6.13401 21 10V14C21 17.866 17.866 21 14 21H10C6.13401 21 3 17.866 3 14V10Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M7.45454 12.9091L16.5455 12.9091"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M7.45454 16.5455L16.5455 16.5455"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M3.5 9H7C8.10457 9 9 8.10457 9 7V3.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    check: (
      <g>
        <path
          d="M10 3H14C17.866 3 21 6.13401 21 10V14C21 17.866 17.866 21 14 21H10C6.13401 21 3 17.866 3 14V10C3 6.13401 6.13401 3 10 3Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M3.5 9H7C8.10457 9 9 8.10457 9 7V3.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6522 10.2586C16.0708 10.6436 16.1183 11.3184 15.7581 11.766L12.144 16.2564C11.3968 17.1847 10.0741 17.2538 9.24589 16.4076L8.30905 15.4505C7.9098 15.0427 7.8955 14.3661 8.2771 13.9393C8.6587 13.5126 9.2917 13.4973 9.69095 13.9052L10.6278 14.8623L14.2419 10.3718C14.6021 9.9243 15.2335 9.87361 15.6522 10.2586Z"
          fill={color}
        />
      </g>
    ),
    cross: (
      <g>
        <path
          d="M10 3H14C17.866 3 21 6.13401 21 10V14C21 17.866 17.866 21 14 21H10C6.13401 21 3 17.866 3 14V10C3 6.13401 6.13401 3 10 3Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M3.5 9H7C8.10457 9 9 8.10457 9 7V3.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <rect
          width="2"
          height="8"
          rx="1"
          transform="matrix(0.707107 0.707107 -0.707067 0.707147 14.6569 11)"
          fill={color}
        />
        <rect
          width="2"
          height="8"
          rx="1"
          transform="matrix(0.707107 -0.707107 0.707147 0.707067 9 12.4145)"
          fill={color}
        />
      </g>
    ),
    edit: (
      <g>
        <path
          d="M13.7891 3.30222H10.3193C6.48666 3.30222 3.37971 6.40916 3.37971 10.2418V13.7116C3.37971 17.5442 6.48666 20.6511 10.3193 20.6511H13.7891C17.6217 20.6511 20.7286 17.5442 20.7286 13.7116V11.625"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M4.08304 9.16334H7.24082C8.34539 9.16334 9.24082 8.26791 9.24082 7.16334V4.00555"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M10.2337 12.4157C10.211 11.7834 10.4522 11.1702 10.8996 10.7228L17.3322 4.29012C18.2539 3.36846 19.7482 3.36846 20.6699 4.29012C21.5915 5.21178 21.5915 6.70608 20.6699 7.62774L14.2372 14.0604C13.7898 14.5078 13.1766 14.749 12.5443 14.7263L10.9083 14.6676C10.5733 14.6556 10.3044 14.3867 10.2924 14.0517L10.2337 12.4157Z"
          stroke={color}
          strokeWidth="2"
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
