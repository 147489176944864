import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  direction: "up" | "down" | "left" | "right";
}

export function ArrowIcon({
  className,
  color = "currentColor",
  direction,
  size,
}: Props): ReactElement {
  const arrow = {
    left: (
      <g>
        <path
          d="M8.14233 7.75L5.43243 10.6293C4.70763 11.3994 4.70763 12.6006 5.43243 13.3707L8.14233 16.25"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M19.1111 12L6.14233 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    right: (
      <g>
        <path
          d="M15.8577 16.25L18.5676 13.3707C19.2924 12.6006 19.2924 11.3994 18.5676 10.6293L15.8577 7.75"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M4.88892 12L17.8577 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    up: (
      <g>
        <path
          d="M16.25 8.14239L13.3707 5.43249C12.6006 4.70769 11.3994 4.70769 10.6293 5.43249L7.75 8.1424"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M12 19.1111L12 6.1424"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    down: (
      <g>
        <path
          d="M7.75 15.8576L10.6293 18.5675C11.3994 19.2923 12.6006 19.2923 13.3707 18.5675L16.25 15.8576"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M12 4.88885V17.8576"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {arrow[direction]}
    </SvgIcon>
  );
}
