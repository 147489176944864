import cx from "classnames";
import * as s from "./styles";

type variantType = "neutral" | "primary";

interface Props {
  variant: variantType;
  label?: string | number;
  icon?: string;
}

export default function Pagination({ variant, label }: Props): JSX.Element {
  const style = variant === "primary" ? s.primary : s.neutral;
  const activeStyle = variant === "primary" ? s.activePrimary : s.activeNeutral;

  return <div className={cx(s.sharedStyle, style, activeStyle)}>{label}</div>;
}
