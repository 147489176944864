import {
  Dispatch,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import type { UnreadMessageCount } from "@sendbird/chat/groupChannel";
import {
  SendBirdProvider,
  sendbirdSelectors,
  useSendbirdStateContext,
} from "@sendbird/uikit-react";
import UserEventHandler from "@sendbird/uikit-react/handlers/UserEventHandler";
import { SendbirdChatType } from "@sendbird/uikit-react/types/lib/types";
import logger from "@olivahealth/logger/client";

import useTranslation from "../../hooks/useTranslation";
import { useUserData } from "./UserDataContext";

interface IChatContext {
  getGroupChannelsCount: () => Promise<number>;
  initialized: boolean;
  unreadMessagesCount: number;
  userChatId: string;
}

export const ChatContext = createContext<IChatContext>({
  getGroupChannelsCount: () => Promise.resolve(0),
  initialized: false,
  unreadMessagesCount: 0,
  userChatId: "",
});

export const useChat = (): IChatContext => useContext(ChatContext);

function initContext(
  groupChannelSdk: SendbirdChatType,
  setUnreadMessagesCount: Dispatch<SetStateAction<number>>,
) {
  const userEventHandler = new UserEventHandler({
    onTotalUnreadMessageCountChanged: (
      unreadMessageCount: UnreadMessageCount,
    ) => {
      setUnreadMessagesCount(unreadMessageCount.groupChannelCount);
    },
  });

  groupChannelSdk.addUserEventHandler(
    "change-unread-messages-count",
    userEventHandler,
  );

  groupChannelSdk.groupChannel
    .getTotalUnreadMessageCount()
    .then(setUnreadMessagesCount)
    .catch((error) => {
      logger.warn(
        "ChatContext/getTotalUnreadMessageCount",
        "Error getting total unread messages count",
        {
          errorMessage: (error as Error).message,
          errorStack: (error as Error).stack,
        },
      );
    });
}

function ChatContextProvider({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);
  const sendbirdState = useSendbirdStateContext();

  const groupChannelSdk = sendbirdSelectors.getSdk(sendbirdState);

  if (groupChannelSdk.currentUser) {
    initContext(groupChannelSdk, setUnreadMessagesCount);
  }

  const getGroupChannelsCount = useCallback(
    () =>
      groupChannelSdk.groupChannel.getGroupChannelCount({}).catch((error) => {
        logger.error(
          "ChatContext/getGroupChannelsCount",
          "Error getting total group channel count",
          {
            errorMessage: (error as Error).message,
            errorStack: (error as Error).stack,
          },
        );
      }),
    [groupChannelSdk],
  );

  const contextValues = useMemo(
    () =>
      ({
        getGroupChannelsCount,
        initialized: Boolean(groupChannelSdk.currentUser),
        unreadMessagesCount,
        userChatId: sendbirdState.config.userId,
      }) as IChatContext,
    [
      getGroupChannelsCount,
      groupChannelSdk.currentUser,
      sendbirdState.config.userId,
      unreadMessagesCount,
    ],
  );

  return (
    <ChatContext.Provider value={contextValues}>
      {children}
    </ChatContext.Provider>
  );
}

export default function ChatProvider({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  const { t } = useTranslation("messages");
  const { data: currentUser } = useUserData();

  const sendbirdAppId = process.env.NEXT_PUBLIC_SENDBIRD_APP_ID ?? "";
  const sendbirdUserId = currentUser?.sendbirdId;

  if (!sendbirdUserId) {
    return children;
  }

  return (
    <SendBirdProvider
      appId={sendbirdAppId}
      userId={sendbirdUserId}
      isMultipleFilesMessageEnabled
      uikitOptions={{
        groupChannel: {
          enableMarkdownForUserMessage: true,
        },
      }}
      colorSet={{
        "--sendbird-font-family-default": "'DM Sans', sans-serif, system-ui",
      }}
      stringSet={{
        MESSAGE_INPUT__PLACE_HOLDER: t("channelPanel.inputMessagePlaceholder"),
      }}
    >
      <ChatContextProvider>{children}</ChatContextProvider>
    </SendBirdProvider>
  );
}
