import { useState } from "react";
import { Switch as HUISwitch } from "@headlessui/react";
import { getInnerStyles, getWrapperStyles } from "./styles";

export interface Props {
  onChange: (_checked: boolean) => void;
  isChecked?: boolean;
  label?: string;
  labelPlacement?: "left" | "right";
}

export default function Switch({
  onChange,
  isChecked = false,
  label,
  labelPlacement = "right",
}: Props) {
  const [checked, setChecked] = useState(isChecked);
  const hasLabel = Boolean(label);

  const stackOrder =
    labelPlacement === "right" ? "flex-row" : "flex-row-reverse";

  return (
    <HUISwitch.Group>
      <div className={`flex items-center justify-center gap-x-4 ${stackOrder}`}>
        <HUISwitch
          checked={checked}
          onChange={(checked) => {
            setChecked(checked);
            onChange(checked);
          }}
          className={getWrapperStyles(checked)}
        >
          <span className={getInnerStyles(checked)} />
        </HUISwitch>

        {hasLabel && <HUISwitch.Label>{label}</HUISwitch.Label>}
      </div>
    </HUISwitch.Group>
  );
}
