import { datadogLogs } from "@datadog/browser-logs";

function format(feature: string, message: string) {
  return `[${feature}] ${message}`;
}

const logger = {
  /**
   * This function formats the log message and sends it directly to Datadog.
   *  -> logger.error("MyFeature", "something happened", { userId: 123 })
   * Will produce the following log:
   *  -> { message: "[MyFeature] something happened", userId: 123, level: 'error' }
   *
   * @param feature Identifier for the log, it could be the file name
   * @param message Message that explains what happened
   * @param data Any additional data. Do not log big variables or data.
   */
  error(feature: string, message: string, data: object = {}) {
    datadogLogs.logger.log(format(feature, message), data, "error");
  },
  /**
   * This function formats the log message and sends it directly to Datadog.
   *  -> logger.warn("MyFeature", "something happened", { userId: 123 })
   * Will produce the following log:
   *  -> { message: "[MyFeature] something happened", userId: 123, level: 'warn' }
   *
   * @param feature Identifier for the log, it could be the file name
   * @param message Message that explains what happened
   * @param data Any additional data. Do not log big variables or data.
   */
  warn(feature: string, message: string, data: object = {}) {
    datadogLogs.logger.log(format(feature, message), data, "warn");
  },
  /**
   * This function formats the log message and sends it directly to Datadog.
   *  -> logger.info("MyFeature", "something happened", { userId: 123 })
   * Will produce the following log:
   *  -> { message: "[MyFeature] something happened", userId: 123, level: 'info' }
   *
   * @param feature Identifier for the log, it could be the file name
   * @param message Message that explains what happened
   * @param data Any additional data. Do not log big variables or data.
   */
  info(feature: string, message: string, data: object = {}) {
    datadogLogs.logger.log(format(feature, message), data, "info");
  },
  /**
   * This function formats the log message and sends it directly to Datadog.
   *  -> logger.debug("MyFeature", "something happened", { userId: 123 })
   * Will produce the following log:
   *  -> { message: "[MyFeature] something happened", userId: 123, level: 'debug' }
   *
   * @param feature Identifier for the log, it could be the file name
   * @param message Message that explains what happened
   * @param data Any additional data. Do not log big variables or data.
   */
  debug(feature: string, message: string, data: object = {}) {
    datadogLogs.logger.log(format(feature, message), data, "debug");
  },
};

export default logger;
