import React, { InputHTMLAttributes, ReactElement, ReactNode } from "react";
import cx from "classnames";
import { RegisterOptions } from "react-hook-form";
import purify from "dompurify";
import * as s from "../../shared/inputStyles";

type InputType = "text" | "password" | "email" | "number" | "date" | "tel";

export interface Props {
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  helperText?: React.ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  id: string;
  label?: string;
  labelInfoIcon?: React.ReactElement;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  register: any;
  type?: InputType;
  defaultValue?: string;
  value?: string;
  validationOptions?: Partial<RegisterOptions>;
  onInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Input({
  disabled = false,
  hasError = false,
  errorMessage,
  helperText,
  iconLeft,
  iconRight,
  id,
  label,
  placeholder,
  readOnly = false,
  required = false,
  register,
  type = "text",
  defaultValue,
  value,
  validationOptions,
  labelInfoIcon,
  onInputKeyDown,
  ...props
}: Props): ReactElement {
  const ariaProps: Partial<InputHTMLAttributes<HTMLInputElement>> = {
    "aria-label": label,
    "aria-invalid": hasError ? "true" : "false",
    ...(hasError ? { "aria-describedby": "helperText" } : {}),
  };

  return (
    <div className={s.inputGroup}>
      {label && (
        <label
          className={cx(s.label, {
            [s.labelDisabled]: disabled,
          })}
          htmlFor={id}
        >
          {label}
          {required && <span className={s.labelRequired}>*</span>}
          {labelInfoIcon && <span className="ml-0.5">{labelInfoIcon}</span>}
        </label>
      )}
      <div className="relative">
        <input
          disabled={disabled}
          id={id}
          className={cx(s.input, {
            "pl-12": Boolean(iconLeft),
            "pr-12": Boolean(iconRight),
            [s.inputError]: hasError,
            [s.inputDisabled]: disabled || readOnly,
          })}
          placeholder={placeholder}
          readOnly={readOnly}
          defaultValue={defaultValue}
          value={value}
          type={type}
          onKeyDown={onInputKeyDown}
          {...register(id, {
            ...validationOptions,
            setValueAs: (value: string) => purify.sanitize(value),
          })}
          {...ariaProps}
          {...props}
        />
        {iconLeft && (
          <span
            className={cx(s.icon, "left-4", {
              [s.iconError]: hasError,
              [s.iconDisabled]: disabled,
            })}
          >
            {iconLeft}
          </span>
        )}
        {iconRight && (
          <span
            className={cx(s.icon, "right-4", {
              [s.iconError]: hasError,
              [s.iconDisabled]: disabled,
            })}
          >
            {iconRight}
          </span>
        )}
      </div>
      {helperText && (
        <span
          id="helperText"
          className={cx(s.helperText, {
            [s.helperTextDisabled]: disabled,
          })}
        >
          {helperText}
        </span>
      )}
      {hasError && errorMessage && (
        <span className="ml-2 mt-2 text-sm text-red-400">{errorMessage}</span>
      )}
    </div>
  );
}
