import { ReactNode } from "react";
import { Button, Dialog, Text } from "@olivahealth/oli-ui";
import Image from "../../../../components/common/Image";
import useTranslation from "../../../../hooks/useTranslation";
import useSignedOutAfterInactivityMiddleware from "./useSignedOutAfterInactivityMiddleware";

interface Props {
  children: ReactNode;
}

export default function SignedOutAfterInactivityMiddleware({
  children,
}: Props): ReactNode {
  const { t } = useTranslation("auth", {
    keyPrefix: "signOutAfterInactivity",
  });

  const {
    data: { signedOut },
    effects: { setSignedOut },
  } = useSignedOutAfterInactivityMiddleware();

  const closeDialog = () => {
    setSignedOut(false);
  };

  return (
    <>
      <Dialog
        icon={
          <Image
            alt={t("imageAltText")}
            src="/img/img_clock_dali.png"
            height={64}
            width={64}
          />
        }
        isOpen={signedOut}
        onClose={closeDialog}
        showCloseButton={false}
        size="lg"
        title={t("title")}
        variant="centered"
      >
        <Text align="center">{t("description")}</Text>
        <div className="flex justify-center">
          <Button onClick={closeDialog}>{t("buttonLabel")}</Button>
        </div>
      </Dialog>
      {children}
    </>
  );
}
