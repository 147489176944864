import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function DashCircleIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
      <line
        x1="9"
        y1="12"
        x2="15"
        y2="12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
