import ctl from "@netlify/classnames-template-literals";

export const getPillContainerStyles = (
  checked: boolean,
  isFullWidth: boolean,
) => {
  return ctl(`
    cursor-pointer
    transition-colors
    ${isFullWidth ? "w-full block text-left py-3 px-4 rounded-[8px]" : "inline-block rounded-[50px] py-2 px-4"}
    ${checked ? "bg-purple-100" : "bg-neutral-50"}
    ${checked ? "hover:bg-purple-200" : "hover:bg-neutral-100"}
    ${checked ? "outline-purple-600" : "outline-neutral-600"}
`);
};
