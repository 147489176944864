/**
 * @generated SignedSource<<66a24a0e2e0ddd5ed108208a1eba6712>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SignOutContextMutation$variables = Record<PropertyKey, never>;
export type SignOutContextMutation$data = {
  readonly revokeToken: {
    readonly success: boolean;
  };
};
export type SignOutContextMutation = {
  response: SignOutContextMutation$data;
  variables: SignOutContextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RevokeTokenMutationResponse",
    "kind": "LinkedField",
    "name": "revokeToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignOutContextMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SignOutContextMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0c634f6e9fe19fc2da358377c9e7b76d",
    "id": null,
    "metadata": {},
    "name": "SignOutContextMutation",
    "operationKind": "mutation",
    "text": "mutation SignOutContextMutation {\n  revokeToken {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae47a82033b14f9d8c6a53ee78ab0244";

export default node;
