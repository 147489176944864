import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function PodcastIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M8.48337 17.2105C7.16322 16.1419 6.31573 14.4852 6.31573 12.6253C6.31573 9.40225 8.86064 6.78947 11.9999 6.78947C15.1392 6.78947 17.6842 9.40225 17.6842 12.6253C17.6842 14.4852 16.8367 16.1419 15.5165 17.2105"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17.9782 19.473C20.1112 17.772 21.4737 15.18 21.4737 12.2764C21.4737 7.15319 17.2322 3 12 3C6.76782 3 2.52631 7.15319 2.52631 12.2764C2.52631 15.18 3.88873 17.772 6.02177 19.473"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 21L12 16.2632"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="12.5886"
        r="1.42105"
        transform="rotate(-45 12 12.5886)"
        fill={color}
      />
    </SvgIcon>
  );
}
