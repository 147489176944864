import React, { createContext, useContext, useMemo, useState } from "react";
import { NativeSurveyFormInitialState } from "../../components/common/NativeSurvey/useNativeSurvey";

export interface ISharedStoreContext {
  matchSelector: {
    tabIndexToShow: number;
  };
  profilePage: {
    tabIndexToShow: number;
  };
  employeeDashboard: {
    hasSeenNewMatchesBanner: boolean;
  };
  login: {
    recentlyEnteredEmail?: string;
  };
  surveyProfessionalNPS: {
    pendingSubmissionId: string | null;
    formInitialState?: NativeSurveyFormInitialState;
  };
  surveyEmployeeNPS: {
    pendingSubmissionId: string | null;
    formInitialState?: NativeSurveyFormInitialState;
    hasSeenSurvey?: boolean | null;
  };
  videoCallPage: {
    sessionsConnectionId: string | null;
  };
  employeeOneToOnePage: {
    therapiesConnectionId: string | null;
  };
  setSharedStore: (storePartial: Partial<ISharedStoreContext>) => void;
}

export const defaultSharedStoreContext: ISharedStoreContext = {
  matchSelector: {
    tabIndexToShow: 0,
  },
  profilePage: {
    tabIndexToShow: 0,
  },
  employeeDashboard: {
    hasSeenNewMatchesBanner: false,
  },
  login: {
    recentlyEnteredEmail: undefined,
  },
  surveyProfessionalNPS: {
    pendingSubmissionId: null,
    formInitialState: undefined,
  },
  surveyEmployeeNPS: {
    pendingSubmissionId: null,
    formInitialState: undefined,
  },
  videoCallPage: {
    sessionsConnectionId: null,
  },
  employeeOneToOnePage: {
    therapiesConnectionId: null,
  },
  setSharedStore: () => {},
};

export const SharedStoreContext = createContext<ISharedStoreContext>(
  defaultSharedStoreContext,
);

export const useSharedStoreContext = (): ISharedStoreContext => {
  const contextData = useContext(SharedStoreContext);

  return {
    ...contextData,
    setSharedStore: (storePartial: Partial<ISharedStoreContext>) => {
      contextData.setSharedStore(storePartial);
    },
  };
};

export default function SharedStoreContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, setState] = useState<ISharedStoreContext>(
    defaultSharedStoreContext,
  );

  const stateWithSetter = useMemo(
    () => ({
      ...state,
      setSharedStore: (storePartial: Partial<ISharedStoreContext>) => {
        setState({ ...state, ...storePartial });
      },
    }),
    [state],
  );

  return (
    <SharedStoreContext.Provider value={stateWithSetter}>
      {children}
    </SharedStoreContext.Provider>
  );
}
