/* eslint-disable @typescript-eslint/no-var-requires */
const boxShadow = require("./boxShadow");
const colors = require("./colors");
const dropShadow = require("./dropShadow");
const fonts = require("./fonts");
const maxWidth = require("./maxWidth");
const spacing = require("./spacing");
const screens = require("./screens");

module.exports = {
  content: {
    files: ["./**/*.{jsx,tsx}"],
  },
  theme: {
    extend: {
      boxShadow,
      colors,
      dropShadow,
      fontFamily: fonts,
      maxWidth,
      spacing,
      screens,
    },
  },
};
