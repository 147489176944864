import ctl from "@netlify/classnames-template-literals";

type DividerVariant = "primary" | "accent";
type DividerMargin = "none" | "xs" | "sm" | "mo" | "md" | "lg" | "xl" | "2xl";

interface Props {
  variant?: DividerVariant;
  margin?: DividerMargin;
}

export default function Divider({
  variant = "primary",
  margin = "md",
}: Props): JSX.Element {
  const className = getClasses(variant, margin);

  return <hr className={className} />;
}

function getClasses(variant: DividerVariant, margin: DividerMargin) {
  const baseStyles = ctl(`
    border-0
    border-t
    border-neutral-200
  `);

  const variantStyles = {
    primary: ctl(`border-neutral-200`),
    accent: ctl(`border-purple-100 border-opacity-30`),
  };

  const marginStyles = {
    none: ctl(`my-0`),
    xs: ctl(`my-1`),
    sm: ctl(`my-2`),
    mo: ctl(`my-3`),
    md: ctl(`my-4`),
    lg: ctl(`my-6`),
    xl: ctl(`my-8`),
    "2xl": ctl(`my-16`),
  };

  return `${baseStyles} ${variantStyles[variant]} ${marginStyles[margin]}`;
}
