import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function PieChartIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <circle cx="12" cy="12" r="9" stroke={color} strokeWidth="2" />
      <path
        d="M6.54541 3.81818L15.6363 19.5641"
        stroke={color}
        strokeWidth="2"
      />
      <path d="M12.2273 12L20.015 7.14568" stroke={color} strokeWidth="2" />
    </SvgIcon>
  );
}
