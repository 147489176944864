import { Menu, Transition } from "@headlessui/react";
import cx from "classnames";
import { ChevronIcon } from "../../atoms/Icons";
import Text from "../../atoms/Text";
import Divider from "../../atoms/Divider";
import NavLink from "./NavLink";
import * as s from "./styles";
import { transitionProps } from "./utils";
import type { NavbarProps, NavbarLink } from "./index";

type NavbarDesktopProps = Pick<
  NavbarProps,
  "isOrganisationAdmin" | "labels"
> & {
  hasTeamSessionsOnly?: boolean;
  adminLinks: NavbarLink[];
  primaryLinks: NavbarLink[];
  secondaryLinks: NavbarLink[];
  tertiaryLinks: NavbarLink[];
  organisationAdminLinks: NavbarLink[];
};

export default function NavbarDesktop({
  adminLinks,
  isOrganisationAdmin,
  hasTeamSessionsOnly = false,
  labels,
  primaryLinks,
  secondaryLinks,
  tertiaryLinks,
  organisationAdminLinks,
}: NavbarDesktopProps) {
  return (
    <div className="flex flex-col gap-2">
      {isOrganisationAdmin && !hasTeamSessionsOnly && (
        <div className="ml-4">
          <Text variant="label" color="secondary">
            {labels.careJourney}
          </Text>
        </div>
      )}

      {primaryLinks.map((link) => (
        <NavLink key={`${link.href}-${link.label}`} {...link} />
      ))}

      {secondaryLinks.length > 0 && (
        <>
          {!hasTeamSessionsOnly && <Divider />}
          {secondaryLinks.map((link) => (
            <NavLink key={`${link.href}-${link.label}`} {...link} />
          ))}
        </>
      )}

      {tertiaryLinks.length > 0 && (
        <>
          <Divider />
          {tertiaryLinks.map((link) => (
            <NavLink key={`${link.href}-${link.label}`} {...link} />
          ))}
        </>
      )}

      {isOrganisationAdmin && organisationAdminLinks.length > 0 && (
        <>
          <Divider />
          <div className="ml-4">
            <Text variant="label" color="secondary">
              {labels.company}
            </Text>
          </div>

          {organisationAdminLinks.map((link) => (
            <NavLink key={`${link.href}-${link.label}`} {...link} />
          ))}
        </>
      )}

      {adminLinks.length > 0 && (
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button
                className={cx(s.item, "justify-between bg-neutral-100")}
              >
                <Text weight="bold">{labels.admin}</Text>
                <ChevronIcon direction={open ? "up" : "down"} />
              </Menu.Button>
              <Transition {...transitionProps}>
                <Menu.Items>
                  {adminLinks.map((link) => (
                    <Menu.Item key={`${link.href}-${link.label}`}>
                      <NavLink {...link} />
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      )}
    </div>
  );
}
