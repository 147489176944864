import { ReactElement, useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";

import Text, { TextWeight } from "../../atoms/Text";
import * as s from "./styles";

export interface Props {
  name: string;
  value: string;
  register?: UseFormRegister<any>;
  text?: string;
  textWeight?: TextWeight;
  isChecked?: boolean;
  isDisabled?: boolean;
}

export default function Radio({
  name,
  register,
  text,
  textWeight = "regular",
  value,
  isChecked = false,
  isDisabled = false,
}: Props): ReactElement {
  const [checked, setChecked] = useState<boolean>(isChecked);
  const [disabled, setDisabled] = useState<boolean>(isDisabled);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  useEffect(() => {
    setDisabled(isDisabled);
  }, [isDisabled]);

  const hasLabelText = Boolean(text);
  const isControlledByReactHookForm = register;

  const Input = () => (
    <input
      type="radio"
      className={s.getInputStyle(hasLabelText, isDisabled)}
      id={value}
      value={value}
      {...(isControlledByReactHookForm
        ? { ...register(name, { required: true }) }
        : {
            checked,
            disabled,
            onClick: () => setChecked(!checked),
            name,
          })}
    />
  );

  if (hasLabelText) {
    return (
      <label
        className={s.getLabelStyle(hasLabelText, isDisabled)}
        htmlFor={value}
      >
        <Input />
        <Text weight={textWeight}>{text}</Text>
      </label>
    );
  }

  return <Input />;
}
