import cx from "classnames";
import React, { useState, useRef } from "react";
import {
  useHover,
  useFloating,
  useInteractions,
  flip,
  offset,
  arrow,
  FloatingArrow,
} from "@floating-ui/react";

import "./styles.css";
import tokens from "../../../theme/tokens";
import * as s from "./styles";

type Placement = "top" | "bottom" | "left" | "right";
type TextAlignment = "left" | "center" | "right" | "justify";

const ARROW_HEIGHT = 15;
const ARROW_WIDTH = 22;
const ARROW_RADIUS = 4;

export interface Props {
  children: React.ReactElement;
  text: string;
  placement?: Placement;
  textAlign?: TextAlignment;
  variant?: "default" | "light";
}
export default function Tooltip({
  children,
  text,
  placement = "top",
  textAlign = "center",
  variant = "default",
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    placement: placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      arrow({
        element: arrowRef,
      }),
      flip({ fallbackAxisSideDirection: "start" }),
      offset(20),
    ],
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && text && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className={cx(s.toolTipClass(textAlign, variant))}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          <FloatingArrow
            ref={arrowRef}
            context={context}
            width={ARROW_WIDTH}
            height={ARROW_HEIGHT}
            tipRadius={ARROW_RADIUS}
            fill={
              variant === "default"
                ? tokens.colors["neutral"][900]
                : tokens.colors["purple"][50]
            }
          />
        </div>
      )}
    </>
  );
}
