import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface IUsePreviousPage {
  currentPage: string;
  previousPage: string | null;
}

export default function usePreviousPage(): IUsePreviousPage {
  const dynamicPathname = useRouter().asPath.split("?")[0];

  const [pages, setPages] = useState<IUsePreviousPage>({
    previousPage: null,
    currentPage: dynamicPathname,
  });

  useEffect(() => {
    setPages((current) => ({
      currentPage: dynamicPathname,
      previousPage: current.currentPage,
    }));
  }, [dynamicPathname]);

  return pages;
}
