import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function ExportIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M9 8.98437V8.98437C6.23858 8.98437 4 11.223 4 13.9844L4 15.9844C4 19.2981 6.68629 21.9844 10 21.9844L14 21.9844C17.3137 21.9844 20 19.2981 20 15.9844L20 13.9844C20 11.223 17.7614 8.98437 15 8.98437V8.98437"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M16.5 6.01562L14.3061 3.95077C13.1509 2.86357 11.3491 2.86357 10.1939 3.95077L8 6.01563"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.25 14.9844L12.25 4.01562"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
