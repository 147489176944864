import { ReactElement } from "react";
import clampString from "../../../utils/clampString";
import * as s from "./styles";

interface Props {
  subtitle?: string;
  thumbnail?: string;
  title?: string;
}

export default function PlayerHeader({
  subtitle,
  thumbnail,
  title,
}: Props): ReactElement {
  return (
    <div className={s.header}>
      {thumbnail && (
        <img src={thumbnail} alt={title} className="h-12 w-12 rounded" />
      )}
      <div className="ml-6 flex flex-col">
        {subtitle && (
          <span className={s.headerSubtitle}>{clampString(subtitle, 30)}</span>
        )}
        {title && (
          <span className={s.headerTitle}>{clampString(title, 30)}</span>
        )}
      </div>
    </div>
  );
}
