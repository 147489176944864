import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function LogoutIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M16 9V9C16 6.23858 13.7614 4 11 4H9C5.68629 4 3 6.68629 3 10V14C3 17.3137 5.68629 20 9 20H11C13.7614 20 16 17.7614 16 15V15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18.9688 16.5L21.0336 14.3061C22.1208 13.1509 22.1208 11.3491 21.0336 10.1939L18.9687 8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 12.25L20.9688 12.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
