import ctl from "@netlify/classnames-template-literals";

export const iconWrapper = ctl(`
  absolute
  left-2
  -top-10
  z-20
  lg:block
  scale-[0.9]
  md:scale-100
  md:left-4
`);

export const closeIcon = ctl(`
  absolute
  top-2
  right-2
  md:flex
`);

export const messageBannerWrapper = ctl(`
  items-center
  flex
  rounded-xl
  relative
  p-4
  pr-6
`);

export const buttonWrapper = ctl(`
  flex
  flex-col
  w-full
  justify-start
  space-y-2
  md:flex-row
  md:space-x-2
  md:space-y-0
`);

export const messageAndCtaWrapper = (hasIcon = true) =>
  ctl(`
  w-full
  md:mt-0
  flex
  gap-x-2
  items-start
  ${hasIcon ? "sm:ml-[120px] mt-8" : ""}
`);

export const info = ctl(`
  bg-status-teal-50
`);

export const purple = ctl(`
  bg-purple-50
`);

export const purpleDark = ctl(`
  bg-purple-900
  text-white
`);

export const warning = ctl(`
  bg-status-yellow-50
`);

export const error = ctl(`
  bg-status-red-50
`);
