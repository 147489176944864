import React, { Dispatch, ReactElement } from "react";
import Downshift from "downshift";
import { usePreloadedQuery } from "react-relay";
import { ChevronIcon, CrossIcon, DropdownOption } from "@olivahealth/oli-ui";
import { GraphQLTaggedNode, OperationType } from "relay-runtime";
import { PreloadedQuery } from "react-relay/hooks";
import * as s from "./styles";

export interface Props {
  query: GraphQLTaggedNode;
  queryReference: PreloadedQuery<OperationType> | null | undefined;
  transformDataToOptions: (data: unknown) => DropdownOption<string>[];
  placeholder?: string;
  initialSelectedValue?: string;
  setSelected: Dispatch<DropdownOption<string> | null>;
  onSearchChange: Dispatch<string>;
}

export default function ServerSearchListbox({
  queryReference,
  query,
  transformDataToOptions,
  placeholder,
  initialSelectedValue,
  setSelected,
  onSearchChange,
}: Props): ReactElement {
  return (
    <Downshift
      onChange={setSelected}
      onInputValueChange={onSearchChange}
      initialSelectedItem={
        initialSelectedValue
          ? {
              id: initialSelectedValue,
              value: initialSelectedValue,
              label: initialSelectedValue,
            }
          : undefined
      }
      itemToString={(item) => (item ? item.value : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        selectedItem,
        isOpen,
        openMenu,
        clearSelection,
      }) => (
        <div>
          <div className={s.inputBar}>
            <input
              {...getInputProps()}
              onClick={() => openMenu()}
              className="h-8 w-full bg-transparent outline-none border-0"
              placeholder={placeholder ?? ""}
            />
            {selectedItem ? (
              <button
                onClick={() => clearSelection()}
                aria-label="clear selection"
              >
                <CrossIcon />
              </button>
            ) : (
              <button {...getToggleButtonProps()}>
                <ChevronIcon
                  direction="down"
                  className="mr-2 h-5 w-5"
                  aria-hidden="true"
                  size={20}
                />
              </button>
            )}
          </div>
          <div className="relative hover:bg-blue-500">
            <React.Suspense fallback={<p className={s.option}>Loading...</p>}>
              {isOpen && queryReference && (
                <RenderOptions
                  isOpen={isOpen}
                  transformDataToOptions={transformDataToOptions}
                  getMenuProps={getMenuProps}
                  getItemProps={getItemProps}
                  query={query}
                  queryReference={queryReference}
                />
              )}
            </React.Suspense>
          </div>
        </div>
      )}
    </Downshift>
  );
}

interface RenderOptionsProps {
  getMenuProps: any;
  getItemProps: any;
  query: GraphQLTaggedNode;
  queryReference: PreloadedQuery<OperationType>;
  isOpen: boolean;
  transformDataToOptions: (data: unknown) => DropdownOption<string>[];
}

function RenderOptions({
  getMenuProps,
  getItemProps,
  query,
  queryReference,
  isOpen,
  transformDataToOptions,
}: RenderOptionsProps) {
  const data = usePreloadedQuery(query, queryReference);
  const options = transformDataToOptions(data);
  return (
    <ul {...getMenuProps()} className={s.optionsList} hidden={!isOpen}>
      {options.map((option, index) => (
        <li
          className={s.option}
          key={`${option.value}${index}`}
          {...getItemProps({
            item: option,
            index,
          })}
          dangerouslySetInnerHTML={{ __html: option.label }}
        />
      ))}
    </ul>
  );
}
