import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

type HeartVariant = "filled" | "outline";

interface Props extends IconProps {
  variant?: HeartVariant;
}

export function HeartIcon({
  className,
  color = "transparent",
  size,
  variant = "outline",
}: Props): ReactElement {
  const variants = {
    filled: (
      <path
        d="M12.5692 6.66848C12.3153 7.02862 11.6847 7.02862 11.4308 6.66848C8.92585 3.11493 3 3.97939 3 9.49991C3 13.9951 8.05175 17.9292 10.5857 19.6197C11.4479 20.1949 12.5521 20.1949 13.4143 19.6197C15.9483 17.9292 21 13.9951 21 9.49991C21 3.97939 15.0742 3.11493 12.5692 6.66848Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    outline: (
      <path
        d="M12.5692 6.66848C12.3153 7.02862 11.6847 7.02862 11.4308 6.66848C8.92585 3.11493 3 3.97939 3 9.49991C3 13.9951 8.05175 17.9292 10.5857 19.6197C11.4479 20.1949 12.5521 20.1949 13.4143 19.6197C15.9483 17.9292 21 13.9951 21 9.49991C21 3.97939 15.0742 3.11493 12.5692 6.66848Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {variants[variant]}
    </SvgIcon>
  );
}
