import ctl from "@netlify/classnames-template-literals";

export const sharedStyle = ctl(`
  px-4
  py-3
  font-base
  rounded-md
  flex
  w-full
  justify-center
  text-center
  text-neutral-900
  shadow-sm
  outline-none
  ring
  ring-transparent
`);

export const activeNeutral = ctl(`
  active:bg-neutral-200
  active:ring
  active:ring-neutral-600
`);

export const neutral = ctl(`
  bg-neutral-50
  hover:bg-neutral-100
  active:bg-neutral-200
  active:ring
  active:ring-neutral-600
  focus:outline-none
  focus:bg-neutral-50
  focus:ring
  focus:ring-neutral-600
`);

export const activePrimary = ctl(`
  active:bg-purple-200
  active:ring
  active:ring-purple-600
`);

export const primary = ctl(`
  bg-purple-50
  hover:bg-purple-100
  focus:bg-purple-50
  focus:outline-none
  focus:ring
  focus:ring-purple-600
`);
