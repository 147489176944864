import React, { ReactElement, useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import useDebounce from "@olivahealth/utils/reactHooks/useDebounce";

import type { Question } from "../../types/Question";
import Text from "../../atoms/Text";
import * as s from "../../shared/surveyStyles";

export const DEBOUNCE_TEXT_TIME = 300; // ms

interface Props {
  initialValue?: string;
  isDisabled?: boolean;
  notice?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  question: Question;
  minRows?: number;
  maxRows?: number;
}

export default function TextQuestion({
  initialValue,
  isDisabled,
  notice,
  onChange,
  placeholder,
  question,
  minRows = 10,
  maxRows = 10,
}: Readonly<Props>): ReactElement | null {
  const [text, setText] = useState<string | undefined>(initialValue);
  const debouncedText = useDebounce(text, DEBOUNCE_TEXT_TIME);

  useEffect(() => {
    if (text === undefined || !onChange) {
      return;
    }

    if (typeof debouncedText !== "undefined") {
      onChange?.(debouncedText);
    }
  }, [debouncedText]);

  const handleOnChange = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  return (
    <>
      {question.question && (
        <Text variant="h3" component="h1" color="accent-primary" gutter="md">
          <span
            dangerouslySetInnerHTML={{
              __html: question.question,
            }}
          />
        </Text>
      )}
      <TextareaAutosize
        autoFocus
        defaultValue={initialValue}
        name={question.id}
        id={question.id}
        minRows={minRows}
        maxRows={maxRows}
        placeholder={placeholder}
        onChange={handleOnChange}
        disabled={isDisabled}
        className={s.textarea}
      />
      {notice && (
        <div className="ml-2 mt-2">
          <Text size="sm" color="secondary">
            {notice}
          </Text>
        </div>
      )}
    </>
  );
}
