import ctl from "@netlify/classnames-template-literals";

export type Variant =
  | "default"
  | "secondary"
  | "tertiary"
  | "ghost"
  | "darkglass"
  | "glass";

export const getDisclosureWrapper = (variant: Variant) =>
  ctl(`
  relative
  w-full
  rounded-b-2xl
  mb-1
  ${variant === "secondary" ? "border border-neutral-200" : ""}
`);

export const getButtonWrapper = (
  variant: Variant,
  isOpen: boolean,
  disabledCursor: "" | "cursor-not-allowed" | "cursor-auto",
  chevronIconPosition: "left" | "right",
  chevronVerticalAlign: "top" | "center",
  fullWidth?: boolean,
  padding?: "none" | "sm" | "md" | "lg",
  showBorderRadius?: boolean,
) =>
  ctl(`
  flex
  ${chevronIconPosition === "right" ? "justify-between" : ""}
  w-full
  rounded-2xl
  peer
  outline-none
  transition-colors
  ${chevronVerticalAlign === "center" ? "items-center" : "items-start"}
  relative
  z-10
  ${padding === "none" ? "p-0" : ""}
  ${padding === "sm" ? "p-2" : ""}
  ${padding === "md" ? "p-4" : ""}
  ${padding === "lg" ? "p-6" : ""}
  
  ${variant === "glass" ? "transition-all" : ""}
  ${variant === "glass" && isOpen && "bg-gradient-to-br from-status-teal-500/30 to-status-teal-50/30 hover:from-status-teal-500/50 hover:to-status-teal-50/50"}
  ${variant === "glass" && !isOpen && "bg-gradient-to-br from-purple-500/30 to-purple-300/30 hover:from-purple-500/50 hover:to-purple-300/50"}
  ${variant !== "ghost" && variant !== "glass" ? "bg-neutral-50" : ""}
  ${variant === "tertiary" ? "!bg-neutral-50" : ""}
  ${variant === "darkglass" ? "!bg-[#1C1831]/50 text-white" : ""}
  ${
    variant === "secondary" ||
    variant === "ghost" ||
    variant === "glass" ||
    variant === "darkglass"
      ? ""
      : "hover:!bg-neutral-50"
  }
  ${
    isOpen &&
    variant !== "secondary" &&
    variant !== "darkglass" &&
    variant !== "ghost" &&
    variant !== "glass"
      ? "!bg-neutral-50"
      : ""
  }
  ${showBorderRadius ? "rounded-b-2xl" : "rounded-b-none"}
  ${disabledCursor}
  ${fullWidth ? "px-0" : ""}
`);

export const getContentWrapper = (variant: Variant) =>
  ctl(`
  h-full
  rounded-b-2xl
  transition-colors
  ${
    variant === "secondary" || variant === "ghost"
      ? ""
      : "bg-neutral-50 peer-active:!bg-neutral-100"
  }
  ${variant === "darkglass" ? "!bg-neutral-900/50 peer-active:!bg-neutral-900/50 text-white" : ""}
`);

export const getContentInnerWrapper = (
  variant: Variant,
  fullWidth?: boolean,
) => {
  if (variant === "secondary") {
    return ctl(`
        bg-neutral-50
        ${fullWidth ? "" : "px-6"}
        pb-6
    `);
  }

  if (variant === "tertiary") {
    return ctl(`
      ${fullWidth ? "" : "px-6"}
      pb-6
      pt-4
  `);
  }

  if (variant === "darkglass") {
    return ctl(`
      ${fullWidth ? "" : "px-4"}
      pb-4
      pt-0
  `);
  }

  if (variant !== "ghost") {
    return ctl(`
      ${fullWidth ? "" : "px-4"}
      py-4
  `);
  }
};

export const getContentChildrenWrapper = (variant: Variant) =>
  ctl(`
    ${variant === "secondary" ? "mt-6" : ""}
    ${variant === "darkglass" ? "pt-4" : ""}
`);

export const header = ctl(`
  flex
  items-center
  justify-start
  text-left
`);

export const getChevronStyle = (isOpen: boolean) =>
  ctl(`
  ${isOpen ? "-rotate-180" : "rotate-0"}
  transform
  transition-all
`);
