import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  variant?: "default" | "ring";
}

export function BellIcon({
  className,
  color = "currentColor",
  size,
  variant = "default",
}: Props): ReactElement {
  const bell = {
    default: (
      <g>
        <path
          d="M5.25012 15.5V8.05333C5.25012 4.50307 8.27215 1.62501 12 1.625C15.7278 1.62499 18.7498 4.50305 18.7498 8.05333V15.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3324 14.7556C18.603 16.387 15.411 17.3245 12 17.3245C8.589 17.3245 5.39698 16.387 2.66757 14.7556"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
    ring: (
      <g>
        <path
          d="M5.25012 15.5V8.05333C5.25012 4.50307 8.27215 1.62501 12 1.625C15.7278 1.62499 18.7498 4.50305 18.7498 8.05333V15.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 7.72934C2 5.66576 2.66032 3.75224 3.78659 2.17786M22 7.72934C22 5.66576 21.3397 3.75224 20.2134 2.17786"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3324 14.7556C18.603 16.387 15.411 17.3245 12 17.3245C8.589 17.3245 5.39698 16.387 2.66757 14.7556"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {bell[variant]}
      <circle cx="12" cy="21.1546" r="2" fill={color} />
    </SvgIcon>
  );
}
