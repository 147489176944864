import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function TagIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path
        d="M20.2345 10.3429C22.073 12.1814 22.073 15.1622 20.2345 17.0007L16.8711 20.364C15.0326 22.2025 12.0518 22.2025 10.2133 20.364L4.63379 14.7845C3.84387 13.9946 3.36058 12.9496 3.2703 11.8361L2.88309 7.06047C2.6959 4.75183 4.62225 2.82548 6.93089 3.01267L11.7065 3.39988C12.82 3.49016 13.8651 3.97345 14.655 4.76337L20.2345 10.3429Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="9.17157"
        cy="9.17157"
        r="2"
        transform="rotate(45 9.17157 9.17157)"
        fill={color}
      />
    </SvgIcon>
  );
}
