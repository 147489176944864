import ctl from "@netlify/classnames-template-literals";

export const item = ctl(`
  flex
  items-center
  cursor-pointer
  py-2
  px-4
  gap-6
  rounded-lg
  text-neutral-900
  hover:bg-neutral-50
  focus:bg-neutral-50
  active:bg-neutral-100
`);

export const itemSelected = ctl(`
  !bg-blue-50
  !text-blue-900
  hover:!bg-blue-100
  focus:!bg-blue-100
  active:!bg-blue-200
`);

export const dropdownButton = ctl(`
  flex
  gap-6
  rounded-lg
  bg-blue-50
  py-2
  px-4
  text-blue-900
`);

export const dropdownItems = ctl(`
  absolute
  top-0
  right-0
  z-20
  mt-1
  flex min-w-[16rem]
  flex-col
  gap-2
  rounded-lg
  bg-white
  p-2
`);
