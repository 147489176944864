/**
 * These styles are used in the survey/triaging components that were migrated
 * from the old design-system.
 */

import ctl from "@netlify/classnames-template-literals";

export const optionGroup = ctl(`
  flex
  flex-col
  items-stretch
  w-auto
  gap-2
`);

export const optionGroupRow = ctl(`
  flex
  flex-row
  items-stretch
  w-auto
  gap-2
`);

export const option = ctl(`
  relative
  flex
  cursor-pointer
  flex-row
  rounded-lg
  text-neutral-900
  font-base
  border 
  border-purple-800
  px-4
  py-2
  w-auto
  md:min-w-[13rem]
  hover:bg-purple-200
  hover:border-purple-600
`);

export const optionSecondary = ctl(`
  relative
  flex
  cursor-pointer
  flex-row
  rounded-lg
  text-neutral-900
  font-base
  px-4
  py-3
  w-auto
  md:min-w-[13rem]
  bg-neutral-50
  hover:bg-neutral-100
`);

export const optionSelected = ctl(`
  bg-purple-300
  border-purple-800
  hover:!bg-purple-200
`);

export const optionSecondarySelected = ctl(`
  bg-purple-50
  hover:!bg-purple-100
`);

export const optionsContainer = ctl(`
  flex
  items-center
  justify-between
  w-full
`);

export const optionLabel = ctl(`
  cursor-pointer
  text-left
  text-base
`);

export const textarea = ctl(`
  font-base
  rounded-xl
  border-neutral-300
  px-4 py-3
  w-full
  text-neutral-900
  outline-none
  resize-none
  focus:border-neutral-500
  focus:ring-0
  focus:outline-none
`);
