import React, { ReactElement, useEffect } from "react";
import { useCombobox } from "downshift";
import cx from "classnames";
import { Transition } from "@headlessui/react";
import { CheckIcon, ChevronIcon } from "../../atoms/Icons";
import * as inputStyles from "../../shared/inputStyles";
import type { SelectItem } from "../Select";

export interface Props {
  disabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  label?: string | ReactElement;
  placeholder?: string;
  onInputChange?: (value?: string, selectedItem?: SelectItem | null) => void;
  onStateChange: (state: any) => void;
  iconLeft?: ReactElement;
  items: SelectItem[];
  initialSelectedValue?: string | number | undefined;
  required?: boolean;
  showToggle?: boolean;
  showRequiredLabel?: boolean;
}

export default function AutocompleteField({
  disabled = false,
  hasError,
  helperText,
  label,
  placeholder,
  onInputChange,
  onStateChange,
  iconLeft,
  items,
  initialSelectedValue,
  required = false,
  showToggle = true,
  showRequiredLabel = true,
}: Props): ReactElement {
  const preSelectedItem = items.find(
    (item) => item.value === initialSelectedValue,
  );

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    getToggleButtonProps,
    isOpen,
    selectedItem,
  } = useCombobox({
    onInputValueChange({ inputValue, selectedItem }) {
      onInputChange?.(inputValue, selectedItem);
    },
    onStateChange({ selectedItem }) {
      if (!selectedItem) {
        return;
      }
      onStateChange(selectedItem);
    },
    items,
    itemToString: (item) => (item ? `${item.label}` : ""),
    initialSelectedItem: preSelectedItem,
  });

  useEffect(() => {
    if (preSelectedItem) {
      onStateChange(preSelectedItem);
    }
  }, []);

  return (
    <div className={inputStyles.inputGroup}>
      <div className="w-full">
        {label && (
          <label
            {...getLabelProps()}
            className={cx(inputStyles.label, {
              [inputStyles.labelDisabled]: disabled,
            })}
          >
            {label}
            {required && showRequiredLabel && (
              <span className={inputStyles.labelRequired}>*</span>
            )}
          </label>
        )}
        <div className="relative flex w-full flex-row">
          {iconLeft && <div className="absolute left-4 top-3">{iconLeft}</div>}
          <input
            {...getInputProps()}
            autoComplete="off"
            className={cx(inputStyles.input, {
              [inputStyles.inputError]: hasError,
              [inputStyles.selectButtonDisabled]: disabled,
              "pl-12": iconLeft,
            })}
            placeholder={placeholder}
            tabIndex={0}
          />
          {showToggle && (
            <div
              {...getToggleButtonProps()}
              aria-label="toggle menu"
              className="absolute bottom-3 right-4"
            >
              <ChevronIcon
                direction="down"
                className={cx({
                  "-rotate-180 transform transition-all": isOpen,
                  "rotate-0 transform transition-all": !isOpen,
                })}
              />
            </div>
          )}
        </div>
      </div>
      <div className="relative z-10" {...getMenuProps()}>
        <Transition
          show={isOpen && Boolean(items.length)}
          {...inputStyles.transitionProps}
        >
          <ul className={cx(inputStyles.selectMenu)}>
            {items.map((item, index) => {
              const isSelected = selectedItem?.value === item.value;

              return (
                <li
                  key={item.value}
                  {...getItemProps({ item, index })}
                  className={cx(inputStyles.selectMenuItem, {
                    "bg-neutral-50": isSelected,
                  })}
                >
                  {item.label}
                  {isSelected && <CheckIcon size={16} />}
                </li>
              );
            })}
          </ul>
        </Transition>
      </div>
      {helperText && (
        <span
          id="helperText"
          className={cx(inputStyles.helperText, {
            [inputStyles.helperTextDisabled]: disabled,
          })}
        >
          {helperText}
        </span>
      )}
    </div>
  );
}
