import ctl from "@netlify/classnames-template-literals";

export const button = ctl(`
  flex
  items-center
  justify-center
  rounded-lg
  hover:bg-neutral-100
  focus:bg-neutral-100
  px-4
  py-3
  font-medium
  text-neutral-900
`);

export const optionList = ctl(`
  absolute
  left-15
  z-10
  bg-white
  w-[210px]
  hover:bg-white
  shadow-lg
  mt-2
  max-h-56
  rounded-lg
  overflow-y-auto
  text-base
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
  sm:text-sm
  z-40
`);

export const option = ctl(`
  cursor-pointer
  flex
  select-none
  font-base
  justify-start
  items-center
  space-x-1
  bg-white
  hover:bg-neutral-50
  text-neutral-900
  relative
  py-3
  px-4
`);

export const truncatedText = ctl(`
  flex
  justify-start
  block
  truncate
  w-6
`);
