import ctl from "@netlify/classnames-template-literals";

export const toolTipClass = (textAlign: string, variant: "default" | "light") =>
  ctl(`
    text-${textAlign} 
    relative
    max-w-xs
    text-sm
    z-30
    rounded-lg
    ${variant === "default" ? "bg-neutral-900" : "bg-purple-50"}
    shadow-xl
    p-2
    ${variant === "default" ? "text-white" : "text-neutral-800"}
  `);
