import { Fragment, ReactNode, useRef } from "react";
import { Dialog as HuiDialog, Transition } from "@headlessui/react";
import cx from "classnames";

import Button from "../Button";
import Text from "../Text";
import { CrossIcon } from "../Icons";
import * as s from "./styles";

type Variant = "default" | "centered" | "centered-lg";
type Size = "3xl" | "2xl" | "xl" | "lg" | "md" | "sm";

interface Props {
  children: ReactNode;
  icon?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  size?: Size;
  customTopPosition?: string;
  contentOverflow?: boolean;
  showCloseButton?: boolean;
  variant?: Variant;
  topLeftContent?: ReactNode;
}

export default function Dialog({
  children,
  icon,
  isOpen,
  onClose,
  title,
  size = "3xl",
  contentOverflow = false,
  customTopPosition,
  showCloseButton = true,
  variant = "default",
  topLeftContent,
}: Readonly<Props>): JSX.Element {
  const focusRef = useRef(null);
  const isCentered = variant === "centered" || variant === "centered-lg";
  const hasTitle = Boolean(title);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HuiDialog
        as="div"
        className={cx(s.dialog, customTopPosition)}
        onClose={onClose}
        initialFocus={focusRef}
      >
        <div className={s.contentWrapper}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HuiDialog.Overlay className={s.overlay} />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={cx(s.content, `max-w-${size}`, {
                "text-center": isCentered,
                "overflow-visible overflow-y-auto h-full": contentOverflow,
              })}
            >
              {topLeftContent || null}
              {/* focus trap */}
              <button ref={focusRef} className="hidden h-0 w-0" tabIndex={0} />
              {showCloseButton && (
                <div className={s.buttonWrapper}>
                  <Button variant="icon" onClick={onClose} aria-label="Close">
                    <CrossIcon />
                  </Button>
                </div>
              )}
              {isCentered ? (
                <div className={s.contentCenteredInner}>
                  {icon && <div className={s.iconWrapper}>{icon}</div>}
                  {hasTitle && (
                    <Text
                      align="center"
                      size={variant === "centered-lg" ? "3xl" : "xl"}
                      weight="bold"
                      fontFamily={variant === "centered-lg" ? "centra" : "sans"}
                    >
                      {title}
                    </Text>
                  )}
                  {children}
                </div>
              ) : (
                <>
                  {hasTitle && (
                    <Text variant="h3" gutter="sm">
                      {title}
                    </Text>
                  )}
                  <div>{children}</div>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </HuiDialog>
    </Transition>
  );
}
