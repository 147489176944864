import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

export function MinusIcon({
  className,
  color = "currentColor",
  size,
}: IconProps): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <path d="M4 12H20" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
  );
}
