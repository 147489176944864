import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  borderColor?: string;
  checkColor?: string;
}

export function CheckCircleIcon({
  borderColor,
  className,
  checkColor,
  color = "currentColor",
  fill = "transparent",
  size,
}: Props): ReactElement {
  return (
    <SvgIcon size={size} className={className}>
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke={borderColor ?? color}
        fill={fill}
        strokeWidth="2"
      />
      <path
        d="M9.5 12.2308L10.3906 13.5872C10.8079 14.2228 11.7538 14.1786 12.11 13.5069L14.5 9"
        stroke={checkColor ?? color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}
