import React from "react";
import ctl from "@netlify/classnames-template-literals";

const s = {
  circle: ctl(`
    relative
    inline-flex
    h-6
    w-6
    rounded-full
    bg-purple-500
  `),
  ping: ctl(`
    absolute
    inline-flex
    h-full
    w-full
    animate-ping
    rounded-full
    bg-purple-400
    opacity-75
  `),
};

export default function DotLoader(): JSX.Element {
  return (
    <span className="relative flex h-6 w-6">
      <span className={s.ping} />
      <span className={s.circle} />
    </span>
  );
}
