import { ReactElement } from "react";
import { IconProps, SvgIcon } from "./SvgIcon";

interface Props extends IconProps {
  direction: "up" | "down" | "left" | "right";
}

export function ChevronIcon({
  className,
  color = "currentColor",
  direction,
  size,
}: Props): ReactElement {
  const chevron = {
    left: (
      <path
        d="M13 6L10.1324 9.09728C8.62253 10.7281 8.62253 13.2719 10.1324 14.9027L13 18"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
    right: (
      <path
        d="M10 18L12.8676 14.9027C14.3775 13.2719 14.3775 10.7281 12.8676 9.09728L10 6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
    up: (
      <path
        d="M18 14L14.9027 11.1324C13.2719 9.62253 10.7281 9.62253 9.09728 11.1324L6 14"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
    down: (
      <path
        d="M6 10L9.09728 12.8676C10.7281 14.3775 13.2719 14.3775 14.9027 12.8676L18 10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    ),
  };

  return (
    <SvgIcon size={size} className={className}>
      {chevron[direction]}
    </SvgIcon>
  );
}
