import { Line } from "rc-progress";
import tokens from "../../../theme/tokens";
import Text, { TextWeight } from "../Text";

type LabelPosition = "top" | "right" | "left";
type Variant = "default" | "light";
export interface Props {
  label?: string;
  labelPosition?: LabelPosition;
  labelWeight?: TextWeight;
  variant?: Variant;
  percent?: number;
}

export default function Progress({
  label,
  labelPosition = "right",
  labelWeight,
  variant = "default",
  percent = 0,
}: Props): JSX.Element {
  const hasLabel = Boolean(label);
  const isLabelTop = labelPosition === "top";
  const isLabelLeft = labelPosition === "left";

  return (
    <div
      className={`relative flex w-full items-center gap-x-3 ${isLabelLeft ? "flex-row-reverse" : ""}`}
    >
      <Line
        percent={percent}
        strokeWidth={1}
        strokeColor={
          variant === "light" ? "white" : tokens.colors["purple"][600]
        }
        strokeLinecap="round"
        trailColor={
          variant === "light"
            ? `${tokens.colors["purple"][100]}33`
            : tokens.colors["neutral"][300]
        }
        trailWidth={1}
      />
      {hasLabel && (
        <div className={isLabelTop ? "absolute -top-7 right-2" : ""}>
          <Text
            size="sm"
            color={variant === "light" ? "accent-lighter" : "primary"}
            weight={labelWeight}
          >
            {label}
          </Text>
        </div>
      )}
    </div>
  );
}
